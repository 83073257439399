import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import VideocamIcon from '@mui/icons-material/Videocam'

const MediaPermissionsCheck: React.FC = () => {
  const { formatMessage } = useIntl()
  const [permissionsDialogOpen, setPermissionsDialogOpen] =
    useState<boolean>(false)

  const checkMediaPermissions = async (): Promise<void> => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      })
      mediaStream.getTracks().forEach((track): void => {
        track.stop()
      })
      console.log('Camera and microphone permissions granted')
    } catch (error) {
      console.error('Cannot access camera and microphone permissions:', error)
      setPermissionsDialogOpen(true)
    }
  }

  const openPermissions = async (): Promise<void> => {
    if (navigator.mediaDevices?.getUserMedia) {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        })
        mediaStream.getTracks().forEach((track): void => {
          track.stop()
        })
        console.log('Permissions opened')
        setPermissionsDialogOpen(false)
      } catch (error) {
        console.error('Cannot open permissions:', error)
        setPermissionsDialogOpen(true)
      }
    }
  }

  useEffect(() => {
    void checkMediaPermissions()
  }, [])

  return (
    <Dialog open={permissionsDialogOpen}>
      <DialogTitle>
        {formatMessage({ id: 'media_permissions_check.title' })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {formatMessage({ id: 'media_permissions_check.content' })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            void openPermissions()
          }}
          startIcon={<VideocamIcon />}
        >
          {formatMessage({
            id: 'media_permissions_check.button.open_permission',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MediaPermissionsCheck
