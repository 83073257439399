import { type LocalizedString } from 'types'
import {
  type CategoriesRawData,
  type CategoryRawData,
  type CategoryData,
} from 'components/category/categoryTypes'

const convertTranslationKeysToUpperCase = (
  translations: Record<string, string>,
): Record<string, string> => {
  const newTranslations: Record<string, string> = {}
  Object.keys(translations).forEach((key) => {
    newTranslations[key.toUpperCase()] = translations[key]
  })
  return newTranslations
}

const getCategoryTranslation = (
  translations: Record<string, string>,
  locale: string,
  fallbackLocale: string,
): string => {
  const keys = Object.keys(translations)

  if (keys.length === 0) {
    return ''
  }

  const newTranslations = convertTranslationKeysToUpperCase(translations)

  return newTranslations[locale] ?? newTranslations[fallbackLocale]
}

function findCategoryData(
  categoryId: string,
  categoryInputs: CategoryData[],
): CategoryData | null {
  for (const categoryInput of categoryInputs) {
    if (categoryInput.id === categoryId) {
      return categoryInput
    }

    if (categoryInput.subcategories) {
      const foundSubcategory = findCategoryData(
        categoryId,
        categoryInput.subcategories,
      )
      if (foundSubcategory) {
        return foundSubcategory
      }
    }
  }

  return null
}

const convertCategoryDataToCategoryInputs = (
  categoryData: CategoryRawData,
): CategoryData => {
  const names: LocalizedString[] = Object.entries(
    categoryData.translations,
  ).map(([language, content]) => ({
    language: language.toUpperCase(),
    content,
  }))

  const categoryInput: CategoryData = {
    id: categoryData.id,
    names,
  }

  if (categoryData.subcategories && categoryData.subcategories.length > 0) {
    categoryInput.subcategories = categoryData.subcategories.map(
      (subcategoryData) => convertCategoryDataToCategoryInputs(subcategoryData),
    )
  }

  return categoryInput
}

const convertCategoriesRawData = (
  rawData: CategoriesRawData,
): CategoryData[] => {
  const { categoryInfo } = rawData
  const { categories } = categoryInfo

  const convertedCategories: CategoryData[] = categories.map((categoryData) =>
    convertCategoryDataToCategoryInputs(categoryData),
  )

  return convertedCategories
}

export {
  findCategoryData,
  getCategoryTranslation,
  convertTranslationKeysToUpperCase,
  convertCategoriesRawData,
}
