import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Controller, useForm, type SubmitHandler } from 'react-hook-form'
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth'
import styled from '@mui/material/styles/styled'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import Alert from '@mui/material/Alert'
import LoadingButton from '@mui/lab/LoadingButton'
import Link from '@mui/material/Link'
import Visibility from '@mui/icons-material/Visibility'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Box from '@mui/material/Box'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import useRoute from 'hooks/useNavigate'
import AuthContext from 'context/AuthContext'
import {
  type AUTH_ERROR,
  AUTH_ERROR_MESSAGE,
  EMAIL_MAX_LENGTH,
  Path,
} from 'commonConstants'
import GoogleLogo from 'assets/icons/google_logo.svg'
import ErrorIcon from 'assets/icons/error_icon.svg'

type LoginFormData = {
  email: string
  password: string
}

type LoginError = {
  code: AUTH_ERROR
}

const Wrapper = styled(Stack)`
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => 4 * theme.shape.borderRadius}px;
  width: 100%;
`

const Header = styled(Typography)`
  font-size: 28px;
  font-weight: 500;
`

const SubHeader = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: 400;
`

const LoginPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const [searchParams] = useSearchParams()
  const { goTo } = useRoute()
  const { authData } = useContext(AuthContext)
  const [isSending, setIsSending] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<LoginFormData>({
    mode: 'onTouched',
  })
  const [errorCode, setErrorCode] = useState<AUTH_ERROR | null>()
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)

  useEffect(() => {
    if (searchParams.get('error')) {
      setIsErrorDialogOpen(true)
    }
  }, [searchParams.get('error')])

  if (authData) {
    goTo('/')
  }

  const handleClickShowPassword = (): void => {
    setShowPassword((show) => !show)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault()
  }

  const shouldDisableLogin = useMemo(
    () => !isValid || Object.keys(errors).length > 0 || isSending,
    [isValid, errors, isSending],
  )

  const onSubmit: SubmitHandler<LoginFormData> = useCallback(
    async (data): Promise<void> => {
      try {
        setIsSending(true)
        const auth = getAuth()
        await signInWithEmailAndPassword(auth, data.email, data.password)
        goTo('/')
      } catch (error: unknown) {
        const loginError = error as LoginError

        setErrorCode(loginError.code)
      } finally {
        setIsSending(false)
      }
    },
    [],
  )

  const handleForgotPassword = (): void => {
    goTo(Path.FORGOT_PASSWORD)
  }

  const handleGoogleLogin = async (): Promise<void> => {
    const provider = new GoogleAuthProvider()

    try {
      await signInWithPopup(getAuth(), provider)
      goTo('/')
    } catch (error) {
      console.error(error)
    }
  }

  const handleCloseErrorDialog = (): void => {
    setIsErrorDialogOpen(false)
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper padding={7} spacing={2}>
        <Stack textAlign="center" spacing={2}>
          <Header>{formatMessage({ id: 'login.header' })}</Header>
          <SubHeader>{formatMessage({ id: 'login.subheader' })}</SubHeader>
        </Stack>

        {errorCode && (
          <Alert variant="filled" severity="error">
            {formatMessage({ id: AUTH_ERROR_MESSAGE[errorCode] })}
          </Alert>
        )}

        <FormControl error={!!errors.email}>
          <Controller
            name="email"
            control={control}
            rules={{
              maxLength: EMAIL_MAX_LENGTH,
              required: true,
            }}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                {...field}
                required
                autoFocus
                label={formatMessage({
                  id: 'login.label.email',
                })}
                variant="outlined"
                fullWidth
                inputProps={{
                  'data-testid': 'email-input',
                }}
              />
            )}
          />

          {errors.email?.type === 'maxLength' && (
            <FormHelperText>
              {formatMessage(
                {
                  id: 'general.error.max_length_email',
                },
                { max: EMAIL_MAX_LENGTH },
              )}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl error={!!errors.password} fullWidth>
          <Controller
            name="password"
            control={control}
            rules={{
              required: true,
            }}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.password}
                required
                type={showPassword ? 'text' : 'password'}
                inputProps={{
                  'data-testid': 'password-input',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        data-testid="show-password-button"
                        aria-label={formatMessage({
                          id: 'general.icon_button.password_visibility_toggle',
                        })}
                        color="primary"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label={formatMessage({
                  id: 'login.label.password',
                })}
              />
            )}
          />
        </FormControl>

        <LoadingButton
          variant="contained"
          type="submit"
          disabled={shouldDisableLogin}
          loading={isSending}
          size="large"
          data-testid="login-button"
        >
          {formatMessage({ id: 'login.button.login' })}
        </LoadingButton>

        <Link
          data-testid="forgot-password-link"
          onClick={handleForgotPassword}
          sx={{ fontSize: 14 }}
        >
          {formatMessage({ id: 'login.button.forgot_password' })}
        </Link>
        <Divider>{formatMessage({ id: 'general.divider.or' })}</Divider>
        <Button
          variant="outlined"
          startIcon={<GoogleLogo />}
          size="large"
          data-testid="google-login-button"
          fullWidth
          disabled={isSending}
          onClick={() => {
            void handleGoogleLogin()
          }}
        >
          {formatMessage({ id: 'general.button.google_login' })}
        </Button>
      </Wrapper>

      <Dialog open={isErrorDialogOpen} maxWidth="xs">
        <DialogTitle>
          <Stack alignItems="center" spacing={2}>
            <ErrorIcon />
            <Box textAlign="center">
              {formatMessage({ id: 'auth.error.no_portal' })}
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            {formatMessage({ id: 'login.error.contact_admin' })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseErrorDialog}
            autoFocus
            variant="contained"
            fullWidth
            color="error"
          >
            {formatMessage({
              id: 'general.button.got_it',
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}

export default LoginPage
