import { atom, selector } from 'recoil'
import { type PortalSetting, type Locale } from 'types'
import { DEFAULT_LOCALE } from '../commonConstants'
import { type PublicPortalInfo } from 'components/publicCase/publicCaseTypes'

const isPortalSettingLoadingState = atom<boolean>({
  key: 'isPortalSettingLoadingState',
  default: false,
})

const portalSettingState = atom<PortalSetting | null>({
  key: 'portalSettingState',
  default: null,
})

const publicPortalInfoState = atom<PublicPortalInfo | null>({
  key: 'publicPortalInfoState',
  default: null,
})

const currentLocaleState = atom<Locale>({
  key: 'currentLocaleState',
  default: DEFAULT_LOCALE,
})

const sortedSupportLanguagesSelector = selector<string[]>({
  key: 'sortedSupportLanguagesSelector',
  get: ({ get }) => {
    const portalSetting = get(portalSettingState)

    if (portalSetting?.supportedLanguages) {
      const { defaultLanguage, supportedLanguages } = portalSetting

      const sortedLanguages = supportedLanguages
        .filter((lang) => lang !== defaultLanguage)
        .sort()

      return [defaultLanguage, ...sortedLanguages]
    }

    return []
  },
})

export {
  portalSettingState,
  currentLocaleState,
  sortedSupportLanguagesSelector,
  isPortalSettingLoadingState,
  publicPortalInfoState,
}
