import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from '@mui/material/styles/styled'
import LoadingButton from '@mui/lab/LoadingButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

import {
  CASE_TASK_STATUS_LABEL,
  CaseTaskStatus,
} from 'components/case/caseConstants'
import {
  getCaseTaskStatusColor,
  getCaseTaskStatusIcon,
} from 'components/task/taskConstants'

type TaskStatusButtonProps = {
  disabled: boolean
  status: CaseTaskStatus
  transitions?: CaseTaskStatus[]
  loading?: boolean
  onUpdateStatus: (status: CaseTaskStatus) => void
}

const StatusButton = styled(LoadingButton)<{
  status: CaseTaskStatus
}>`
  background: ${({ status }) => getCaseTaskStatusColor(status)};
  color: ${({ status, theme }) =>
    status === CaseTaskStatus.UNASSIGNED || status === CaseTaskStatus.ASSIGNED
      ? theme.palette.primary.contrastText
      : theme.palette.common.black};
  border: none;
  border-radius: ${({ theme }) => 0.8 * theme.shape.borderRadius}px;
  font-size: 12px;

  :disabled {
    color: ${({ status, theme }) =>
      status === CaseTaskStatus.UNASSIGNED || status === CaseTaskStatus.ASSIGNED
        ? theme.palette.primary.contrastText
        : theme.palette.common.black};
  }

  :hover {
    border: none;
    background: ${({ status }) => getCaseTaskStatusColor(status)};
  }

  & svg {
    color: ${({ status, theme }) =>
      status === CaseTaskStatus.UNASSIGNED || status === CaseTaskStatus.ASSIGNED
        ? theme.palette.primary.contrastText
        : theme.palette.common.black};
    font-size: 16px;
  }

  > .MuiSelect-select {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};
    padding: 2px 8px;

    > .MuiListItemIcon-root {
      min-width: 0;
    }
  }
`

const TaskStatusButton: React.FC<TaskStatusButtonProps> = ({
  disabled,
  status,
  transitions,
  loading = false,
  onUpdateStatus,
}) => {
  const { formatMessage } = useIntl()
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(menuEl)

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      if (transitions?.length) {
        setMenuEl(event.currentTarget)
      }
    },
    [transitions],
  )

  const handleCloseMenu = (): void => {
    setMenuEl(null)
  }

  const handleUpdateStatus = (status: CaseTaskStatus): void => {
    onUpdateStatus(status)
    handleCloseMenu()
  }

  const renderStatusIcon = (status: CaseTaskStatus): React.ReactNode => {
    const StatusIcon = getCaseTaskStatusIcon(status)

    return <>{StatusIcon && <StatusIcon color="primary" />}</>
  }

  return (
    <>
      <StatusButton
        disabled={disabled}
        loading={loading}
        variant="outlined"
        size="small"
        status={status}
        startIcon={renderStatusIcon(status)}
        endIcon={
          !!transitions?.length &&
          !disabled &&
          (menuEl ? <ExpandLessIcon /> : <ExpandMoreIcon />)
        }
        onClick={(
          event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        ): void => {
          handleOpenMenu(event)
        }}
      >
        {formatMessage({
          id: CASE_TASK_STATUS_LABEL[status],
        })}
      </StatusButton>

      <Menu anchorEl={menuEl} open={openMenu} onClose={handleCloseMenu}>
        {transitions?.map((taskStatus) => (
          <MenuItem
            value={taskStatus}
            key={taskStatus}
            selected={taskStatus === status}
            onClick={(): void => {
              handleUpdateStatus(taskStatus)
            }}
          >
            <ListItemIcon>{renderStatusIcon(taskStatus)}</ListItemIcon>
            <ListItemText
              primary={formatMessage({
                id: CASE_TASK_STATUS_LABEL[taskStatus],
              })}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default TaskStatusButton
