export const IMAGE_EXTENSIONS = [
  '.avif',
  '.ase',
  '.art',
  '.bmp',
  '.blp',
  '.cd5',
  '.cit',
  '.cpt',
  '.cr2',
  '.cut',
  '.dds',
  '.dib',
  '.djvu',
  '.egt',
  '.exif',
  '.gif',
  '.gpl',
  '.grf',
  '.icns',
  '.ico',
  '.iff',
  '.jng',
  '.jpeg',
  '.jpg',
  '.jfif',
  '.jp2',
  '.jps',
  '.lbm',
  '.max',
  '.miff',
  '.mng',
  '.msp',
  '.nitf',
  '.ota',
  '.pbm',
  '.pc1',
  '.pc2',
  '.pc3',
  '.pcf',
  '.pcx',
  '.pdn',
  '.pgm',
  '.PI1',
  '.PI2',
  '.PI3',
  '.pict',
  '.pct',
  '.pnm',
  '.pns',
  '.ppm',
  '.psb',
  '.psd',
  '.pdd',
  '.psp',
  '.px',
  '.pxm',
  '.pxr',
  '.qfx',
  '.raw',
  '.rle',
  '.sct',
  '.sgi',
  '.rgb',
  '.int',
  '.bw',
  '.tga',
  '.tiff',
  '.tif',
  '.vtf',
  '.xbm',
  '.xcf',
  '.xpm',
  '.3dv',
  '.amf',
  '.ai',
  '.awg',
  '.cgm',
  '.cdr',
  '.cmx',
  '.dxf',
  '.e2d',
  '.egt',
  '.eps',
  '.fs',
  '.gbr',
  '.odg',
  '.svg',
  '.stl',
  '.vrml',
  '.x3d',
  '.sxd',
  '.v2d',
  '.vnd',
  '.wmf',
  '.emf',
  '.art',
  '.xar',
  '.png',
  '.webp',
  '.jxr',
  '.hdp',
  '.wdp',
  '.cur',
  '.ecw',
  '.iff',
  '.lbm',
  '.liff',
  '.nrrd',
  '.pam',
  '.pcx',
  '.pgf',
  '.sgi',
  '.rgb',
  '.rgba',
  '.bw',
  '.int',
  '.inta',
  '.sid',
  '.ras',
  '.sun',
  '.tga',
  '.heic',
]

export const DOCUMENT_EXTENSIONS = [
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
  '.txt',
  '.odt',
  '.csv',
]

export const EMAIL_EXTENSIONS = ['.eml', '.msg', '.emlx']

export enum FILE_INPUT_ACCEPT_TYPE {
  ALL = 'ALL',
  IMAGE = 'image/*',
  DOCUMENT = 'application/msword',
  EMAIL = 'text/plain',
}

export const FILE_INPUT_ACCEPT_MAP: Record<FILE_INPUT_ACCEPT_TYPE, string[]> = {
  [FILE_INPUT_ACCEPT_TYPE.ALL]: ['*'],
  [FILE_INPUT_ACCEPT_TYPE.IMAGE]: IMAGE_EXTENSIONS,
  [FILE_INPUT_ACCEPT_TYPE.DOCUMENT]: DOCUMENT_EXTENSIONS,
  [FILE_INPUT_ACCEPT_TYPE.EMAIL]: EMAIL_EXTENSIONS,
}

export const MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD = 2000
export const MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD = 2000
