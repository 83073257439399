import { useIntl } from 'react-intl'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CommentIcon from '@mui/icons-material/Comment'
import Avatar from '@mui/material/Avatar'

import { type PublicCaseComment } from './publicCaseTypes'
import { SmallInfoText } from 'components/StyledComponents'
import usePortalSetting from 'hooks/usePortalSetting'
import ResourceList from 'components/resource/ResourceList'
import { AnonymousIcon } from 'components/icons/Icons'
import { nameInitials } from 'utils/stringUtils'
import { getThumbnailUrl } from 'utils/fileUtils'

type PublicCommentListProps = {
  comments?: PublicCaseComment[]
}

const Wrapper = styled(Stack)`
  width: 100%;
`

const SenderName = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
`

const CommentsWrapper = styled(Stack)`
  width: 100%;
  background: transparent;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  white-space: pre-line;

  .REPORTER {
    margin-right: 50px;
    background: #f5f6fe;
  }

  .MEMBER {
    margin-left: 50px;
    background: #f7f7f7;
  }
`

const CommentWrapper = styled(Stack)<{ resolved: string }>`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  background: transparent;
  margin-left: 50px;
`

const EmptyCommentWrapper = styled(Stack)`
  color: #bbc6f1;
  width: 100%;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
  padding: ${({ theme }) => theme.spacing(2)};
`

const PublicCommentList: React.FC<PublicCommentListProps> = ({ comments }) => {
  const { formatMessage } = useIntl()
  const { formatDate } = usePortalSetting()

  return (
    <Wrapper>
      <Stack spacing={2}>
        {!!comments?.length && (
          <CommentsWrapper spacing={1}>
            {comments?.map((comment) => (
              <CommentWrapper
                key={comment.id}
                resolved={''}
                paddingX={2}
                paddingY={1.5}
                className={comment.reporterComment ? 'REPORTER' : 'MEMBER'}
              >
                <Stack direction="row" spacing={1}>
                  {comment.reporterComment && (
                    <>
                      <Avatar sx={{ width: 30, height: 30 }}>
                        <AnonymousIcon />
                      </Avatar>
                      <Stack>
                        <SenderName>
                          {formatMessage({
                            id: 'case_detail.label.anonymous',
                          })}
                        </SenderName>
                        <SmallInfoText>
                          {formatDate(comment.created)}
                        </SmallInfoText>
                      </Stack>
                    </>
                  )}

                  {!comment.reporterComment && (
                    <>
                      <Avatar
                        src={getThumbnailUrl(comment.author?.avatarUrl)}
                        sx={{ width: 30, height: 30, fontSize: 16 }}
                      >
                        {nameInitials(comment.author?.name)}
                      </Avatar>
                      <Stack>
                        <SenderName>{comment.author?.name}</SenderName>
                        <SmallInfoText>
                          {formatDate(comment.created)}
                        </SmallInfoText>
                      </Stack>
                    </>
                  )}
                </Stack>

                <Stack marginTop={1} spacing={1}>
                  <Typography variant="body2">{comment.content}</Typography>
                  <ResourceList
                    resources={comment.resources}
                    size={117}
                    max={2}
                  />
                </Stack>
              </CommentWrapper>
            ))}
          </CommentsWrapper>
        )}

        {!comments?.length && (
          <EmptyCommentWrapper>
            <CommentIcon fontSize="large" />
            {formatMessage({ id: 'comment_list.text.no_comments' })}
          </EmptyCommentWrapper>
        )}
      </Stack>
    </Wrapper>
  )
}

export default PublicCommentList
