import { useIntl } from 'react-intl'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'

import { CASE_SHAREABILITY_LABEL, Shareability } from './caseConstants'
import { getShareabilityIcon } from 'components/case/caseUtils'

type CaseShareabilityIconProps = {
  shareability: Shareability
  size?: 'small' | 'medium' | 'large'
}

const Wrapper = styled(Stack)<{
  shareability: Shareability
  size: 'small' | 'medium' | 'large'
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ shareability, theme }) => {
    if (shareability === Shareability.SHAREABLE) {
      return theme.palette.primary.light
    }

    if (shareability === Shareability.PRIVATE) {
      return theme.palette.secondary.main
    }

    return theme.palette.primary.main
  }};

  color: ${({ shareability, theme }) =>
    shareability === Shareability.SHAREABLE
      ? theme.palette.common.black
      : theme.palette.common.white};
  border: none;

  & svg {
    color: ${({ shareability, theme }) =>
      shareability === Shareability.SHAREABLE
        ? theme.palette.common.black
        : theme.palette.common.white};
    font-size: ${({ size, theme }) => {
      switch (size) {
        case 'small':
          return '14px'
        case 'large':
          return '24px'
        default:
          return '16px'
      }
    }};
  }
  align-items: center;
  border-radius: ${({ theme }) => 1.5 * theme.shape.borderRadius}px;
  font-size: ${({ size, theme }) => {
    switch (size) {
      case 'small':
        return '11px'
      case 'large':
        return '14px'
      default:
        return '12px'
    }
  }};
  padding: ${({ size, theme }) => {
    switch (size) {
      case 'small':
        return '4px'
      case 'large':
        return '8px'
      default:
        return '6px'
    }
  }};
`

const CaseShareabilityIcon: React.FC<CaseShareabilityIconProps> = ({
  shareability,
  size = 'medium',
}) => {
  const { formatMessage } = useIntl()
  const ShareabilityIcon = getShareabilityIcon(shareability)

  return (
    <Wrapper shareability={shareability} direction="row" size={size}>
      {ShareabilityIcon && (
        <ShareabilityIcon
          aria-label={formatMessage({
            id: CASE_SHAREABILITY_LABEL[shareability],
          })}
        />
      )}
    </Wrapper>
  )
}

export default CaseShareabilityIcon
