import { useCallback, useEffect, useRef, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useRecoilValue, useRecoilState } from 'recoil'
import {
  hasUnsavedChangesState,
  isUnsavedConfirmDialogOpenState,
} from 'state/formStates'
import { Path } from '../commonConstants'

type UseRouteReturn = {
  goTo: (page: string, withoutConfirm?: boolean) => void
}

const useRoute = (): UseRouteReturn => {
  const navigate = useNavigate()
  const hasUnsavedChanges = useRecoilValue(hasUnsavedChangesState)
  const [isUnsavedConfirmDialogOpen, setIsUnsavedConfirmDialogOpen] =
    useRecoilState(isUnsavedConfirmDialogOpenState)
  const intendedPage = useRef<string | null>(null)
  const [searchParams] = useSearchParams()
  const portalId = searchParams.get('portalId')

  useEffect(() => {
    if (
      !isUnsavedConfirmDialogOpen &&
      !hasUnsavedChanges &&
      intendedPage.current
    ) {
      goTo(intendedPage.current)
    }
  }, [isUnsavedConfirmDialogOpen, hasUnsavedChanges])

  const goTo = useCallback(
    (page: string, withoutConfirm?: boolean): void => {
      if (hasUnsavedChanges && !withoutConfirm) {
        setIsUnsavedConfirmDialogOpen(true)
        intendedPage.current = page
      } else {
        let url = page

        if (
          portalId &&
          !page.includes('portalId=') &&
          page !== Path.PORTALS_LIST &&
          page !== Path.LOGOUT &&
          page !== Path.LOGIN
        ) {
          url = page.includes('?')
            ? `${page}&portalId=${portalId}`
            : `${page}?portalId=${portalId}`
        }

        navigate(url)
      }
    },
    [hasUnsavedChanges, portalId],
  )

  const memoizedValue = useMemo(
    () => ({
      goTo,
    }),
    [goTo],
  )

  return memoizedValue
}

export default useRoute
