import { useContext, type ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import AuthContext from '../../context/AuthContext'
import useMember from 'hooks/useMember'
import { PATH_ACCESSES, type Path } from '../../commonConstants'
import Loader from 'components/loader/Loader'

type AuthGuardProps = {
  component: ReactNode
}

const AuthGuard: React.FC<AuthGuardProps> = ({ component }) => {
  const { authData, isAuthLoading } = useContext(AuthContext)
  const { pathname } = useLocation()
  const { checkAccesses } = useMember()

  const mainPath = pathname.split('/')[1]
  const accesses = PATH_ACCESSES[`/${mainPath}` as Path]

  if (isAuthLoading) {
    return <Loader />
  }

  if (authData && accesses && !checkAccesses(accesses)) {
    return null
  }

  return authData ? <>{component}</> : <Navigate to="/login" replace />
}

export default AuthGuard
