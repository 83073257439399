export const ITEM_COUNTRY_CODE_MAX_LENGTH = 2
export const ITEM_NAME_MAX_LENGTH = 50
export const ITEM_WEBSITE_MAX_LENGTH = 1000
export const ITEM_DESCRIPTION_MAX_LENGTH = 1000
export const ITEM_FEED_URL_MAX_LENGTH = 1000
export const ITEM_CONTACT_TITLE_MAX_LENGTH = 100
export const ITEM_REPORT_DISCLAIMER_MAX_LENGTH = 1000
export const ITEM_ZIP_CODES_MAX_LENGTH = 5000
export const ITEM_PUBLIC_RETENTION_DAYS = 30

export enum ItemContactMethodType {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
}

export enum ItemPrivacyMode {
  COMMUNITY_MODE_PRIVATE_REPORTING = 'COMMUNITY_MODE_PRIVATE_REPORTING',
  COMMUNITY_MODE = 'COMMUNITY_MODE',
  OPEN_MODE_PRIVATE_REPORTING = 'OPEN_MODE_PRIVATE_REPORTING',
  OPEN_MODE = 'OPEN_MODE',
}

export type PortalItemContactMethod = {
  type: ItemContactMethodType
  contact: string
}
