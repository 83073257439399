export enum CaseAccess {
  ALL = 'ALL',
  ITEM_BASED = 'ITEM_BASED',
  TASK_BASED = 'TASK_BASED',
  NONE = 'NONE',
}

export enum FeatureAccess {
  NONE = 'NONE',
  READ = 'READ',
  WRITE = 'WRITE',
}

export enum CaseHandling {
  CAN_CREATE_CASE = 'CAN_CREATE_CASE',
  CAN_DELETE_CASE = 'CAN_DELETE_CASE',
  CAN_EDIT_CASE_TITLE = 'CAN_EDIT_CASE_TITLE',
  CAN_EDIT_CASE_CATEGORIES = 'CAN_EDIT_CASE_CATEGORIES',
  CAN_UPDATE_CASE_STATUS = 'CAN_UPDATE_CASE_STATUS',
  CAN_MAKE_VIDEO_CALL = 'CAN_MAKE_VIDEO_CALL',
  CAN_SEND_COMMENT = 'CAN_SEND_COMMENT',
  CAN_DELETE_COMMENT = 'CAN_DELETE_COMMENT',
  CAN_ADD_FILE = 'CAN_ADD_FILE',
  CAN_EDIT_FILE = 'CAN_EDIT_FILE',
  CAN_DELETE_FILE = 'CAN_DELETE_FILE',
  CAN_ADD_TASKS = 'CAN_ADD_TASKS',
  CAN_SET_TASK_DEADLINE = 'CAN_SET_TASK_DEADLINE',
  CAN_ASSIGN_TASK = 'CAN_ASSIGN_TASK',
  CAN_UPDATE_TASK_STATUS = 'CAN_UPDATE_TASK_STATUS',
}

export enum PortalSection {
  ALERTS = 'ALERTS',
  CASES = 'CASES',
  CATEGORIES = 'CATEGORIES',
  CONTACTS = 'CONTACTS',
  CUSTOMERS = 'CUSTOMERS',
  ITEMS = 'ITEMS',
  MEMBERS = 'MEMBERS',
  ROLES = 'ROLES',
  RULES = 'RULES',
  SETTINGS = 'SETTINGS',
  SUPPLIERS = 'SUPPLIERS',
  TEAMS = 'TEAMS',
  POLLS = 'POLLS',
  COMMENT_TEMPLATES = 'COMMENT_TEMPLATES',
}

export enum RoleAdminSection {
  categories = 'categories',
  customers = 'customers',
  items = 'items',
  members = 'members',
  roles = 'roles',
  settings = 'settings',
  portals = 'portals',
  rules = 'rules',
  teams = 'teams',
  suppliers = 'suppliers',
  alerts = 'alerts',
  contacts = 'contacts',
  polls = 'polls',
  commentTemplates = 'commentTemplates',
}
