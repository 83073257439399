import { atom } from 'recoil'
import { type GridFilterModel } from '@mui/x-data-grid'

const filterModelState = atom<GridFilterModel>({
  key: 'filterModelState',
  default: {
    items: [],
  },
})

export { filterModelState }
