import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react'
import { useIntl } from 'react-intl'
import { nanoid } from 'nanoid'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import { storage } from '../firebase'
import {
  generateFilenameForUpload,
  generateFilenameWithType,
  generateBase64FromBlob,
  isImageFile,
  shrinkImageViaCanvasElement,
  generateFileFormat,
} from 'utils/fileUtils'
import { useRecoilState, useSetRecoilState } from 'recoil'
import {
  hasNewFileState,
  isFileUploadingState,
  processedFormFilesState,
} from 'state/formStates'
import { type ProcessedFile } from 'utils/fileTypes'
import { PromiseStatus } from '../commonConstants'

const MAX_FILE_SIZE = 1024 * 1024 * 10

type UseFileUploadReturn = {
  setSelectedFiles: Dispatch<SetStateAction<File[]>>
  processedFormFiles: ProcessedFile[]
  clearProcessedFiles: () => void
  handleDeleteFile: (targetId: number | string) => void
  errors: string[]
  isFileUploading: boolean
}

type UseFileUpload = (param: {
  formName: string
  limit: number
  toJpg?: boolean
  isPublic?: boolean
  maxWidth: number
  maxHeight: number
}) => UseFileUploadReturn

const useFileUpload: UseFileUpload = ({
  formName,
  limit,
  toJpg = false,
  isPublic = false,
  maxWidth,
  maxHeight,
}) => {
  const { formatMessage } = useIntl()
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [errors, setErrors] = useState<string[]>([])
  const [isFileUploading, setIsFileUploading] = useRecoilState(
    isFileUploadingState(formName),
  )
  const [processedFormFiles, setProcessedFormFiles] = useRecoilState(
    processedFormFilesState(formName),
  )
  const setHasNewFile = useSetRecoilState(hasNewFileState)

  const processFiles = async (files: File[]): Promise<void> => {
    const newErrors: string[] = []
    const results: ProcessedFile[] = []

    await Promise.all(
      files.map(async (file) => {
        if (!isImageFile(file.name) && file.size > MAX_FILE_SIZE) {
          newErrors.push(
            formatMessage(
              { id: 'image_uploader.error.exceed_size' },
              { filename: file.name },
            ),
          )
        } else if (isImageFile(file.name) && results.length < limit) {
          try {
            const imageBase64 = await generateBase64FromBlob(file)
            const { blob, base64 } = await shrinkImageViaCanvasElement({
              imageBase64,
              maxWidth,
              maxHeight,
              toJpg,
            })

            const newFilename = generateFilenameWithType(
              file.name,
              toJpg ? 'jpg' : 'png',
            )
            results.push({
              id: nanoid(6),
              formName,
              base64,
              file: new File([blob], newFilename, { type: blob.type }),
              format: generateFileFormat(file.name),
            })
          } catch (e) {
            newErrors.push(
              formatMessage(
                { id: 'image_uploader.error.invalid_format' },
                { filename: file.name },
              ),
            )
          }
        } else if (results.length < limit) {
          results.push({
            id: nanoid(6),
            formName,
            file,
            format: generateFileFormat(file.name),
          })
        }
      }),
    )

    setErrors(newErrors)

    setProcessedFormFiles((preResults) => {
      const newResults = [...preResults, ...results]
      if (newResults.length > limit) {
        newResults.length = limit
      }
      return newResults
    })

    if (results.length) {
      setHasNewFile(true)
    }
  }

  useEffect(() => {
    if (selectedFiles.length) {
      void processFiles(selectedFiles)
    }
  }, [selectedFiles])

  const handleDeleteFile = useCallback(
    (targetId: number | string): void => {
      if (processedFormFiles.length === 1) {
        setHasNewFile(false)
      }

      setProcessedFormFiles((prevResults) =>
        prevResults.filter((result) => result.id !== targetId),
      )
    },
    [processedFormFiles],
  )

  const uploadPendingFiles = useMemo(
    () => processedFormFiles.filter((result) => !result.status),
    [processedFormFiles],
  )

  const uploadFile = async (pendingFile: ProcessedFile): Promise<string> => {
    return await new Promise<string>((resolve, reject) => {
      const storageRef = ref(
        storage,
        `/${isPublic ? 'public' : 'uploads'}/${generateFilenameForUpload(
          pendingFile.file.name,
        )}`,
      )
      const uploadTask = uploadBytesResumable(storageRef, pendingFile.file)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          )
          console.log(percent)
        },
        (err) => {
          console.log(err)
          reject(err)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then((url) => {
              setProcessedFormFiles((prevResults) =>
                prevResults.map((prevResult) => ({
                  ...prevResult,
                  status:
                    prevResult.id === pendingFile.id
                      ? PromiseStatus.FULFILLED
                      : prevResult.status,
                  url: prevResult.id === pendingFile.id ? url : prevResult.url,
                })),
              )
              resolve(url)
            })
            .catch((error) => {
              setProcessedFormFiles((prevResults) =>
                prevResults.map((prevResult) => ({
                  ...prevResult,
                  status:
                    prevResult.id === pendingFile.id
                      ? PromiseStatus.REJECTED
                      : prevResult.status,
                })),
              )
              setErrors((preErrors) => [
                ...preErrors,
                formatMessage(
                  { id: 'image_uploader.error.network' },
                  { filename: pendingFile.file.name },
                ),
              ])
              reject(error)
            })
        },
      )
    })
  }

  const uploadAllFiles = async (
    pendingFiles: ProcessedFile[],
  ): Promise<void> => {
    await Promise.allSettled(
      pendingFiles.map(async (pendingFile) => {
        await uploadFile(pendingFile)
      }),
    )
  }

  useEffect(() => {
    if (uploadPendingFiles.length) {
      setIsFileUploading(true)
      void uploadAllFiles(uploadPendingFiles)
    } else {
      setIsFileUploading(false)
    }
  }, [uploadPendingFiles])

  const clearProcessedFiles = (): void => {
    setProcessedFormFiles([])
  }

  useEffect(
    () => () => {
      clearProcessedFiles()
      setSelectedFiles([])
    },
    [],
  )

  const memoizedValue = useMemo(
    () => ({
      setSelectedFiles,
      processedFormFiles,
      clearProcessedFiles,
      handleDeleteFile,
      errors,
      isFileUploading,
    }),
    [
      setSelectedFiles,
      processedFormFiles,
      clearProcessedFiles,
      handleDeleteFile,
      errors,
      isFileUploading,
    ],
  )

  return memoizedValue
}

export default useFileUpload
