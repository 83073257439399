import { useEffect } from 'react'

import useRoute from 'hooks/useNavigate'
import { Path } from '../commonConstants'
import useMember from 'hooks/useMember'

const LogoutPage: React.FC = () => {
  const { goTo } = useRoute()
  const { clearSession } = useMember()

  useEffect(() => {
    clearSession()
    goTo(Path.LOGIN)
  }, [])

  return null
}

export default LogoutPage
