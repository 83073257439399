import { useMemo } from 'react'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'

import { CategoryTag } from 'components/StyledComponents'
import { type LocalizedCategory } from 'components/category/categoryTypes'
import usePortalSetting from 'hooks/usePortalSetting'

type CategoryTagListProps = {
  category?: LocalizedCategory
}

const Wrapper = styled(Stack)`
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(0.5)};
`

const CategoryTagList: React.FC<CategoryTagListProps> = ({ category }) => {
  const { extractLocalizedCategoryNames } = usePortalSetting()

  const categoryLabels = useMemo((): string[] => {
    if (!category) {
      return []
    }

    return extractLocalizedCategoryNames(category)
  }, [category])

  if (!category) {
    return null
  }

  return (
    <Wrapper direction={'row'} alignItems={'center'} data-testid="category-tag">
      {categoryLabels.map((categoryLabel) => (
        <CategoryTag key={categoryLabel} label={categoryLabel} size="small" />
      ))}
    </Wrapper>
  )
}

export default CategoryTagList
