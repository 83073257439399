import {
  type ReactElement,
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect,
} from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { getAnalytics, logEvent } from 'firebase/analytics'
import useSWR from 'swr'
import { useSnackbar } from 'notistack'
import styled from '@mui/material/styles/styled'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import MuiTab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EditIcon from '@mui/icons-material/Edit'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import useRoute from 'hooks/useNavigate'
import {
  BackButtonGrey,
  ContentWrapper,
  SmallInfoText,
  Link,
  MainHeader,
  LightSubHeader,
} from 'components/StyledComponents'
import { type Resource, type CaseComment } from 'types'
import { type CaseInfo } from 'components/case/caseTypes'
import { shortenUuid } from 'utils/stringUtils'
import { portalSettingState } from 'state/portalSettingStates'
import TaskList from 'components/task/TaskList'
import ResourceList from 'components/resource/ResourceList'
import FileList from 'components/file/FileList'
import CategoryTagList from 'components/category/CategoryTagList'
import { AnonymousIcon, ForwardingArrowIcon } from 'components/icons/Icons'
import { Path } from '../commonConstants'
import {
  CaseFileSource,
  CaseTaskStatus,
  CaseStatus,
  type VirtualPortalCaseStatus,
  Shareability,
  SHAREABILITY_TRANSITIONS,
  CaseTab,
  CaseResponsiveness,
} from 'components/case/caseConstants'
import usePortalSetting from 'hooks/usePortalSetting'
import useApi from 'hooks/useApi'
import CaseStatusButton from 'components/case/CaseStatusButton'
import CommentList from 'components/comment/CommentList'
import ErrorIcon from 'assets/icons/error_icon.svg'
import CaseStatusTag from 'components/case/CaseStatusTag'
import CaseShareabilityButton from 'components/case/CaseShareabilityButton'
import UserAvatar from 'components/user/UserAvatar'
import CaseLogList from 'components/log/CaseLogList'
import { type CaseLog } from 'components/log/logTypes'
import { filterModelState } from 'state/logStates'
import { type CaseTask } from 'components/task/taskTypes'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import useMember from 'hooks/useMember'
import CaseForwardingDialog from 'components/case/CaseForwardingDialog'

type TaskTransitionRawData = {
  taskId: string
  caseId: string
  allowedStatusTransitions: CaseTaskStatus[]
}

const InfoWrapper = styled(Stack)`
  border: none;
  box-shadow: none;
  background-color: ${({ theme }) => theme.palette.info.light};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(1.5)};
  gap: ${({ theme }) => theme.spacing(1)};
`

const Tab = styled(MuiTab)`
  padding: 10px 16px;
  min-height: 0;
  color: inherit;
  font-weight: 300;

  .counter {
    padding: 2px 6px;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    background: ${({ theme }) => theme.palette.primary.light};
    font-size: 0.8rem;
    color: ${({ theme }) => theme.palette.text.primary};
  }

  &.Mui-selected {
    color: ${({ theme }) => theme.palette.text.primary};
    background: ${({ theme }) => theme.palette.primary.light};
    border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
    font-weight: 500;

    .counter {
      background: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.black};
    }
  }
`

const TabPanel = styled(Box)``

const LeftTabPanel = styled(TabPanel)`
  padding: 0;
  flex-grow: 1;
`

const RightTabPanel = styled(LeftTabPanel)`
  padding-top: 24px;
  overflow: hidden;
`

const LeftTabsWrapper = styled(Box)`
  position: -webkit-sticky;
  position: sticky;
  background: ${({ theme }) => theme.palette.background.paper};
  z-index: 1;
  top: 0;
`

const UserCard = styled(CardHeader)`
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
`

const CaseDetailPage: React.FC = () => {
  const { formatMessage, locale } = useIntl()
  const { caseId } = useParams()
  const { goTo } = useRoute()
  const [rightActiveTab, setRightActiveTab] = useState<CaseTab>(CaseTab.TASKS)
  const [leftActiveTab, setLeftActiveTab] = useState<CaseTab>(
    CaseTab.INFORMATION,
  )
  const portalSetting = useRecoilValue(portalSettingState)
  const { formatDate } = usePortalSetting()
  const [caseMenuEl, setCaseMenuEl] = useState<null | HTMLElement>(null)
  const openCaseMenu = Boolean(caseMenuEl)
  const { sendPostRequest } = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const leftContainer = useRef<HTMLDivElement>(null)
  const [isCancelConfirmDialogOpen, setIsCancelConfirmDialogOpen] =
    useState(false)
  const [isToPrivateConfirmDialogOpen, setIsToPrivateConfirmDialogOpen] =
    useState(false)
  const [statusTransition, setStatusTransition] = useState<
    CaseStatus[] | VirtualPortalCaseStatus[]
  >([])
  const [isStatusUpdating, setIsStatusUpdating] = useState(false)
  const [isShareabilityUpdating, setIsShareabilityUpdating] = useState(false)
  const [taskStatusTransitionsMap, setTaskStatusTransitionMap] = useState<
    Map<string, CaseTaskStatus[]>
  >(new Map())
  const { checkAccesses } = useMember()
  const setFilterModel = useSetRecoilState(filterModelState)
  const analytics = getAnalytics()

  const [isCaseForwardingDialogOpen, setIsCaseForwardingDialogOpen] =
    useState(false)

  const { data: caseInfoData, mutate: mutateCaseInfo } = useSWR<CaseInfo>(
    portalSetting && caseId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/cases/${caseId}`
      : null,
    {
      revalidateOnFocus: true,
      refreshInterval: 60000,
    },
  )
  const {
    data: caseFilesData,
    isLoading: isFilesLoading,
    mutate: mutateCaseFiles,
  } = useSWR<Resource[]>(
    portalSetting && caseId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/cases/${caseId}/resources`
      : null,
    {
      revalidateOnFocus: true,
      refreshInterval: 60000,
    },
  )
  const {
    data: tasksData,
    isLoading: isTasksLoading,
    mutate: mutateCaseTasks,
  } = useSWR<CaseTask[]>(
    portalSetting && caseId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/cases/${caseId}/tasks`
      : null,
    {
      revalidateOnFocus: true,
      refreshInterval: 60000,
    },
  )
  const { data: logsData, isLoading: isLogsLoading } = useSWR<CaseLog[]>(
    portalSetting && caseId && rightActiveTab === CaseTab.LOGS
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/cases/${caseId}/logs`
      : null,
    {
      revalidateOnFocus: true,
      refreshInterval: 60000,
    },
  )

  useEffect(() => {
    if (leftContainer.current && leftActiveTab === CaseTab.COMMENTS) {
      leftContainer.current.scrollTo({
        top: leftContainer.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }, [leftActiveTab])

  const fetchCaseStatusTransitions = useCallback(async () => {
    if (portalSetting) {
      setIsStatusUpdating(true)
      const res = await sendPostRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/cases/${caseId}:listTransitions`,
      )
      const newTransitions = await res.json()
      if (newTransitions) {
        setStatusTransition(newTransitions.allowedStatusTransitions)
      }
      setIsStatusUpdating(false)
    }
  }, [portalSetting])

  useEffect(() => {
    if (caseInfoData) {
      void fetchCaseStatusTransitions()
      logEvent(analytics, 'web_case_detail_page_view')
    }
  }, [caseInfoData])

  const fetchTasksStatusTransitions = useCallback(async () => {
    if (portalSetting && tasksData?.length) {
      const res = await sendPostRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/cases/${caseId}/tasks:listTransitions`,
      )
      const newTransitions: TaskTransitionRawData[] = await res.json()
      const newTransitionsMap = new Map<string, CaseTaskStatus[]>()
      newTransitions.forEach((transition) => {
        newTransitionsMap.set(
          transition.taskId,
          transition.allowedStatusTransitions,
        )
      })
      setTaskStatusTransitionMap(newTransitionsMap)
    }
  }, [portalSetting, tasksData])

  useEffect(() => {
    if (tasksData?.length) {
      void fetchTasksStatusTransitions()
    }
  }, [tasksData])

  const handleSaveTask = useCallback(
    (newTask: CaseTask): void => {
      const existingIndex = (tasksData ?? []).findIndex(
        (action) => action.id === newTask.id,
      )

      const copyData = [...(tasksData ?? [])]

      if (existingIndex > -1) {
        copyData[existingIndex] = {
          ...copyData[existingIndex],
          ...newTask,
        }
      } else {
        copyData.push(newTask)
      }
      void mutateCaseTasks(copyData)
    },
    [tasksData],
  )

  const handleSaveFile = useCallback(
    (targetFile: Resource): void => {
      const existingFileIndex = caseFilesData?.findIndex(
        (caseFile) => caseFile.id === targetFile.id,
      )

      if (
        caseFilesData &&
        caseFilesData?.length > 0 &&
        existingFileIndex !== undefined &&
        existingFileIndex > -1
      ) {
        caseFilesData[existingFileIndex] = targetFile
        void mutateCaseFiles(caseFilesData)
      } else {
        void mutateCaseFiles([targetFile, ...(caseFilesData ?? [])])
      }
    },
    [caseFilesData],
  )

  const handleDeleteFile = useCallback(
    (targetFile: Resource): void => {
      const results = caseFilesData?.filter(
        (caseFile) => caseFile.id !== targetFile.id,
      )
      void mutateCaseFiles(results)
    },
    [caseFilesData],
  )

  const reportResources = useMemo(
    () =>
      caseInfoData?.resources.filter(
        (resource: Resource) =>
          resource.source === CaseFileSource.REPORT_UPLOAD,
      ),
    [caseInfoData],
  )

  const handleSendComment = useCallback(
    async (newResource: CaseComment): Promise<void> => {
      if (caseInfoData) {
        await mutateCaseInfo({
          ...caseInfoData,
          comments: [...caseInfoData.comments, newResource],
        })
        await mutateCaseFiles()
      }
    },
    [caseInfoData],
  )

  const handleResolveComments = useCallback(async (): Promise<void> => {
    if (caseInfoData && portalSetting) {
      try {
        await sendPostRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/cases/${caseId}/comments:resolve`,
        )

        await mutateCaseInfo({
          ...caseInfoData,
          commentsResolved: true,
          comments: caseInfoData.comments.map((comment) => ({
            ...comment,
            resolved: true,
          })),
        })
        enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
          variant: 'success',
        })
      } catch (error) {
        console.error(error)
      }
    }
  }, [portalSetting, caseInfoData, caseId])

  const updateStatus = useCallback(
    async (newStatus: CaseStatus | VirtualPortalCaseStatus): Promise<void> => {
      if (caseInfoData && portalSetting) {
        try {
          setIsStatusUpdating(true)
          const response = await sendPostRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting.id
            }/cases/${caseId}:transition?to=${newStatus}`,
          )

          const responseData = await response.json()
          if (responseData) {
            setStatusTransition(responseData.allowedStatusTransitions)
            await mutateCaseInfo({
              ...caseInfoData,
              status: newStatus,
            })
            enqueueSnackbar(
              formatMessage({ id: 'general.text.changes_saved' }),
              {
                variant: 'success',
              },
            )
          }
        } catch (error) {
          console.error(error)
        } finally {
          setIsStatusUpdating(false)
        }
      }
    },
    [portalSetting, caseInfoData, caseId],
  )

  const updateShareability = useCallback(
    async (shareability: Shareability): Promise<void> => {
      if (caseInfoData && portalSetting) {
        try {
          const transition = SHAREABILITY_TRANSITIONS[shareability]
          setIsShareabilityUpdating(true)
          const response = await sendPostRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting.id
            }/cases/${caseId}:${transition}`,
          )

          const responseData = await response.json()
          if (responseData) {
            await mutateCaseInfo({
              ...caseInfoData,
              shareability,
            })
            enqueueSnackbar(
              formatMessage({ id: 'general.text.changes_saved' }),
              {
                variant: 'success',
              },
            )
          }
        } catch (error) {
          console.error(error)
        } finally {
          setIsShareabilityUpdating(false)
        }
      }
    },
    [portalSetting, caseInfoData, caseId],
  )

  const handleCloseCancelConfirmDialog = (): void => {
    setIsCancelConfirmDialogOpen(false)
  }

  const handleConfirmedCancel = (): void => {
    void updateStatus(CaseStatus.CANCELLED)

    setIsCancelConfirmDialogOpen(false)
  }

  const handleUpdateCaseStatus = (
    newStatus: CaseStatus | VirtualPortalCaseStatus,
  ): void => {
    if (newStatus === CaseStatus.CANCELLED) {
      setIsCancelConfirmDialogOpen(true)
    } else {
      void updateStatus(newStatus)
    }
  }

  const handleUpdateTaskCaches = useCallback(
    (newTask: CaseTask, newAllowedStatues?: CaseTaskStatus[]): void => {
      if (tasksData?.length) {
        const allTasks = [...tasksData]
        const taskIndex = allTasks.findIndex((task) => task.id === newTask.id)
        if (taskIndex !== -1) {
          allTasks[taskIndex] = newTask
        }

        void mutateCaseTasks(allTasks)
      } else {
        void mutateCaseTasks([newTask])
      }

      void mutateCaseInfo()
      if (newAllowedStatues) {
        const newTransitionsMap = new Map(taskStatusTransitionsMap)
        newTransitionsMap.set(newTask.id, newAllowedStatues)
        setTaskStatusTransitionMap(newTransitionsMap)
      }
    },
    [taskStatusTransitionsMap, tasksData],
  )

  const handleReloadTasks = useCallback((): void => {
    void mutateCaseTasks()
  }, [])

  const filteredComments = useMemo(
    () =>
      (caseInfoData?.comments ?? [])
        .filter((comment) => !!comment.content || comment.resources.length)
        .sort(
          (a, b) =>
            new Date(a.updated).getTime() - new Date(b.updated).getTime(),
        ),
    [caseInfoData],
  )

  const hasUnfinishedTasks = useMemo((): boolean => {
    if (!tasksData) {
      return false
    }

    return !!tasksData.find(
      (task) =>
        task.status !== CaseTaskStatus.DONE &&
        task.status !== CaseTaskStatus.CANCELLED,
    )
  }, [tasksData])

  const sortedFiles = useMemo((): Resource[] => {
    if (!caseFilesData) {
      return []
    }

    return caseFilesData.sort((a, b) => {
      const aUpdated = new Date(a.updated)
      const bUpdated = new Date(b.updated)
      return bUpdated.getTime() - aUpdated.getTime()
    })
  }, [caseFilesData])

  if (!caseId) {
    throw Error('caseId not found!')
  }

  if (!caseInfoData) {
    return null
  }

  const handleVideoCall = (): void => {
    if (caseInfoData.reporter?.user?.id) {
      goTo(`${Path.VIDEO_CALL_CASE}/${caseId}`)
    }
  }

  const handleGoBack = (): void => {
    goTo(Path.CASES_LIST)
  }

  const handleOpenCaseMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setCaseMenuEl(event.currentTarget)
  }

  const handleCloseCaseMenu = (): void => {
    setCaseMenuEl(null)
  }

  const handleEditCase = (): void => {
    handleCloseCaseMenu()
    goTo(`${Path.CASES_EDIT}/${caseId}`)
  }

  const handleLeftTabChange = (
    event: React.SyntheticEvent,
    newValue: CaseTab,
  ): void => {
    setLeftActiveTab(newValue)
  }

  const handleRightTabChange = (
    event: React.SyntheticEvent,
    newValue: CaseTab,
  ): void => {
    setRightActiveTab(newValue)
  }

  const openMap = (position?: google.maps.LatLngLiteral): void => {
    if (position) {
      const googleMapsUrl = `https://www.google.com/maps?q=${position.lat},${position.lng}`
      window.open(googleMapsUrl, '_blank')
    }
  }

  const renderTabLabel = ({
    labelKey,
    counter,
    showBadge = false,
  }: {
    labelKey: string
    counter?: number
    showBadge?: boolean
  }): ReactElement => (
    <Stack direction="row" spacing={1} alignItems="center">
      <Box>
        {formatMessage({
          id: labelKey,
        })}
      </Box>
      {!!counter && (
        <Badge color="error" variant="dot" invisible={!showBadge}>
          <Box className="counter">{counter}</Box>
        </Badge>
      )}
    </Stack>
  )

  const handleUpdateShareability = (shareability: Shareability): void => {
    if (shareability === Shareability.PRIVATE) {
      setIsToPrivateConfirmDialogOpen(true)
    } else {
      void updateShareability(shareability)
    }
  }

  const handleConfirmedToPrivate = (): void => {
    void updateShareability(Shareability.PRIVATE)

    setIsToPrivateConfirmDialogOpen(false)
  }

  const handleCloseToPrivateConfirmDialog = (): void => {
    setIsToPrivateConfirmDialogOpen(false)
  }

  const goToLog = (referenceId: string): void => {
    setFilterModel({
      items: [{ field: 'referenceId', operator: 'equals', value: referenceId }],
    })
    setRightActiveTab(CaseTab.LOGS)
  }

  const handleOpenCaseForwardingDialog = (): void => {
    handleCloseCaseMenu()
    setIsCaseForwardingDialogOpen(true)
  }

  const handleCloseCaseForwardingDialog = (): void => {
    void mutateCaseInfo()
    setIsCaseForwardingDialogOpen(false)
  }

  return (
    <Grid container height={'100%'} columnSpacing={2} paddingX={2}>
      <Grid item md={5} sm={12} paddingY={2} height={'100%'}>
        <ContentWrapper height={'100%'}>
          <Box padding={2} height={'100%'}>
            <Stack height={'100%'} spacing={1.5}>
              <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <BackButtonGrey
                  onClick={handleGoBack}
                  size="small"
                  aria-label={formatMessage({
                    id: 'general.icon_button.go_back',
                  })}
                >
                  <ArrowBackIcon />
                </BackButtonGrey>
                <MainHeader
                  onClick={() => {
                    goToLog(caseInfoData.id)
                  }}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  {formatMessage(
                    { id: 'case_detail.header' },
                    { caseUid: shortenUuid(caseInfoData.id) },
                  )}
                </MainHeader>

                {checkAccesses({
                  [PortalSection.CASES]: [FeatureAccess.READ],
                }) ? (
                  <CaseStatusButton
                    loading={isStatusUpdating}
                    status={caseInfoData.status}
                    transitions={statusTransition}
                    onUpdateStatus={handleUpdateCaseStatus}
                  />
                ) : (
                  <CaseStatusTag status={caseInfoData.status} />
                )}

                {caseInfoData.status !== CaseStatus.CANCELLED &&
                  caseInfoData.status !== CaseStatus.FORWARDED &&
                  caseInfoData.status !== CaseStatus.FORWARDING &&
                  checkAccesses({
                    [PortalSection.CASES]: [FeatureAccess.READ],
                  }) && (
                    <IconButton
                      onClick={handleOpenCaseMenu}
                      size="small"
                      aria-label={formatMessage({
                        id: 'general.icon_button.see_more',
                      })}
                      data-testid="case-see-more"
                    >
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                  )}
              </Stack>

              <Stack
                ref={leftContainer}
                spacing={2}
                flexGrow={1}
                overflow="auto"
                position="relative"
              >
                <InfoWrapper data-testid="case-basic">
                  <Stack direction="row" alignItems="center">
                    <Box flexGrow={1}>
                      <SmallInfoText>
                        {formatMessage(
                          { id: 'case_detail.label.case_created' },
                          { date: formatDate(caseInfoData.created) },
                        )}
                      </SmallInfoText>
                    </Box>

                    <CaseShareabilityButton
                      shareability={caseInfoData.shareability}
                      loading={isShareabilityUpdating}
                      onUpdateShareability={handleUpdateShareability}
                    />
                  </Stack>

                  <Stack spacing={1}>
                    <LightSubHeader>{caseInfoData.title}</LightSubHeader>
                    <Link
                      variant="body2"
                      onClick={() => {
                        openMap(caseInfoData.location?.position)
                      }}
                    >
                      <address>{caseInfoData.location?.address}</address>
                    </Link>

                    <Typography
                      variant="body2"
                      gutterBottom={true}
                      data-testid="item-name"
                    >
                      {caseInfoData.item?.name?.localisedValues[locale]}
                    </Typography>

                    <CategoryTagList category={caseInfoData.category} />
                  </Stack>

                  {!caseInfoData.anonymous && caseInfoData.creator?.active && (
                    <UserCard
                      data-testid="creator-info"
                      avatar={
                        <UserAvatar
                          avatarUrl={caseInfoData.creator.avatarUrl}
                          size={30}
                          name={caseInfoData.creator.fullName}
                          email={caseInfoData.creator.email}
                          phone={caseInfoData.creator.phone}
                        />
                      }
                      title={`${caseInfoData.creator.firstName} ${caseInfoData.creator.lastName}`}
                      subheader={
                        <>
                          <Typography variant="body2">
                            {caseInfoData.creator.email}
                          </Typography>
                          <Typography variant="body2">
                            {caseInfoData.creator.phone}
                          </Typography>
                        </>
                      }
                    />
                  )}
                </InfoWrapper>

                <TabContext value={leftActiveTab}>
                  <LeftTabsWrapper
                    sx={{ borderBottom: 1, borderColor: 'divider' }}
                    id="left-tabs-wrapper"
                  >
                    <TabList onChange={handleLeftTabChange}>
                      <Tab
                        label={formatMessage({
                          id: 'case_detail.tab.label.information',
                        })}
                        value={CaseTab.INFORMATION}
                        tabIndex={0}
                      />
                      {caseInfoData.responsiveness !==
                        CaseResponsiveness.NONE && (
                        <Tab
                          label={renderTabLabel({
                            labelKey: 'case_detail.tab.label.comments',
                            counter: filteredComments.length,
                            showBadge: !caseInfoData?.commentsResolved,
                          })}
                          value={CaseTab.COMMENTS}
                          tabIndex={0}
                        />
                      )}
                    </TabList>
                  </LeftTabsWrapper>
                  <LeftTabPanel hidden={leftActiveTab !== CaseTab.INFORMATION}>
                    <InfoWrapper data-testid="case-info">
                      <SmallInfoText>
                        {formatMessage(
                          { id: 'case_detail.label.report_created' },
                          { date: formatDate(caseInfoData.occurred) },
                        )}
                      </SmallInfoText>

                      {!caseInfoData.anonymous && caseInfoData.reporter && (
                        <UserCard
                          data-testid="reporter-info"
                          avatar={
                            <UserAvatar
                              avatarUrl={caseInfoData.reporter.user?.avatarUrl}
                              size={30}
                              name={
                                caseInfoData.reporter.fullName ||
                                caseInfoData.reporter.user?.fullName
                              }
                              email={
                                caseInfoData.reporter.email ||
                                caseInfoData.reporter.user?.email
                              }
                              phone={
                                caseInfoData.reporter.phone ||
                                caseInfoData.reporter.user?.phone
                              }
                            />
                          }
                          action={
                            caseInfoData.reporter.user?.supportsVideoCall &&
                            caseInfoData.status !== CaseStatus.CANCELLED &&
                            caseInfoData.status !== CaseStatus.FORWARDED &&
                            caseInfoData.status !== CaseStatus.FORWARDING &&
                            process.env.REACT_APP_VIDEO_CALL_ENABLED ===
                              'true' &&
                            checkAccesses({
                              [PortalSection.CASES]: [FeatureAccess.READ],
                            }) && (
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={handleVideoCall}
                              >
                                {formatMessage({
                                  id: 'case_detail.case.button.video_inspection',
                                })}
                              </Button>
                            )
                          }
                          title={
                            caseInfoData.reporter.fullName?.trim() ||
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            `${caseInfoData.reporter.user?.firstName} ${caseInfoData.reporter.user?.lastName}`
                          }
                          subheader={
                            <>
                              <Typography variant="body2">
                                {caseInfoData.reporter.email ||
                                  caseInfoData.reporter.user?.email ||
                                  ''}
                              </Typography>
                              <Typography variant="body2">
                                {caseInfoData.reporter.phone ||
                                  caseInfoData.reporter.user?.phone ||
                                  ''}
                              </Typography>
                            </>
                          }
                        />
                      )}

                      {caseInfoData.anonymous && (
                        <UserCard
                          avatar={
                            <Avatar
                              sx={{ width: 30, height: 30 }}
                              alt={formatMessage({
                                id: 'case_detail.label.anonymous',
                              })}
                            >
                              <AnonymousIcon />
                            </Avatar>
                          }
                          title={formatMessage({
                            id: 'case_detail.label.anonymous',
                          })}
                        />
                      )}

                      <LightSubHeader>
                        {formatMessage({ id: 'case_detail.label.description' })}
                      </LightSubHeader>
                      <Typography
                        variant="body2"
                        gutterBottom={true}
                        sx={{ whiteSpace: 'pre-line' }}
                      >
                        {caseInfoData.description}
                      </Typography>

                      <ResourceList
                        resources={reportResources}
                        size={117}
                        max={5}
                      />
                    </InfoWrapper>
                  </LeftTabPanel>
                  {caseInfoData.responsiveness !== CaseResponsiveness.NONE && (
                    <LeftTabPanel hidden={leftActiveTab !== CaseTab.COMMENTS}>
                      <CommentList
                        itemId={caseInfoData.item.id}
                        comments={filteredComments}
                        isShared={
                          caseInfoData.shareability === Shareability.SHARED
                        }
                        isLimited={
                          caseInfoData.responsiveness ===
                          CaseResponsiveness.LIMITED
                        }
                        isResolved={caseInfoData.commentsResolved}
                        onResolve={handleResolveComments}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onSend={handleSendComment}
                        disabled={
                          caseInfoData.status === CaseStatus.CANCELLED ||
                          caseInfoData.status === CaseStatus.FORWARDED ||
                          caseInfoData.status === CaseStatus.FORWARDING ||
                          !checkAccesses({
                            [PortalSection.CASES]: [FeatureAccess.READ],
                          })
                        }
                        goToLog={goToLog}
                      />
                    </LeftTabPanel>
                  )}
                </TabContext>
              </Stack>
            </Stack>
          </Box>
        </ContentWrapper>
      </Grid>
      <Grid item md={7} sm={12} height={'100%'} paddingY={2}>
        <ContentWrapper height={'100%'}>
          <Stack padding={2} height={'100%'} overflow="hidden">
            <TabContext value={rightActiveTab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleRightTabChange}>
                  <Tab
                    label={renderTabLabel({
                      labelKey: 'case_detail.tab.label.actions',
                      counter: tasksData?.length,
                    })}
                    value={CaseTab.TASKS}
                    tabIndex={0}
                  />
                  <Tab
                    label={renderTabLabel({
                      labelKey: 'case_detail.tab.label.files',
                      counter: caseFilesData?.length,
                    })}
                    value={CaseTab.FILES}
                    tabIndex={0}
                  />
                  {/* <Tab
                    label={formatMessage({
                      id: 'case_detail.tab.label.notes',
                    })}
                    value="notes"
                    tabIndex={0}
                  /> */}
                  <Tab
                    label={formatMessage({
                      id: 'case_detail.tab.label.history',
                    })}
                    value={CaseTab.LOGS}
                    tabIndex={0}
                  />
                </TabList>
              </Box>
              <RightTabPanel hidden={rightActiveTab !== CaseTab.TASKS}>
                <TaskList
                  disabled={
                    caseInfoData.status === CaseStatus.CANCELLED ||
                    caseInfoData.status === CaseStatus.FORWARDED ||
                    caseInfoData.status === CaseStatus.FORWARDING
                  }
                  tasks={tasksData}
                  statusTransitionsMap={taskStatusTransitionsMap}
                  isLoading={isTasksLoading}
                  onSave={handleSaveTask}
                  onUpdateTaskCaches={handleUpdateTaskCaches}
                  onReloadTasks={handleReloadTasks}
                  goToLog={goToLog}
                />
              </RightTabPanel>
              <RightTabPanel hidden={rightActiveTab !== CaseTab.FILES}>
                <FileList
                  disabled={
                    caseInfoData.status === CaseStatus.CANCELLED ||
                    caseInfoData.status === CaseStatus.FORWARDED ||
                    caseInfoData.status === CaseStatus.FORWARDING
                  }
                  files={sortedFiles}
                  isLoading={isFilesLoading}
                  onSave={handleSaveFile}
                  onDelete={handleDeleteFile}
                  goToLog={goToLog}
                />
              </RightTabPanel>
              <RightTabPanel hidden={rightActiveTab !== CaseTab.LOGS}>
                <CaseLogList logs={logsData} isLoading={isLogsLoading} />
              </RightTabPanel>
            </TabContext>
          </Stack>
        </ContentWrapper>
        <Menu
          anchorEl={caseMenuEl}
          open={openCaseMenu}
          onClose={handleCloseCaseMenu}
        >
          <MenuItem onClick={handleEditCase}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {formatMessage({
                id: 'case_detail.menu.edit',
              })}
            </ListItemText>
          </MenuItem>
          {(caseInfoData.status === CaseStatus.NEW ||
            caseInfoData.status === CaseStatus.IN_PROGRESS ||
            caseInfoData.status === CaseStatus.NOT_FORWARDED) && (
            <MenuItem onClick={handleOpenCaseForwardingDialog}>
              <ListItemIcon>
                <ForwardingArrowIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {formatMessage({
                  id: 'case_detail.menu.forward_case',
                })}
              </ListItemText>
            </MenuItem>
          )}
        </Menu>
        <Dialog open={isCancelConfirmDialogOpen} maxWidth="xs">
          <DialogTitle textAlign="center">
            <Stack alignItems="center" spacing={2}>
              <ErrorIcon />
              <Box textAlign="center">
                {formatMessage({ id: 'case_detail.cancel_confirm.title' })}
              </Box>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <DialogContentText textAlign="center">
              {formatMessage({ id: 'case_detail.cancel_confirm.message' })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack spacing={1} width="100%">
              <Button
                onClick={handleConfirmedCancel}
                variant="contained"
                fullWidth
                color="secondary"
              >
                {formatMessage({
                  id: 'case_detail.cancel_confirm.button.cancel_case',
                })}
              </Button>
              <Button
                onClick={handleCloseCancelConfirmDialog}
                variant="outlined"
                fullWidth
                autoFocus
                color="secondary"
              >
                {formatMessage({
                  id: 'general.button.close',
                })}
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
        <Dialog open={isToPrivateConfirmDialogOpen} maxWidth="xs">
          <DialogTitle textAlign="center">
            <Stack alignItems="center" spacing={2}>
              <ErrorIcon />
              <Box textAlign="center">
                {formatMessage({ id: 'case_list.cases.header.shareability' })}
              </Box>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <DialogContentText textAlign="center">
              {formatMessage({ id: 'case_detail.to_private_confirm.message' })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack spacing={1} width="100%">
              <Button
                onClick={handleConfirmedToPrivate}
                variant="contained"
                fullWidth
                color="secondary"
              >
                {formatMessage({
                  id: 'case_detail.cancel_confirm.button.to_private_case',
                })}
              </Button>
              <Button
                onClick={handleCloseToPrivateConfirmDialog}
                variant="outlined"
                fullWidth
                color="secondary"
                autoFocus
              >
                {formatMessage({
                  id: 'general.button.close',
                })}
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
        <CaseForwardingDialog
          hasUnfinishedTasks={hasUnfinishedTasks}
          isOpen={isCaseForwardingDialogOpen}
          onClose={handleCloseCaseForwardingDialog}
        />
      </Grid>
    </Grid>
  )
}

export default CaseDetailPage
