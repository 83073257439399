import { selector } from 'recoil'
import { type PortalMemberAccess } from 'components/role/roleTypes'
import { portalSettingState } from 'state/portalSettingStates'
import { userAccessState } from 'state/userStates'

const currentPortalAccessSelector = selector<PortalMemberAccess | null>({
  key: 'currentPortalAccessSelector',
  get: ({ get }) => {
    const portalSetting = get(portalSettingState)
    const userAccess = get(userAccessState)

    if (portalSetting && userAccess?.accessiblePortals?.length) {
      const filteredPortalMemberAccess = userAccess?.accessiblePortals.find(
        (portalMemberAccess) =>
          portalMemberAccess.portalId === portalSetting.id,
      )
      return filteredPortalMemberAccess ?? null
    }

    return null
  },
})

export { currentPortalAccessSelector }
