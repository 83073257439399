import React, { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import dayjs, { type Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import CalendarIcon from '@mui/icons-material/Event'

import { portalSettingState } from 'state/portalSettingStates'
import { DEFAULT_DATE_FORMAT } from '../../commonConstants'
import InputAdornment from '@mui/material/InputAdornment'

type DateInputProps = {
  disabled?: boolean
  label?: string
  defaultValue?: string
  onChange?: (value: Dayjs | null) => void
  allowOnlyFuture?: boolean
  allowOnlyPast?: boolean
  value?: Dayjs | null
  showTime?: boolean
}

const DateInput = React.forwardRef<HTMLInputElement, DateInputProps>(
  (props, ref) => {
    const {
      onChange,
      label,
      disabled = false,
      allowOnlyFuture = false,
      allowOnlyPast = false,
      value,
      showTime = false,
    } = props
    const portalSetting = useRecoilValue(portalSettingState)

    const dateFormat = useMemo((): string => {
      const datetimeFormat = portalSetting
        ? portalSetting.dateFormat
        : DEFAULT_DATE_FORMAT

      return showTime
        ? `${datetimeFormat.split(' ')[0].toUpperCase()} ${
            datetimeFormat.split(' ')[1]
          }`
        : datetimeFormat.split(' ')[0].toUpperCase()
    }, [portalSetting, showTime])

    const Component = showTime ? DateTimePicker : DatePicker

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Component
          ref={ref}
          disabled={disabled}
          onChange={onChange}
          label={label}
          format={dateFormat}
          slotProps={{
            textField: {
              size: 'small',
              inputProps: {
                'data-testid': 'date-picker-input',
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarIcon color="primary" />
                  </InputAdornment>
                ),
              },
            },
          }}
          shouldDisableDate={(date) => {
            if (
              !!props.defaultValue &&
              date.isSame(dayjs(props.defaultValue), 'date')
            ) {
              return false
            }

            return (
              (allowOnlyFuture && date.isBefore(dayjs(new Date()), 'date')) ||
              (allowOnlyPast && date.isAfter(dayjs(new Date()), 'date'))
            )
          }}
          value={value}
          ampm={false}
        />
      </LocalizationProvider>
    )
  },
)

DateInput.displayName = 'DateInput'

export default DateInput
