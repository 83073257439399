import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useForm, Controller, type SubmitHandler } from 'react-hook-form'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import useSWR from 'swr'
import { useSnackbar } from 'notistack'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import LoadingButton from '@mui/lab/LoadingButton'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import WorkIcon from '@mui/icons-material/Work'

import {
  MainHeader,
  SettingWrapper,
  SubHeader,
} from 'components/StyledComponents'
import ImageUploader from 'components/form/ImageUploader'
import {
  hasNewFileState,
  hasUnsavedChangesState,
  isFileUploadingState,
  processedFormFilesState,
} from 'state/formStates'
import useApi from 'hooks/useApi'
import {
  EMAIL_MAX_LENGTH,
  PHONE_MAX_LENGTH,
  SHORT_TEXT_MAX_LENGTH,
  USER_NAME_MAX_LENGTH,
} from '../commonConstants'
import { isValidEmail, isValidPhoneNumber } from 'utils/stringUtils'
import usePortalSetting from 'hooks/usePortalSetting'
import { portalSettingState } from 'state/portalSettingStates'
import { type Member } from 'types'
import { currentPortalAccessSelector } from 'state/memberStates'

const MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD = 300
const MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD = 300

type ProfileFormData = {
  firstName: string
  lastName: string
  phone?: string
  email: string
  avatarUrl?: string
  jobTitle?: string
}

const FORM_NAME = 'profile'

const MyProfile: React.FC = () => {
  const { formatMessage } = useIntl()
  const [isSaving, setIsSaving] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isDirty },
  } = useForm<ProfileFormData>({
    mode: 'onTouched',
  })
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const hasNewFile = useRecoilValue(hasNewFileState)
  const isFileUploading = useRecoilValue(isFileUploadingState(FORM_NAME))
  const processedFormFiles = useRecoilValue(processedFormFilesState(FORM_NAME))
  const { sendPutRequest } = useApi()
  const { formatPhoneNumber } = usePortalSetting()
  const portalSetting = useRecoilValue(portalSettingState)
  const currentPortalAccess = useRecoilValue(currentPortalAccessSelector)
  const {
    data: profileData,
    isLoading,
    mutate: mutateProfile,
  } = useSWR<Member>(
    portalSetting && currentPortalAccess
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/members/${currentPortalAccess.memberData?.id}/profile`
      : null,
  )

  useEffect(() => {
    if (profileData) {
      setValue('firstName', profileData.firstName)
      setValue('lastName', profileData.lastName)
      setValue('email', profileData.email)
      setValue('phone', profileData.phone)
      setValue('jobTitle', profileData.jobTitle)
    }
  }, [profileData])

  const hasUnsavedChanges = useMemo(
    () => hasNewFile || isDirty,
    [hasNewFile, isDirty],
  )

  useEffect(() => {
    setHasUnsavedChanges(hasUnsavedChanges)
  }, [hasUnsavedChanges])

  const shouldDisableSaving = useMemo(
    () =>
      (!isValid ||
        Object.keys(errors).length > 0 ||
        isFileUploading ||
        isSaving) &&
      !hasNewFile,
    [isValid, errors, isFileUploading, isSaving, hasNewFile],
  )

  const onSubmit: SubmitHandler<ProfileFormData> = useCallback(
    async (data): Promise<void> => {
      if (profileData && portalSetting) {
        try {
          setIsSaving(true)
          const formData = {
            ...data,
          }

          if (processedFormFiles.length) {
            formData.avatarUrl = processedFormFiles[0].url
          }

          if (data.phone) {
            formData.phone = formatPhoneNumber(data.phone)
          }

          const memberResponse = await sendPutRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting.id
            }/members/${profileData.id}/profile`,
            formData,
          )

          enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
            variant: 'success',
          })

          const memberData = await memberResponse.json()
          await mutateProfile(memberData)
        } catch (error) {
          console.error(error)
        } finally {
          setIsSaving(false)
          setHasUnsavedChanges(false)
        }
      }
    },
    [profileData, processedFormFiles, portalSetting],
  )

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Stack height="100%" width="100%">
          <Stack direction="row" width="100%" spacing={2} marginBottom={2}>
            <Box flexGrow={1}>
              <MainHeader>
                {formatMessage({ id: 'my_profile.header' })}
              </MainHeader>
            </Box>

            <LoadingButton
              variant="contained"
              size="small"
              type="submit"
              loading={isSaving}
              disabled={shouldDisableSaving}
              color="secondary"
            >
              {formatMessage({ id: 'general.button.save' })}
            </LoadingButton>
          </Stack>

          <SettingWrapper width="100%" flexGrow={1}>
            <Stack paddingY={2} paddingX={4} spacing={2}>
              <SubHeader>
                {formatMessage({
                  id: 'my_profile.subheader.personal_information',
                })}
              </SubHeader>

              <Stack direction="row" spacing={2} width="100%">
                <Box width="220px" height="220px">
                  <ImageUploader
                    formName={FORM_NAME}
                    maxWidth={MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD}
                    maxHeight={MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD}
                    defaultImageUrl={profileData?.avatarUrl}
                  />
                </Box>

                <Stack spacing={2} flexGrow={1}>
                  <FormControl error={!!errors.firstName} fullWidth>
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{
                        required: true,
                        maxLength: USER_NAME_MAX_LENGTH,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.firstName}
                          required
                          size="small"
                          label={formatMessage({
                            id: 'member_edit.label.first_name',
                          })}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                    {errors.firstName?.type === 'maxLength' && (
                      <FormHelperText>
                        {formatMessage(
                          {
                            id: 'general.error.max_length',
                          },
                          { max: USER_NAME_MAX_LENGTH },
                        )}
                      </FormHelperText>
                    )}
                    {errors.firstName?.type === 'required' && (
                      <FormHelperText>
                        {formatMessage({
                          id: 'general.error.required',
                        })}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl error={!!errors.lastName} fullWidth>
                    <Controller
                      name="lastName"
                      control={control}
                      rules={{
                        required: true,
                        maxLength: USER_NAME_MAX_LENGTH,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.lastName}
                          required
                          size="small"
                          label={formatMessage({
                            id: 'member_edit.label.last_name',
                          })}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                    {errors.lastName?.type === 'maxLength' && (
                      <FormHelperText>
                        {formatMessage(
                          {
                            id: 'general.error.max_length',
                          },
                          { max: USER_NAME_MAX_LENGTH },
                        )}
                      </FormHelperText>
                    )}
                    {errors.lastName?.type === 'required' && (
                      <FormHelperText>
                        {formatMessage({
                          id: 'general.error.required',
                        })}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl error={!!errors.email}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        maxLength: EMAIL_MAX_LENGTH,
                        required: true,
                        validate: (val): boolean => {
                          if (!val) {
                            return true
                          }

                          return isValidEmail(val)
                        },
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          disabled
                          size="small"
                          label={formatMessage({
                            id: 'member_edit.label.email',
                          })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon fontSize="small" color="primary" />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />

                    {errors.email?.type === 'validate' && (
                      <FormHelperText>
                        {formatMessage({
                          id: 'general.error.validate_email',
                        })}
                      </FormHelperText>
                    )}
                    {errors.email?.type === 'maxLength' && (
                      <FormHelperText>
                        {formatMessage(
                          {
                            id: 'general.error.max_length_email',
                          },
                          { max: EMAIL_MAX_LENGTH },
                        )}
                      </FormHelperText>
                    )}
                    {errors.email?.type === 'required' && (
                      <FormHelperText>
                        {formatMessage({
                          id: 'general.error.required',
                        })}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl error={!!errors.phone}>
                    <Controller
                      name="phone"
                      control={control}
                      rules={{
                        maxLength: PHONE_MAX_LENGTH,
                        validate: (val): boolean => {
                          if (!val) {
                            return true
                          }

                          return isValidPhoneNumber(val)
                        },
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          label={formatMessage({
                            id: 'member_edit.label.phone',
                          })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PhoneIcon fontSize="small" color="primary" />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />

                    {errors.phone?.type === 'validate' && (
                      <FormHelperText>
                        {formatMessage({
                          id: 'general.error.validate_phone',
                        })}
                      </FormHelperText>
                    )}
                    {errors.phone?.type === 'maxLength' && (
                      <FormHelperText>
                        {formatMessage(
                          {
                            id: 'general.error.max_length_phone',
                          },
                          { max: PHONE_MAX_LENGTH },
                        )}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl error={!!errors.jobTitle}>
                    <Controller
                      name="jobTitle"
                      control={control}
                      rules={{
                        maxLength: SHORT_TEXT_MAX_LENGTH,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          label={formatMessage({
                            id: 'member_edit.label.job_title',
                          })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <WorkIcon fontSize="small" color="primary" />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />

                    {errors.jobTitle?.type === 'maxLength' && (
                      <FormHelperText>
                        {formatMessage(
                          {
                            id: 'general.error.max_length',
                          },
                          { max: SHORT_TEXT_MAX_LENGTH },
                        )}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
          </SettingWrapper>
        </Stack>
      </form>
    </>
  )
}

export default MyProfile
