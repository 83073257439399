import { useCallback, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilValue } from 'recoil'
import { Loader as GMapLoader } from '@googlemaps/js-api-loader'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import LazyLoad from 'react-lazyload'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { debounce } from '@mui/material/utils'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import UpdateIcon from '@mui/icons-material/Update'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import VerifiedIcon from '@mui/icons-material/Verified'

import { casesDataState } from 'state/caseListStates'
import CaseMarker from 'components/case/CaseMarker'
import { type Resource } from 'types'
import { parseComponentToElement } from 'utils/domUtils'
import EmptyCaseListIcon from 'assets/icons/empty_case_map_list.svg'
import {
  InfoText,
  NotificationWrapper,
  SmallInfoText,
  SubHeader,
} from 'components/StyledComponents'
import CaseStatusBadge from 'components/case/CaseStatusBadge'
import ResourceInlineSlider from 'components/resource/ResourceInlineSlider'
import { type CaseStatus, CaseFileSource } from './caseConstants'
import usePortalSetting from 'hooks/usePortalSetting'
import CaseShareabilityIcon from 'components/case/CaseShareabilityIcon'
import CategoryTagList from 'components/category/CategoryTagList'
import { type CaseInfo } from 'components/case/caseTypes'

type CaseListMapProps = {
  region?: string
  language?: string
  zoom?: number
  center?: google.maps.LatLngLiteral
  caseListWidth: number
  isLoading: boolean
  onRefresh: () => void
  onCaseClick?: (data: CaseInfo) => void
}

const CaseWrapper = styled(Stack)<{
  selected: boolean
}>`
  border: ${({ selected, theme }) =>
    `1px solid ${
      selected ? theme.palette.primary.light : theme.palette.divider
    }`};

  box-shadow: ${({ selected }) =>
    selected ? '0px 0px 5px 1px rgb(51, 153, 255)' : 'none'};

  background: ${({ selected, theme }) =>
    selected ? theme.palette.primary.light : theme.palette.background.paper};

  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  cursor: pointer;
  padding-bottom: ${({ theme }) => theme.spacing(1)};

  > .user-card {
    background: ${({ selected }) => (selected ? 'white' : '#f5f6fe')};
  }
`

const EmptyCaseListWrapper = styled(Box)`
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.divider};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  height: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(4)};
`

const EmptyCaseIconWrapper = styled(Stack)`
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(6)};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  background: rgba(245, 246, 254, 1);
`

const DescriptionWrapper = styled(Box)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
`

const REGION = 'FI'
const DEFAULT_ZOOM = 12
const DEFAULT_CENTER = { lat: 60.1695, lng: 24.9354 }
const DEFAULT_LANGUAGE = 'fi'

type FilteredCase = CaseInfo & {
  marker: google.maps.marker.AdvancedMarkerElement
}

const CaseListMap: React.FC<CaseListMapProps> = ({
  region = REGION,
  language = DEFAULT_LANGUAGE,
  zoom = DEFAULT_ZOOM,
  center = DEFAULT_CENTER,
  caseListWidth,
  isLoading,
  onRefresh,
  onCaseClick,
}) => {
  const { formatMessage, locale } = useIntl()
  const caseListRef = useRef<HTMLDivElement>(null)
  const gmapLoaderRef = useRef<GMapLoader | null>(null)
  const mapElementRef = useRef<HTMLElement>(null)
  const mapServiceRef = useRef<google.maps.Map | null>(null)
  const markerClusterRef = useRef<MarkerClusterer | null>(null)
  const casesData = useRecoilValue(casesDataState)
  const [filteredCases, setFilterCases] = useState<FilteredCase[]>([])
  const [isMapLoading, setIsMapLoading] = useState(false)
  const selectedMarkerRef =
    useRef<google.maps.marker.AdvancedMarkerElement | null>(null)
  const [selectedCaseId, setSelectedCaseId] = useState<string | null>(null)
  const { formatDate } = usePortalSetting()

  const loadMarkers = async (cases: CaseInfo[]): Promise<void> => {
    if (mapServiceRef.current && gmapLoaderRef.current) {
      const { AdvancedMarkerElement } =
        await gmapLoaderRef.current.importLibrary('marker')
      const newFilterCases: FilteredCase[] = []

      const results = cases.filter(
        (caseInfo) =>
          caseInfo.location?.position &&
          mapServiceRef.current
            ?.getBounds()
            ?.contains(caseInfo.location?.position),
      )

      const markers = results.map((caseInfo) => {
        const marker = new AdvancedMarkerElement({
          position: caseInfo.location?.position,
          content: parseComponentToElement(
            <CaseMarker status={caseInfo.status} />,
          ),
        })

        marker.addListener('click', () => {
          if (selectedMarkerRef.current) {
            const container = selectedMarkerRef.current.content
              ?.firstChild as HTMLDivElement
            const status = container.getAttribute('status') as CaseStatus
            selectedMarkerRef.current.content = parseComponentToElement(
              <CaseMarker status={status} selected={false} />,
            )
          }

          selectedMarkerRef.current = marker
          marker.content = parseComponentToElement(
            <CaseMarker status={caseInfo.status} selected={true} />,
          )

          if (caseListRef.current) {
            const selectedCase = caseListRef.current.querySelector(
              `#case-${caseInfo.id}`,
            )

            if (selectedCase) {
              selectedCase.scrollIntoView()
            }

            setSelectedCaseId(caseInfo.id)
          }
        })

        newFilterCases.push({
          ...caseInfo,
          marker,
        })

        return marker
      })

      setFilterCases(newFilterCases)

      markerClusterRef.current?.clearMarkers()
      markerClusterRef.current?.addMarkers(markers)

      setIsMapLoading(false)
    }
  }

  const debounceLoadMarkers = debounce(async (cases: CaseInfo[]) => {
    await loadMarkers(cases)
  }, 400)

  const init = async (cases: CaseInfo[]): Promise<void> => {
    if (mapElementRef.current && !gmapLoaderRef.current) {
      gmapLoaderRef.current = new GMapLoader({
        apiKey: process.env.REACT_APP_GMAP_API_KEY ?? '',
        version: 'weekly',
        libraries: ['places'],
        region,
        language,
      })

      setIsMapLoading(true)
      const { Map } = await gmapLoaderRef.current.importLibrary('maps')

      let defaultCenter = center

      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(function (position) {
          defaultCenter = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
        })
      }

      mapServiceRef.current = new Map(mapElementRef.current, {
        center: defaultCenter,
        zoom,
        mapId: process.env.REACT_APP_GMAP_ID,
        mapTypeControl: false,
        fullscreenControl: false,
      })

      mapServiceRef.current.addListener(
        'zoom_changed',
        async (): Promise<void> => {
          await debounceLoadMarkers(cases)
        },
      )

      mapServiceRef.current.addListener('dragend', async (): Promise<void> => {
        await debounceLoadMarkers(cases)
      })

      markerClusterRef.current = new MarkerClusterer({
        markers: [],
        map: mapServiceRef.current,
      })

      mapServiceRef.current.addListener(
        'tilesloaded',
        async (): Promise<void> => {
          await loadMarkers(cases)
        },
      )
    }
  }

  useEffect(() => {
    if (casesData.length) {
      void init(casesData)
    } else {
      setFilterCases([])
      if (markerClusterRef.current) {
        markerClusterRef.current.clearMarkers()
      }
    }
  }, [casesData])

  const handleMouseEnterCase = (filteredCase: FilteredCase): void => {
    if (selectedMarkerRef.current) {
      const container = selectedMarkerRef.current.content
        ?.firstChild as HTMLDivElement
      if (container) {
        const status = container.getAttribute('status') as CaseStatus
        selectedMarkerRef.current.content = parseComponentToElement(
          <CaseMarker status={status} selected={false} />,
        )
      }
    }

    filteredCase.marker.content = parseComponentToElement(
      <CaseMarker status={filteredCase.status} selected={true} />,
    )

    setSelectedCaseId(filteredCase.id)
  }

  const handleMouseLeaveCase = (filteredCase: FilteredCase): void => {
    filteredCase.marker.content = parseComponentToElement(
      <CaseMarker status={filteredCase.status} selected={false} />,
    )

    setSelectedCaseId(null)
  }

  const handleRowClick = useCallback((filteredCase: FilteredCase): void => {
    if (onCaseClick) {
      onCaseClick(filteredCase)
    }
  }, [])

  const reportResources = useCallback(
    (filteredCase: FilteredCase) =>
      filteredCase?.resources.filter(
        (resource: Resource) =>
          resource.source === CaseFileSource.REPORT_UPLOAD,
      ),
    [],
  )

  return (
    <Stack width={'100%'} height={'100%'} direction="row" spacing={2}>
      <Stack
        ref={caseListRef}
        height={'100%'}
        overflow="auto"
        spacing={1}
        width={`${caseListWidth}px`}
        padding={0.5}
      >
        {filteredCases.map((filteredCase) => (
          <CaseWrapper
            key={filteredCase.id}
            id={`case-${filteredCase.id}`}
            width={'100%'}
            onMouseEnter={() => {
              handleMouseEnterCase(filteredCase)
            }}
            onMouseLeave={() => {
              handleMouseLeaveCase(filteredCase)
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                const row = event.target as HTMLTableRowElement
                row.click()
              }
            }}
            spacing={1}
            onClick={() => {
              handleRowClick(filteredCase)
            }}
            selected={selectedCaseId === filteredCase.id}
            tabIndex={0}
          >
            <Stack
              direction="row"
              width={'100%'}
              paddingRight={1}
              paddingLeft={!filteredCase.commentsResolved ? 0 : 1}
              spacing={1}
              alignItems="center"
            >
              {!filteredCase.commentsResolved && (
                <NotificationWrapper>!</NotificationWrapper>
              )}

              <SmallInfoText flexGrow={1}>
                <UpdateIcon sx={{ fontSize: 14 }} />
                {formatDate(filteredCase.updated)}
              </SmallInfoText>

              {filteredCase.shareability && (
                <CaseShareabilityIcon
                  shareability={filteredCase.shareability}
                />
              )}

              <CaseStatusBadge status={filteredCase.status} />
            </Stack>

            <Stack paddingX={1} spacing={1}>
              <LazyLoad
                height={200}
                offset={200}
                overflow
                unmountIfInvisible={true}
              >
                <ResourceInlineSlider
                  resources={reportResources(filteredCase)}
                  width={caseListWidth - 26}
                  height={caseListWidth * 0.6}
                  showZoomInButton={false}
                  imageSize="cover"
                />
              </LazyLoad>

              <DescriptionWrapper>
                {filteredCase.description}
              </DescriptionWrapper>

              <SmallInfoText flexGrow={1}>
                {formatMessage({ id: 'case_list.cases.header.created_at' })}
                {': '}
                {formatDate(filteredCase.created)}
              </SmallInfoText>

              <Divider />

              <Stack justifyItems="center">
                <InfoText flexGrow={1}>
                  <LocationOnIcon sx={{ fontSize: 16 }} />
                  {filteredCase.location?.address}
                </InfoText>
                {filteredCase.item?.name && (
                  <InfoText flexGrow={1}>
                    <VerifiedIcon sx={{ fontSize: 16 }} color="success" />
                    {filteredCase.item.name.localisedValues[locale]}
                  </InfoText>
                )}

                <Box overflow="hidden">
                  <CategoryTagList category={filteredCase.category} />
                </Box>
              </Stack>
            </Stack>
          </CaseWrapper>
        ))}

        {filteredCases.length === 0 && (
          <EmptyCaseListWrapper>
            {isLoading && <CircularProgress />}

            {!isLoading && !isMapLoading && (
              <Stack spacing={2}>
                <EmptyCaseIconWrapper>
                  <EmptyCaseListIcon />
                </EmptyCaseIconWrapper>
                <SubHeader>
                  {formatMessage({ id: 'case_list_map.label.case_empty' })}
                </SubHeader>

                <div
                  dangerouslySetInnerHTML={{
                    __html: formatMessage({
                      id: 'case_list.text.no_map_result',
                    }),
                  }}
                ></div>
                <Box>
                  <Button size="small" onClick={onRefresh} variant="outlined">
                    {formatMessage({ id: 'general.button.refresh' })}
                  </Button>
                </Box>
              </Stack>
            )}
          </EmptyCaseListWrapper>
        )}
      </Stack>

      <Box
        ref={mapElementRef}
        flexGrow={1}
        height="100%"
        borderRadius={1}
      ></Box>
    </Stack>
  )
}

export default CaseListMap
