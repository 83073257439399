import { Loader as GMapLoader } from '@googlemaps/js-api-loader'

type Options = {
  region?: string
  language?: string
}

let gmapLoader: GMapLoader | null = null

export const getGoogleMapsLoader = (options: Options): GMapLoader => {
  if (!gmapLoader) {
    gmapLoader = new GMapLoader({
      apiKey: process.env.REACT_APP_GMAP_API_KEY ?? '',
      version: 'weekly',
      libraries: ['places'],
      region: options.region,
      language: options.language,
    })
  } else if (options) {
    if (options.region) {
      gmapLoader.options.region = options.region
    }

    if (options.language) {
      gmapLoader.options.language = options.language
    }
  }

  return gmapLoader
}
