import { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import SubCategorySelectorTree from './SubCategorySelectorTree'
import { type CategoryData } from 'components/category/categoryTypes'
import { TreeView } from 'components/category/CategoryStyledComponents'

type CategorySelectorTreeProps = {
  categories: CategoryData[]
  selectedCategoryIds?: string
  isLoading?: boolean
  onCategoryChange: (categoryName: string) => void
}

const CategorySelectorTree: React.FC<CategorySelectorTreeProps> = ({
  selectedCategoryIds,
  categories,
  isLoading = false,
  onCategoryChange,
}) => {
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null)

  useEffect((): void => {
    if (selectedCategoryIds) {
      setSelectedItemId(selectedCategoryIds)
    }
  }, [selectedCategoryIds])

  const handleCategoryChange = (ids: string): void => {
    setSelectedItemId(ids)
    onCategoryChange(ids)
  }

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <TreeView defaultExpanded={selectedCategoryIds?.split('|') || []}>
      <SubCategorySelectorTree
        paths={[]}
        categories={categories}
        onCategoryChange={handleCategoryChange}
        selectedItemId={selectedItemId}
      />
    </TreeView>
  )
}

export default CategorySelectorTree
