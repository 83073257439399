import {
  type ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useForm, type SubmitHandler, Controller } from 'react-hook-form'
import { useRecoilValue } from 'recoil'
import dayjs, { type Dayjs } from 'dayjs'
import styled from '@mui/material/styles/styled'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import MuiTab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import MuiTabList from '@mui/lab/TabList'
import MuiTabPanel from '@mui/lab/TabPanel'
import Badge from '@mui/material/Badge'
import MenuList from '@mui/material/MenuList'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import LoadingButton from '@mui/lab/LoadingButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { portalSettingState } from 'state/portalSettingStates'
import {
  TASK_DESCRIPTION_MAX_LENGTH,
  TaskEditingMode,
} from '../../commonConstants'
import MultiLineInput from 'components/form/MultiLineInput'
import {
  type CaseTask,
  type TaskAssignmentCandidates,
} from 'components/task/taskTypes'
import DateInput from 'components/form/DateInput'
import { SubHeader, SubSubHeader } from 'components/StyledComponents'
import useApi from 'hooks/useApi'
import { nameInitials } from 'utils/stringUtils'
import { TaskAssigneeType } from 'components/task/taskConstants'
import { getThumbnailUrl } from 'utils/fileUtils'

const Tab = styled(MuiTab)`
  padding: 5px 12px;
  min-height: 0;
  color: inherit;
  font-weight: 300;
  font-size: 14px;

  .counter {
    padding: 2px 6px;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    background: ${({ theme }) => theme.palette.primary.light};
    font-size: 0.8rem;
    color: ${({ theme }) => theme.palette.text.primary};
  }

  &.Mui-selected {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background: ${({ theme }) => theme.palette.primary.main};
    border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
    font-weight: 500;

    .counter {
      background: ${({ theme }) => theme.palette.primary.light};
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }
`

const TabList = styled(MuiTabList)`
  min-height: 0px;

  & .MuiTabs-indicator {
    display: none;
  }
`

const TabPanel = styled(MuiTabPanel)`
  padding: 0px;
  margin: 0;
  margin-top: 0px;
`

const TeamMenuList = styled(MenuList)`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .MuiMenuItem-root {
    margin: 0px 4px;
    border: 1px solid #e9ecfc;
    border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  }

  .Mui-selected {
    background: white;
    box-shadow: 0px 10px 30px 0px #1126920d;
    box-shadow: 0px 0px 5px 1px #07208d;
  }
`

const MemberListSwitch = styled(RadioGroup)`
  display: flex;
  margin-left: 30px;
`

const MemberControlLabel = styled(FormControlLabel)`
  display: flex;

  & .MuiFormControlLabel-label {
    flex-grow: 1;
  }
`

type TaskEditFormProps = {
  onClose: () => void
  onSave: (data: TaskCreationFormData) => Promise<void>
  selectedTask?: CaseTask | null
  taskEditingMode: TaskEditingMode | null
  isSaving: boolean
}

export type TaskCreationFormData = {
  description: string
  assigneeMemberId?: string
  assigneeTeamId?: string
  assigneeType: TaskAssigneeType
  deadline?: Dayjs | null
}

const TaskEditForm: React.FC<TaskEditFormProps> = ({
  onClose,
  onSave,
  selectedTask,
  taskEditingMode,
  isSaving,
}) => {
  const { formatMessage } = useIntl()
  const portalSetting = useRecoilValue(portalSettingState)
  const { caseId } = useParams()
  const { sendPostRequest } = useApi()
  const [taskAssignmentCandidates, setTaskAssignmentCandidates] =
    useState<TaskAssignmentCandidates | null>()
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    watch,
  } = useForm<TaskCreationFormData>({
    mode: 'onTouched',
    defaultValues: {
      assigneeType: TaskAssigneeType.MEMBER,
    },
  })
  const [userTypedMemberName, setUserTypedMemberName] = useState<
    string | undefined
  >()
  const [isAssigneeTeamMemberOpen, setIsAssigneeTeamMemberOpen] = useState<
    string[]
  >([])

  const assigneeType = watch('assigneeType')
  const assigneeTeamId = watch('assigneeTeamId')
  const assigneeMemberId = watch('assigneeMemberId')

  const getTaskAssignmentCandidates = useCallback(async (): Promise<void> => {
    if (portalSetting?.id) {
      const response = await sendPostRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/cases/${caseId}/tasks:listAssignmentCandidates`,
      )
      const data = await response.json()

      setTaskAssignmentCandidates(data)
    }
  }, [portalSetting, caseId])

  useEffect(() => {
    if (taskEditingMode !== TaskEditingMode.EDITING && portalSetting) {
      void getTaskAssignmentCandidates()
    }
  }, [taskEditingMode, portalSetting])

  useEffect(() => {
    setValue('description', selectedTask?.description ?? '')

    if (selectedTask?.deadline) {
      setValue('deadline', dayjs(selectedTask.deadline))
    }
  }, [selectedTask])

  const onSubmit: SubmitHandler<TaskCreationFormData> = async (
    data,
  ): Promise<void> => {
    void onSave(data)
  }

  const descriptionErrorMessage = useMemo((): string | undefined => {
    if (errors.description?.type === 'maxLength') {
      return formatMessage(
        {
          id: 'general.error.max_length',
        },
        {
          max: TASK_DESCRIPTION_MAX_LENGTH,
        },
      )
    }

    if (errors.description?.type === 'required') {
      return formatMessage({
        id: 'general.error.required',
      })
    }

    return undefined
  }, [errors.description])

  const shouldDisableSaving = useMemo(
    () => !isValid || Object.keys(errors).length > 0,
    [isValid, errors],
  )

  const handleAssigneeTypeChange = (
    event: React.SyntheticEvent,
    newValue: string,
  ): void => {
    setValue('assigneeType', newValue as TaskAssigneeType)
    setValue('assigneeMemberId', undefined)
    setValue('assigneeTeamId', undefined)
    setUserTypedMemberName(undefined)
  }

  const renderTabLabel = ({
    labelKey,
    counter,
  }: {
    labelKey: string
    counter?: number
  }): ReactElement => (
    <Stack direction="row" spacing={1} alignItems="center">
      <Box>
        {formatMessage({
          id: labelKey,
        })}
      </Box>
      {!!counter && (
        <Badge variant="dot">
          <Box className="counter">{counter}</Box>
        </Badge>
      )}
    </Stack>
  )

  const toggleTeamMembers = (teamId: string): void => {
    if (isAssigneeTeamMemberOpen.includes(teamId)) {
      setIsAssigneeTeamMemberOpen(
        isAssigneeTeamMemberOpen.filter((id) => id !== teamId),
      )
    } else {
      setIsAssigneeTeamMemberOpen([...isAssigneeTeamMemberOpen, teamId])
    }
  }

  const assigneeMemberName = useMemo((): string | null => {
    if (taskAssignmentCandidates?.memberCandidates) {
      const member = taskAssignmentCandidates.memberCandidates.find(
        (member) => member.memberId === assigneeMemberId,
      )

      return member?.name ?? null
    }

    return null
  }, [assigneeMemberId, taskAssignmentCandidates])

  const teamCandidates = useMemo(
    () =>
      taskAssignmentCandidates?.teamCandidates.filter(
        (team) => team.name !== 'AUTO_DISPATCH',
      ),
    [taskAssignmentCandidates?.teamCandidates],
  )

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Stack spacing={2} height={'100%'}>
        <Stack direction="row" width="100%">
          <Box flexGrow={1}>
            <SubHeader>
              {formatMessage({
                id:
                  selectedTask && taskEditingMode === TaskEditingMode.EDITING
                    ? 'action_edit_form.header.edit_action'
                    : 'action_edit_form.header',
              })}
            </SubHeader>
          </Box>

          <Button onClick={onClose} size="small" variant="outlined">
            {formatMessage({ id: 'general.button.cancel' })}
          </Button>
        </Stack>

        <Divider />

        <Stack flexGrow={1} spacing={2} overflow="auto" paddingTop={1}>
          {taskEditingMode !== TaskEditingMode.ASSIGNING && (
            <FormControl error={!!errors?.description}>
              <Controller
                name="description"
                control={control}
                rules={{
                  required: true,
                  maxLength: TASK_DESCRIPTION_MAX_LENGTH,
                }}
                defaultValue={''}
                render={({ field }) => (
                  <MultiLineInput
                    {...field}
                    required
                    autoFocus
                    error={!!errors?.description}
                    label={formatMessage({
                      id: 'action_edit_form.label.description',
                    })}
                    maxLength={TASK_DESCRIPTION_MAX_LENGTH}
                    rows={3}
                    variant="outlined"
                    fullWidth
                    helpMessage={descriptionErrorMessage}
                  />
                )}
              />
            </FormControl>
          )}

          {taskEditingMode !== TaskEditingMode.EDITING && (
            <Stack spacing={1}>
              <SubSubHeader>
                {formatMessage({
                  id: 'action_edit_form.label.assign_to',
                })}
              </SubSubHeader>

              <TabContext value={assigneeType}>
                <TabList onChange={handleAssigneeTypeChange}>
                  <Tab
                    label={renderTabLabel({
                      labelKey: 'task_edit_form.label.members',
                      counter:
                        taskAssignmentCandidates?.memberCandidates?.length,
                    })}
                    value={TaskAssigneeType.MEMBER}
                    tabIndex={0}
                  />
                  <Tab
                    label={renderTabLabel({
                      labelKey: 'task_edit_form.label.teams',
                      counter: teamCandidates?.length,
                    })}
                    value={TaskAssigneeType.TEAM}
                    tabIndex={0}
                  />
                </TabList>
                <TabPanel value={TaskAssigneeType.MEMBER}>
                  <FormControl fullWidth>
                    <Controller
                      name="assigneeMemberId"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          freeSolo
                          options={
                            taskAssignmentCandidates?.memberCandidates ?? []
                          }
                          getOptionLabel={(option) => {
                            if (typeof option === 'string') {
                              return option
                            }

                            return option.name || ''
                          }}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <Avatar
                                  alt={option.name}
                                  sx={{ width: 30, height: 30, fontSize: 16 }}
                                  src={option.avatarUrl ?? ''}
                                >
                                  {nameInitials(option.name)}
                                </Avatar>

                                <Box>{option.name}</Box>
                              </Stack>
                            </li>
                          )}
                          onChange={(event, newValue): void => {
                            if (typeof newValue === 'object' && newValue) {
                              setValue('assigneeMemberId', newValue.memberId)
                            }
                          }}
                          inputValue={userTypedMemberName}
                          onInputChange={(event, newInputValue) => {
                            setUserTypedMemberName(newInputValue)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                        />
                      )}
                    />
                  </FormControl>
                </TabPanel>
                <TabPanel value={TaskAssigneeType.TEAM}>
                  <TeamMenuList>
                    {teamCandidates?.map((team) => (
                      <MenuItem
                        key={team.teamId}
                        value={team.teamId}
                        selected={team.teamId === assigneeTeamId}
                        onClick={() => {
                          setValue('assigneeTeamId', team.teamId)
                          setValue('assigneeMemberId', undefined)
                        }}
                      >
                        <Stack width="100%">
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Avatar
                              alt={team.name}
                              sx={{
                                width: 30,
                                height: 30,
                                fontSize: 16,
                              }}
                              src={team.logoUrl ?? ''}
                            >
                              {nameInitials(team.name)}
                            </Avatar>

                            <Stack>
                              <Typography variant="body1">
                                {team.name}
                              </Typography>
                              <Button
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  toggleTeamMembers(team.teamId)
                                }}
                                endIcon={
                                  isAssigneeTeamMemberOpen.includes(
                                    team.teamId,
                                  ) ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )
                                }
                              >
                                {assigneeTeamId === team.teamId &&
                                assigneeMemberName
                                  ? assigneeMemberName
                                  : formatMessage({
                                      id: 'task_edit_form.option.no_assignee',
                                    })}
                              </Button>
                            </Stack>
                          </Stack>

                          {isAssigneeTeamMemberOpen.includes(team.teamId) && (
                            <MemberListSwitch
                              onChange={(e) => {
                                e.stopPropagation()
                                setValue('assigneeMemberId', e.target.value)
                                setValue('assigneeTeamId', team.teamId)
                              }}
                            >
                              <MemberControlLabel
                                value=""
                                control={
                                  <Radio
                                    checked={
                                      assigneeMemberId === '' &&
                                      assigneeTeamId === team.teamId
                                    }
                                  />
                                }
                                labelPlacement="start"
                                label={
                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    flexGrow={1}
                                  >
                                    <Avatar
                                      alt={formatMessage({
                                        id: 'task_edit_form.option.no_assignee',
                                      })}
                                      sx={{
                                        width: 20,
                                        height: 20,
                                        fontSize: 14,
                                      }}
                                    />

                                    <Typography variant="body2">
                                      {formatMessage({
                                        id: 'task_edit_form.option.no_assignee',
                                      })}
                                    </Typography>
                                  </Stack>
                                }
                              />

                              {team.memberCandidates.map((member) => (
                                <MemberControlLabel
                                  key={member.memberId}
                                  value={member.memberId}
                                  control={
                                    <Radio
                                      checked={
                                        member.memberId === assigneeMemberId &&
                                        assigneeTeamId === team.teamId
                                      }
                                    />
                                  }
                                  labelPlacement="start"
                                  label={
                                    <Stack
                                      key={member.memberId}
                                      direction="row"
                                      spacing={1}
                                      alignItems="center"
                                      flexGrow={1}
                                    >
                                      <Avatar
                                        alt={member.name}
                                        sx={{
                                          width: 20,
                                          height: 20,
                                          fontSize: 14,
                                        }}
                                        src={getThumbnailUrl(member.avatarUrl)}
                                      >
                                        {nameInitials(member.name)}
                                      </Avatar>

                                      <Typography variant="body2">
                                        {member.name}
                                      </Typography>
                                    </Stack>
                                  }
                                />
                              ))}
                            </MemberListSwitch>
                          )}
                        </Stack>
                      </MenuItem>
                    ))}
                  </TeamMenuList>
                </TabPanel>
              </TabContext>
            </Stack>
          )}

          {taskEditingMode !== TaskEditingMode.ASSIGNING && (
            <FormControl>
              <Controller
                name="deadline"
                control={control}
                render={({ field }) => (
                  <DateInput
                    {...field}
                    defaultValue={selectedTask?.deadline ?? ''}
                    label={formatMessage({
                      id: 'action_edit_form.label.set_deadline',
                    })}
                    allowOnlyFuture={true}
                  />
                )}
              />
            </FormControl>
          )}
        </Stack>

        <LoadingButton
          type="submit"
          size="small"
          variant="contained"
          disabled={shouldDisableSaving}
          loading={isSaving}
        >
          {formatMessage({ id: 'general.button.save' })}
        </LoadingButton>
      </Stack>
    </form>
  )
}

export default TaskEditForm
