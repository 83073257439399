import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSetRecoilState } from 'recoil'
import { useForm, Controller } from 'react-hook-form'
import dayjs from 'dayjs'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import CloseIcon from '@mui/icons-material/Close'

import DateInput from 'components/form/DateInput'
import { hasUnsavedChangesState } from 'state/formStates'
import {
  type Alert,
  type AlertExpiryFormData,
} from 'components/alert/alertTypes'

type AlertEditExpiryDialogProps = {
  alert: Alert | null
  isOpen: boolean
  isSaving: boolean
  onSave: (data: AlertExpiryFormData) => void
  onClose: () => void
}

const AlertEditExpiryDialog: React.FC<AlertEditExpiryDialogProps> = ({
  alert,
  isOpen,
  isSaving,
  onSave,
  onClose,
}) => {
  const { formatMessage } = useIntl()

  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const [isExpiryDisabled, setIsExpiryDisabled] = useState(true)
  const {
    control,
    getValues,
    setValue,
    formState: { errors, isValid },
    reset,
  } = useForm<AlertExpiryFormData>({
    mode: 'onTouched',
  })

  useEffect(() => {
    if (isOpen) {
      reset()
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && alert?.expiry) {
      setValue('expiry', dayjs(alert.expiry))
      setIsExpiryDisabled(false)
    }
  }, [alert, isOpen])

  const handleSave = useCallback((): void => {
    if (alert) {
      const formData = getValues()
      setHasUnsavedChanges(false)
      onSave({
        ...formData,
        id: alert.id,
      })
    }
  }, [alert])

  const handleClose = (reason: 'backdropClick' | 'escapeKeyDown'): void => {
    if (reason !== 'backdropClick') {
      onClose()
    }
  }

  const handleToggleExpirationDate = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setValue('expiry', null)
    setIsExpiryDisabled(!event.target.checked)
  }

  const disableSubmit = useMemo(
    () => !isValid || Object.keys(errors).length > 0 || isSaving,
    [isValid, errors, isSaving],
  )

  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        handleClose(reason)
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {formatMessage({
          id: 'alert_edit.header',
        })}
      </DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
        aria-label={formatMessage({
          id: 'general.button.close',
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack minWidth="500px" spacing={1}>
          <FormControlLabel
            value="start"
            control={
              <Switch
                checked={!isExpiryDisabled}
                onChange={handleToggleExpirationDate}
                color="primary"
              />
            }
            label={formatMessage({
              id: 'alert_edit.label.expiration_date_switch',
            })}
            labelPlacement="start"
          />

          <FormControl error={!!errors?.expiry}>
            <Controller
              name="expiry"
              control={control}
              rules={{
                validate: (val): boolean | string => {
                  if (!val) {
                    return true
                  }

                  if (val.toDate() < new Date()) {
                    return 'validate'
                  }

                  return true
                },
              }}
              render={({ field }) => (
                <DateInput
                  {...field}
                  disabled={isExpiryDisabled}
                  label={formatMessage({
                    id: 'alert_edit.label.expiration_date',
                  })}
                  allowOnlyFuture={true}
                  showTime={true}
                />
              )}
            />
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack width="100%" spacing={1}>
          <Button
            fullWidth
            onClick={handleSave}
            variant="contained"
            disabled={disableSubmit}
          >
            {formatMessage({ id: 'general.button.save' })}
          </Button>
          <Button
            fullWidth
            onClick={onClose}
            variant="outlined"
            disabled={disableSubmit}
          >
            {formatMessage({ id: 'general.button.cancel' })}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default AlertEditExpiryDialog
