import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import { useRecoilValue } from 'recoil'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'

import {
  ContentWrapper,
  MainHeader,
  SubContentWrapper,
  SubHeader,
  SubSubHeader,
} from 'components/StyledComponents'
import EmptyItemIcon from 'assets/icons/empty_item.svg'
import useRoute from 'hooks/useNavigate'
import { Path } from '../commonConstants'
import { portalSettingState } from 'state/portalSettingStates'
import LogoWrapper from 'components/LogoWrapper'
import usePortalSetting from 'hooks/usePortalSetting'
import CommunityIcon from 'assets/icons/community.svg'
import CommunityPrivateIcon from 'assets/icons/community_private.svg'
import OpenModeIcon from 'assets/icons/open_mode.svg'
import OpenModePrivateIcon from 'assets/icons/open_mode_private.svg'
import {
  ItemContactMethodType,
  ItemPrivacyMode,
} from 'components/item/itemConstants'
import { type LocalizedContact, type Item } from 'components/item/itemTypes'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import useMember from 'hooks/useMember'

const ItemWrapper = styled(Stack)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.divider};
  position: relative;
`

const PrivacyIconWrapper = styled(Stack)`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  padding: 6px 6px;
  background: ${({ theme }) => theme.palette.primary.main};
  border-top-left-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border-bottom-right-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  align-items: center;
`

const ItemListPage: React.FC = () => {
  const { formatMessage, locale } = useIntl()
  const portalSetting = useRecoilValue(portalSettingState)
  const { getLocalizedContent } = usePortalSetting()
  const { data: itemsData, isLoading } = useSWR<Item[]>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/items`
      : null,
  )
  const { goTo } = useRoute()
  const { checkAccesses } = useMember()

  const handleClick = (itemId: string): void => {
    goTo(`${Path.ITEMS_EDIT}/${itemId}`)
  }

  const getItemContact = useCallback(
    (item: Item): LocalizedContact | null => {
      const defaultLanguageContact = item.contacts.find(
        (contact) => contact.language === locale,
      )

      return defaultLanguageContact ?? null
    },
    [locale],
  )

  const sortedItems = useMemo(
    () =>
      itemsData?.sort((a, b) => {
        const nameA = getLocalizedContent(a.names).toUpperCase()
        const nameB = getLocalizedContent(b.names).toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }

        return 0
      }),
    [itemsData],
  )

  return (
    <Stack height="100%" width="100%" spacing={2} overflow="auto">
      <Stack direction="row" width="100%" spacing={2}>
        <Box flexGrow={1}>
          <MainHeader>
            {formatMessage({ id: 'portal_item_list.header' })}
          </MainHeader>
        </Box>

        {sortedItems &&
          sortedItems?.length > 0 &&
          checkAccesses({ [PortalSection.ITEMS]: [FeatureAccess.WRITE] }) && (
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => {
                goTo(Path.ITEMS_ADD)
              }}
              color="secondary"
            >
              {formatMessage({ id: 'portal_item_list.button.create_item' })}
            </Button>
          )}
      </Stack>

      <Stack flexGrow={1} overflow="auto" spacing={2}>
        {!sortedItems ||
          (sortedItems.length === 0 && (
            <ItemWrapper
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              {isLoading && <CircularProgress />}

              {!isLoading &&
                checkAccesses({
                  [PortalSection.ITEMS]: [FeatureAccess.WRITE],
                }) && (
                  <Stack textAlign="center" spacing={2} alignItems="center">
                    <EmptyItemIcon />
                    <Box width="100%">
                      <SubHeader>
                        {formatMessage({
                          id: 'portal_item_list.label.start_creating_item',
                        })}
                      </SubHeader>
                    </Box>
                    <Box width="100%">
                      <Button
                        variant="contained"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => {
                          goTo(Path.ITEMS_ADD)
                        }}
                        color="secondary"
                      >
                        {formatMessage({
                          id: 'portal_item_list.button.create_item',
                        })}
                      </Button>
                    </Box>
                  </Stack>
                )}
            </ItemWrapper>
          ))}

        {sortedItems?.map((item) => (
          <ItemWrapper
            key={item.id}
            direction="row"
            spacing={2}
            paddingX={4}
            paddingY={2}
            alignItems="center"
          >
            <PrivacyIconWrapper>
              {item.privacyMode ===
                ItemPrivacyMode.COMMUNITY_MODE_PRIVATE_REPORTING && (
                <CommunityPrivateIcon />
              )}
              {item.privacyMode === ItemPrivacyMode.COMMUNITY_MODE && (
                <CommunityIcon />
              )}
              {item.privacyMode ===
                ItemPrivacyMode.OPEN_MODE_PRIVATE_REPORTING && (
                <OpenModePrivateIcon />
              )}
              {item.privacyMode === ItemPrivacyMode.OPEN_MODE && (
                <OpenModeIcon />
              )}
            </PrivacyIconWrapper>
            <LogoWrapper
              size={120}
              url={item.logoUrl}
              alt={getLocalizedContent(item.names)}
            >
              {!item.logoUrl && (
                <LightbulbIcon fontSize="small" sx={{ fontSize: 80 }} />
              )}
            </LogoWrapper>

            <Stack justifyContent="center" spacing={1} width="300px">
              <SubHeader>{getLocalizedContent(item.names)}</SubHeader>
              <SubSubHeader>{item.website}</SubSubHeader>

              {checkAccesses({
                [PortalSection.ITEMS]: [FeatureAccess.WRITE],
              }) && (
                <Box>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      handleClick(item.id)
                    }}
                  >
                    {formatMessage({
                      id: 'portal_item_list.button.settings',
                    })}
                  </Button>
                </Box>
              )}
            </Stack>

            <Box flexGrow={1}>
              {(item.locationDetails ||
                item.contacts.length ||
                item.address) && (
                <Stack spacing={1}>
                  {(item.address ||
                    item.locationDetails?.location?.address) && (
                    <SubContentWrapper spacing={1} flexGrow={1} direction="row">
                      <LocationOnIcon fontSize="small" />
                      <Typography variant="body2">
                        <address>
                          {item.address ||
                            item.locationDetails?.location?.address}
                        </address>
                      </Typography>
                    </SubContentWrapper>
                  )}

                  {getItemContact(item) && (
                    <SubContentWrapper
                      width="100%"
                      direction="row"
                      spacing={1}
                      flexGrow={1}
                      key={getItemContact(item)?.name}
                    >
                      <AccountBoxIcon fontSize="small" />
                      <Stack spacing={1} width="100%">
                        <Stack>
                          <Box paddingX={1}>
                            <Typography variant="body2">
                              {getItemContact(item)?.name}
                            </Typography>
                          </Box>

                          {getItemContact(item)?.contacts.map((method) => (
                            <ContentWrapper
                              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                              key={`${item.id}-${method.type}-${method.contact}`}
                              width="100%"
                              direction="row"
                              spacing={1}
                              padding={1}
                            >
                              {method.type === ItemContactMethodType.PHONE && (
                                <PhoneIcon fontSize="small" color="primary" />
                              )}

                              {method.type === ItemContactMethodType.EMAIL && (
                                <EmailIcon fontSize="small" color="primary" />
                              )}

                              <Box>{method.contact}</Box>
                            </ContentWrapper>
                          ))}
                        </Stack>
                      </Stack>
                    </SubContentWrapper>
                  )}
                </Stack>
              )}
            </Box>
          </ItemWrapper>
        ))}
      </Stack>
    </Stack>
  )
}

export default ItemListPage
