import React, {
  type FocusEventHandler,
  type ChangeEventHandler,
  type MouseEvent,
} from 'react'

type FileInputProps = {
  id?: string
  name?: string
  required?: boolean
  isDisabled?: boolean
  isHidden?: boolean
  multiple?: boolean
  accept: string
  onBlur?: FocusEventHandler
  onFocus?: FocusEventHandler
  onChange?: ChangeEventHandler
}

const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>(
  (props, ref) => {
    const {
      id,
      required = false,
      isDisabled = false,
      accept,
      name,
      onBlur,
      onFocus,
      onChange,
      multiple = true,
    } = props

    const handleClick = (event: MouseEvent<HTMLInputElement>): void => {
      ;(event.target as HTMLInputElement).value = ''
    }

    return (
      <input
        ref={ref}
        id={id}
        name={name}
        type={'file'}
        multiple={multiple}
        required={required}
        disabled={isDisabled}
        accept={accept}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        onClick={handleClick}
        style={{
          display: 'none',
        }}
      />
    )
  },
)

FileInput.displayName = 'FileInput'

export default FileInput
