import { type CategoryRawData } from 'components/category/categoryTypes'
import CategoryListItem from './CategoryListItem'
import { CATEGORY_MAX_DEPTH } from 'commonConstants'

type SubCategoryListTreeProps = {
  paths: string[]
  categories: CategoryRawData[]
}

const SubCategoryListTree: React.FC<SubCategoryListTreeProps> = ({
  paths,
  categories,
}) => {
  if (paths.length >= CATEGORY_MAX_DEPTH) {
    return null
  }

  return categories?.map((category, index) => (
    <CategoryListItem
      key={category.id}
      paths={[...paths, category.id]}
      category={category}
    />
  ))
}

export default SubCategoryListTree
