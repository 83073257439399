import { type ReactElement } from 'react'
import ReactDOMServer from 'react-dom/server'
import ReactDOM from 'react-dom/client'

const parseSvgToElement = (component: ReactElement): HTMLElement => {
  const svgHtml = ReactDOMServer.renderToStaticMarkup(component)
  const parser = new DOMParser()

  return parser.parseFromString(svgHtml, 'image/svg+xml').documentElement
}

const parseComponentToElement = (component: ReactElement): HTMLElement => {
  const container = document.createElement('div')
  const root = ReactDOM.createRoot(container)
  root.render(component)

  return container
}

export { parseSvgToElement, parseComponentToElement }
