import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Controller, useForm, type SubmitHandler } from 'react-hook-form'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import styled from '@mui/material/styles/styled'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import {
  type AUTH_ERROR,
  AUTH_ERROR_MESSAGE,
  EMAIL_MAX_LENGTH,
  Path,
} from '../commonConstants'
import useRoute from 'hooks/useNavigate'
import {
  BackButtonGrey,
  LightSubHeader,
  MainHeader,
} from 'components/StyledComponents'
import EmailSentIcon from 'assets/icons/email_sent.svg'

type FormData = {
  email: string
}

type ForgotPasswordError = {
  code: AUTH_ERROR
}

const Wrapper = styled(Stack)`
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => 4 * theme.shape.borderRadius}px;
  width: 560px;
  max-width: 100vw;
`

const ForgotPasswordPage: React.FC = () => {
  const { formatMessage, locale } = useIntl()
  const { goTo } = useRoute()
  const [isSending, setIsSending] = useState(false)
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm<FormData>({
    mode: 'onTouched',
  })
  const [errorCode, setErrorCode] = useState<AUTH_ERROR | null>()
  const [hasSent, setHasSent] = useState(false)

  const shouldDisableSend = useMemo(
    () => !isValid || Object.keys(errors).length > 0 || isSending,
    [isValid, errors, isSending],
  )

  const onSubmit: SubmitHandler<FormData> = useCallback(
    async (data): Promise<void> => {
      try {
        setIsSending(true)
        const auth = getAuth()
        auth.languageCode = locale.toLowerCase()

        await sendPasswordResetEmail(auth, data.email)
        setHasSent(true)
      } catch (error: unknown) {
        const forgotPasswordError = error as ForgotPasswordError
        setErrorCode(forgotPasswordError.code)
      } finally {
        setIsSending(false)
      }
    },
    [locale],
  )

  const handleGoBack = (): void => {
    goTo(Path.LOGIN)
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper padding={7} spacing={2}>
        <Stack alignItems="start">
          <BackButtonGrey
            onClick={handleGoBack}
            size="small"
            aria-label={formatMessage({
              id: 'general.icon_button.go_back',
            })}
          >
            <ArrowBackIcon />
          </BackButtonGrey>
        </Stack>

        {hasSent && (
          <Stack alignItems="center" spacing={2}>
            <EmailSentIcon />
            <MainHeader>
              {formatMessage({ id: 'forgot_password.header.email_sent' })}
            </MainHeader>
            <LightSubHeader>
              {formatMessage(
                { id: 'forgot_password.text.email_sent' },
                {
                  email: getValues('email'),
                },
              )}
            </LightSubHeader>
            <Button
              variant="outlined"
              size="large"
              onClick={handleGoBack}
              fullWidth
            >
              {formatMessage({ id: 'general.button.close' })}
            </Button>
          </Stack>
        )}

        {!hasSent && (
          <>
            {errorCode && (
              <Alert severity="error">
                <AlertTitle>
                  {formatMessage({ id: AUTH_ERROR_MESSAGE[errorCode] })}
                </AlertTitle>
              </Alert>
            )}

            <Stack alignItems="center" spacing={2}>
              <MainHeader>
                {formatMessage({ id: 'forgot_password.header' })}
              </MainHeader>
              <LightSubHeader>
                {formatMessage(
                  { id: 'forgot_password.subheader' },
                  {
                    email: getValues('email'),
                  },
                )}
              </LightSubHeader>
            </Stack>

            <FormControl error={!!errors.email}>
              <Controller
                name="email"
                control={control}
                rules={{
                  maxLength: EMAIL_MAX_LENGTH,
                  required: true,
                }}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    autoFocus
                    label={formatMessage({
                      id: 'login.label.email',
                    })}
                    variant="outlined"
                    fullWidth
                  />
                )}
              />

              {errors.email?.type === 'validate' && (
                <FormHelperText>
                  {formatMessage({
                    id: 'general.error.validate_email',
                  })}
                </FormHelperText>
              )}
              {errors.email?.type === 'maxLength' && (
                <FormHelperText>
                  {formatMessage(
                    {
                      id: 'general.error.max_length_email',
                    },
                    { max: EMAIL_MAX_LENGTH },
                  )}
                </FormHelperText>
              )}
            </FormControl>

            <LoadingButton
              variant="contained"
              type="submit"
              disabled={shouldDisableSend}
              loading={isSending}
            >
              {formatMessage({
                id: 'forgot_password.button.send_reset_email',
              })}
            </LoadingButton>
          </>
        )}
      </Wrapper>
    </form>
  )
}

export default ForgotPasswordPage
