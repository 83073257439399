import { atom } from 'recoil'
import {
  CASE_PERIODS_DEFAULT,
  CASE_SHAREABILITIES_DEFAULT,
  CASE_STATUSES_DEFAULT,
  CaseListColumn,
  type CaseStatus,
  CurrentCaseListView,
  type Period,
  type Shareability,
} from 'components/case/caseConstants'
import { type GridSortModel, type GridScrollParams } from '@mui/x-data-grid'
import { type CaseInfo } from 'components/case/caseTypes'

const selectedCaseShareabilitiesState = atom<Shareability[]>({
  key: 'selectedCaseShareabilitiesState',
  default: CASE_SHAREABILITIES_DEFAULT,
})

const selectedCaseStatusesState = atom<CaseStatus[]>({
  key: 'selectedCaseStatusesState',
  default: CASE_STATUSES_DEFAULT,
})

const selectedCasePeriodState = atom<Period>({
  key: 'selectedCasePeriodState',
  default: CASE_PERIODS_DEFAULT,
})

const casesDataState = atom<CaseInfo[]>({
  key: 'casesDataState',
  default: [],
})

const sortModelState = atom<GridSortModel>({
  key: 'sortModelState',
  default: [
    {
      field: CaseListColumn.UPDATED,
      sort: 'desc',
    },
  ],
})

const offsetState = atom<number>({
  key: 'offsetState',
  default: 0,
})

const lastScrollPositionState = atom<GridScrollParams | null>({
  key: 'lastScrollPositionState',
  default: null,
})

const currentCaseListViewState = atom<CurrentCaseListView>({
  key: 'currentCaseListViewState',
  default: CurrentCaseListView.LIST,
})

const columnVisibilityState = atom<Record<CaseListColumn, boolean>>({
  key: 'columnVisibilityState',
  default: {
    [CaseListColumn.IMAGE]: true,
    [CaseListColumn.ADDRESS_AND_TITLE]: true,
    [CaseListColumn.SHAREABILITY]: true,
    [CaseListColumn.STATUS]: true,
    [CaseListColumn.CREATED_BY]: true,
    [CaseListColumn.UPDATED]: true,
    [CaseListColumn.CREATED]: false,
  },
})

const keywordState = atom<string>({
  key: 'keywordState',
  default: '',
})

export {
  selectedCaseShareabilitiesState,
  selectedCaseStatusesState,
  selectedCasePeriodState,
  casesDataState,
  offsetState,
  lastScrollPositionState,
  currentCaseListViewState,
  columnVisibilityState,
  sortModelState,
  keywordState,
}
