import { type ReactElement, useState, useMemo, useEffect } from 'react'
import { type User, getAuth, onAuthStateChanged } from 'firebase/auth'
import { useSetRecoilState } from 'recoil'

import AuthContext from './AuthContext'
import { casesDataState } from 'state/caseListStates'

type AuthProviderProps = {
  children: ReactElement
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authData, setAuthData] = useState<User | null>(null)
  const [isAuthLoading, setIsAuthLoading] = useState(true)
  const setCasesData = useSetRecoilState(casesDataState)

  const loadAuthData = async (): Promise<void> => {
    onAuthStateChanged(getAuth(), (data) => {
      if (data) {
        setAuthData(data)
      }
      setIsAuthLoading(false)
    })
  }

  useEffect(() => {
    setCasesData([])
    void loadAuthData()
  }, [])

  const clearAuthData = (): void => {
    setAuthData(null)
  }

  const memoizedAuthContextValue = useMemo(
    () => ({
      isAuthLoading,
      authData,
      clearAuthData,
    }),
    [authData, isAuthLoading],
  )

  return (
    <AuthContext.Provider value={memoizedAuthContextValue}>
      {children}
    </AuthContext.Provider>
  )
}
