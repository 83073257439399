import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useIntl } from 'react-intl'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { debounce } from '@mui/material/utils'
import styled from '@mui/material/styles/styled'
import { type PredictAddress, type LocationInfo } from '../../types'
import LocationMovingIcon from 'assets/icons/location_is_moving.svg'
import LocationSelectedIcon from 'assets/icons/location_selected.svg'
import { parseSvgToElement } from 'utils/domUtils'
import { useSetRecoilState } from 'recoil'
import {
  currentZoomState,
  selectedLocationInfoState,
  hasNewAddressState,
} from 'state/mapStates'
import { getGoogleMapsLoader } from 'components/form/mapLoader'

type AddressInputProps = {
  mapHeight: number
  region?: string
  language?: string
  zoom?: number
  center?: google.maps.LatLngLiteral
  selectedPosition?: google.maps.LatLngLiteral
  markerRadius?: number
}

const MapWrapper = styled(Box)`
  position: relative;
`

const AddressInputWrapper = styled(Box)`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 10px;
  left: 0;
  z-index: 1;
  width: 100%;

  & .MuiInputBase-root {
    background: ${({ theme }) => theme.palette.background.paper};
  }
`

const REGION = 'FI'
const DEFAULT_CENTER = { lat: 60.1695, lng: 24.9354 }
const DEFAULT_ZOOM = 10
const DEFAULT_LANGUAGE = 'fi'

const AddressInput: React.FC<AddressInputProps> = ({
  region = REGION,
  zoom = DEFAULT_ZOOM,
  center = DEFAULT_CENTER,
  language = DEFAULT_LANGUAGE,
  selectedPosition,
  mapHeight,
  markerRadius,
}) => {
  const { formatMessage } = useIntl()
  const [initialized, setInitialized] = useState(false)
  const [options, setOptions] = useState<PredictAddress[]>([])
  const [predictedAddress, setPredictedAddress] =
    useState<PredictAddress | null>(null)
  const [addressInputValue, setAddressInputValue] = useState('')

  const mapServiceRef = useRef<google.maps.Map | null>(null)
  const autoCompleteServiceRef =
    useRef<google.maps.places.AutocompleteService | null>(null)
  const mapElementRef = useRef<HTMLElement>(null)
  const placeServiceRef = useRef<google.maps.places.PlacesService | null>(null)
  const markerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(
    null,
  )
  const circleRef = useRef<google.maps.Circle | null>(null)
  const geocoderServiceRef = useRef<google.maps.Geocoder | null>(null)
  const setSelectedLocationInfo = useSetRecoilState(selectedLocationInfoState)
  const setHasNewAddress = useSetRecoilState(hasNewAddressState)
  const setCurrentZoom = useSetRecoilState(currentZoomState)

  useEffect(
    () => () => {
      setSelectedLocationInfo(null)
      setHasNewAddress(false)
    },
    [],
  )

  const getLocationInfo = (
    result: google.maps.GeocoderResult | google.maps.places.PlaceResult,
  ): LocationInfo | null => {
    if (result.formatted_address && result.geometry?.location) {
      const selectedLocation: LocationInfo = {
        address: result.formatted_address,
        position: {
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng(),
        },
        postalCode: '',
      }

      result.address_components?.forEach((addressComponent) => {
        if (addressComponent.types.includes('postal_code')) {
          selectedLocation.postalCode = addressComponent.long_name.replace(
            /\s+/g,
            '',
          )
        }
        if (addressComponent.types.includes('country')) {
          selectedLocation.country = addressComponent.long_name
          selectedLocation.countryCode = addressComponent.short_name
        }
      })

      return selectedLocation
    }

    return null
  }

  const init = useCallback(async (): Promise<void> => {
    const gmapLoader = getGoogleMapsLoader({
      region,
      language,
    })

    const { AutocompleteService, PlacesService } =
      await gmapLoader.importLibrary('places')
    const { Map, Circle } = await gmapLoader.importLibrary('maps')

    autoCompleteServiceRef.current = new AutocompleteService()
    geocoderServiceRef.current = new google.maps.Geocoder()

    if (mapElementRef.current) {
      mapServiceRef.current = new Map(mapElementRef.current, {
        center,
        zoom,
        mapId: process.env.REACT_APP_GMAP_ID,
        mapTypeControl: false,
        fullscreenControl: false,
      })

      placeServiceRef.current = new PlacesService(mapServiceRef.current)

      const locationSelectedIconNode = parseSvgToElement(
        <LocationSelectedIcon />,
      )
      const locationMovingIconNode = parseSvgToElement(<LocationMovingIcon />)

      const { AdvancedMarkerElement } = await gmapLoader.importLibrary('marker')
      circleRef.current = new Circle({
        map: mapServiceRef.current,
        strokeColor: '#07208D', // Color of the circle outline
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: 'rgba(7, 32, 141)', // Color of the circle fill
        fillOpacity: 0.2,
      })
      const marker = new AdvancedMarkerElement({
        map: mapServiceRef.current,
        content: locationSelectedIconNode,
      })

      mapServiceRef.current.addListener('drag', () => {
        marker.position = mapServiceRef.current?.getCenter()
        marker.content = locationMovingIconNode

        if (marker.position) {
          circleRef.current?.setCenter(marker.position)
        }
      })

      mapServiceRef.current.addListener('zoom_changed', () => {
        const zoom = mapServiceRef.current?.getZoom()
        if (zoom) {
          setCurrentZoom(zoom)
        }
      })

      mapServiceRef.current.addListener('dragend', async () => {
        marker.content = locationSelectedIconNode
        if (marker.position && geocoderServiceRef.current) {
          const { results } = await geocoderServiceRef.current.geocode({
            location: marker.position,
          })

          if (results.length > 0) {
            setPredictedAddress({
              address: results[0].formatted_address,
              placeId: results[0].place_id,
            })

            const locationInfo = getLocationInfo(results[0])
            if (locationInfo) {
              setSelectedLocationInfo({
                ...locationInfo,
                position: marker.position as google.maps.LatLngLiteral,
              })
              setHasNewAddress(true)
            }
          }
        }
      })

      markerRef.current = marker
      setHasNewAddress(false)
    }
    setInitialized(true)
  }, [])

  useEffect(() => {
    void init()
    return () => {
      if (mapServiceRef.current) {
        google.maps.event.clearInstanceListeners(mapServiceRef.current)
      }
    }
  }, [])

  const showMarker = useCallback(async (): Promise<void> => {
    if (
      selectedPosition &&
      geocoderServiceRef.current &&
      markerRef.current &&
      mapServiceRef.current
    ) {
      const { results } = await geocoderServiceRef.current.geocode({
        location: selectedPosition,
      })

      if (results.length > 0) {
        setPredictedAddress({
          address: results[0].formatted_address,
          placeId: results[0].place_id,
        })

        setSelectedLocationInfo(getLocationInfo(results[0]))

        markerRef.current.position = selectedPosition
        mapServiceRef.current.setCenter(selectedPosition)
      }
    }
  }, [selectedPosition])

  useEffect(() => {
    if (
      initialized &&
      selectedPosition &&
      mapServiceRef.current &&
      geocoderServiceRef.current &&
      markerRef.current
    ) {
      void showMarker()
    }
  }, [
    initialized,
    selectedPosition,
    markerRef.current,
    geocoderServiceRef.current,
    mapServiceRef.current,
  ])

  useEffect(() => {
    if (circleRef.current && selectedPosition && initialized) {
      circleRef.current?.setCenter(selectedPosition)
    }
  }, [selectedPosition, circleRef.current, initialized])

  useEffect(() => {
    if (circleRef.current && markerRadius && initialized) {
      circleRef.current.setRadius(markerRadius)
    }
  }, [markerRadius, circleRef.current, initialized])

  const fetchOptions = useMemo(
    () =>
      debounce(async (search: string) => {
        if (autoCompleteServiceRef.current) {
          const { predictions } =
            await autoCompleteServiceRef.current.getPlacePredictions({
              input: search,
            })

          setOptions(
            predictions.map((predict) => ({
              address: predict.description,
              placeId: predict.place_id,
            })),
          )
        }
      }, 400),
    [],
  )

  useEffect(() => {
    if (addressInputValue) {
      void fetchOptions(addressInputValue)
    } else {
      setOptions([])
    }
  }, [addressInputValue])

  const handleSelectAddress = (
    event: React.SyntheticEvent,
    newPredictedAddress: PredictAddress | null,
  ): void => {
    if (newPredictedAddress?.placeId) {
      setPredictedAddress(newPredictedAddress)

      placeServiceRef.current?.getDetails(
        { placeId: newPredictedAddress.placeId },
        (placeResult, status) => {
          if (
            status === google.maps.places.PlacesServiceStatus.OK &&
            placeResult?.geometry?.location &&
            markerRef.current &&
            mapServiceRef.current
          ) {
            const locationInfo = getLocationInfo(placeResult)

            if (locationInfo?.position) {
              mapServiceRef.current.setCenter(locationInfo.position)
              markerRef.current.position = locationInfo.position
              circleRef.current?.setCenter(locationInfo.position)

              setSelectedLocationInfo(locationInfo)
            }
          }
        },
      )

      setHasNewAddress(true)
    }
  }

  const renderOptions = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: PredictAddress,
    ): React.ReactNode => {
      return (
        <li {...props}>
          <Grid container alignItems="center">
            <Grid item sx={{ display: 'flex', width: 44 }}>
              <LocationOnIcon sx={{ color: 'text.secondary' }} />
            </Grid>
            <Grid
              item
              sx={{
                width: 'calc(100% - 44px)',
                wordWrap: 'break-word',
              }}
            >
              {option.address}
            </Grid>
          </Grid>
        </li>
      )
    },
    [addressInputValue],
  )

  return (
    <MapWrapper>
      <AddressInputWrapper>
        <Autocomplete
          autoComplete={true}
          selectOnFocus={true}
          options={options}
          isOptionEqualToValue={(option, value) =>
            option.address === value.address
          }
          getOptionLabel={(option) => option.address}
          noOptionsText={formatMessage({
            id: 'address_input.address_search_empty_result',
          })}
          value={predictedAddress}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              autoComplete="off"
              hiddenLabel={true}
              placeholder={formatMessage({
                id: 'address_input.placeholder.address',
              })}
              InputProps={{
                ...params.InputProps,
                type: 'search',
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOnIcon color="primary" />
                  </InputAdornment>
                ),
              }}
              fullWidth={true}
              sx={{ background: 'white', borderRadius: '4px' }}
            />
          )}
          onChange={handleSelectAddress}
          onInputChange={(event, newInputValue) => {
            setAddressInputValue(newInputValue)
          }}
          renderOption={renderOptions}
          sx={{ width: 'calc(100% - 20px)' }}
        />
      </AddressInputWrapper>

      <Box
        ref={mapElementRef}
        width={'100%'}
        height={mapHeight}
        borderRadius={2}
      />
    </MapWrapper>
  )
}

export default AddressInput
