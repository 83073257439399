import { atom } from 'recoil'

import { type UserAccess } from 'components/role/roleTypes'

const isUserLoadingState = atom<boolean>({
  key: 'isUserLoadingState',
  default: false,
})

const userAccessState = atom<UserAccess | null>({
  key: 'userAccessState',
  default: null,
})

export { userAccessState, isUserLoadingState }
