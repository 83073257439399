import { type ReactNode } from 'react'
import styled from '@mui/material/styles/styled'
import Box from '@mui/material/Box'

type LogoWrapperProps = {
  size?: number
  height?: number
  width?: number
  url?: string
  children?: ReactNode
  alt: string
}

const Wrapper = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.divider};
  overflow: hidden;
  background: ${({ theme }) => theme.palette.info.light};

  & img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: auto;
  }
`

const DEFAULT_SIZE = 70

const LogoWrapper: React.FC<LogoWrapperProps> = ({
  size,
  height,
  width,
  url,
  alt,
  children,
}) => {
  return (
    <Wrapper
      width={`${width ?? size ?? DEFAULT_SIZE}px`}
      height={`${height ?? size ?? DEFAULT_SIZE}px`}
      minWidth={`${width ?? size ?? DEFAULT_SIZE}px`}
      minHeight={`${height ?? size ?? DEFAULT_SIZE}px`}
      borderRadius={!!size && size < 50 ? 1 : 2}
    >
      {url && <img src={url} alt={alt} />}

      {!url && children}
    </Wrapper>
  )
}

export default LogoWrapper
