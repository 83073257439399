import { atom } from 'recoil'
import { type LocationInfo } from 'types'

const selectedLocationInfoState = atom<LocationInfo | null>({
  key: 'selectedLocationInfoState',
  default: null,
})

const hasNewAddressState = atom({
  key: 'hasNewAddressState',
  default: false,
})

const currentZoomState = atom<number>({
  key: 'currentZoomState',
  default: 10,
})

export { selectedLocationInfoState, hasNewAddressState, currentZoomState }
