import { atom, selectorFamily } from 'recoil'
import { type ProcessedFile } from '../utils/fileTypes'

const fileUploadingFormNamesState = atom<Set<string>>({
  key: 'fileUploadingNamesState',
  default: new Set(),
})

const isFileUploadingState = selectorFamily({
  key: 'isFileUploadingState',
  get:
    (formName: string) =>
    ({ get }) => {
      const results = get(fileUploadingFormNamesState)
      return results.has(formName)
    },
  set:
    (formName: string) =>
    ({ get, set }, newValue) => {
      const results = get(fileUploadingFormNamesState)
      if (newValue) {
        results.add(formName)
      } else {
        results.delete(formName)
      }
      set(fileUploadingFormNamesState, results)
    },
})

const processedFilesState = atom<ProcessedFile[]>({
  key: 'processedFilesState',
  default: [],
})

const processedFormFilesState = selectorFamily({
  key: 'processedFormFilesState',
  get:
    (formName: string) =>
    ({ get }) => {
      const allFiles = get(processedFilesState)
      return allFiles.filter((file) => file.formName === formName)
    },
  set:
    (formName: string) =>
    ({ get, set }, newValue) => {
      const allFiles = get(processedFilesState)
      const filteredFiles = allFiles.filter(
        (file) => file.formName !== formName,
      )

      set(processedFilesState, [
        ...filteredFiles,
        ...(newValue as ProcessedFile[]),
      ])
    },
})

const hasNewFileState = atom({
  key: 'hasNewFileState',
  default: false,
})

const hasUnsavedChangesState = atom({
  key: 'hasUnsavedChangesState',
  default: false,
})

const isUnsavedConfirmDialogOpenState = atom({
  key: 'isUnsavedConfirmDialogOpenState',
  default: false,
})

const isPublicCaseCreatedState = atom({
  key: 'isPublicCaseCreatedState',
  default: false,
})

export {
  hasUnsavedChangesState,
  isUnsavedConfirmDialogOpenState,
  isFileUploadingState,
  processedFilesState,
  hasNewFileState,
  processedFormFilesState,
  isPublicCaseCreatedState,
}
