import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

import { ContentWrapper } from 'components/StyledComponents'
import { Main } from 'layout/MainLayout'
import { Nav, RightSideBox } from 'layout/WithMenuLayout'
import { TopBannerWrapper } from 'components/TopBanner'
import { useLayoutEffect, useState } from 'react'
import { SMALL_SCREEN_SIZE } from 'commonConstants'
import {
  MENU_FOLDED_WIDTH,
  MENU_FULL_WIDTH,
  MenuWrapper,
} from 'components/MainMenu'

const Loader: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true)

  useLayoutEffect(() => {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    )

    if (vw < SMALL_SCREEN_SIZE) {
      setIsOpen(false)
    }
  }, [])

  return (
    <Box height={'100vh'} width="100%">
      <Stack height={'100%'} width="100%" overflow="hidden">
        <header>
          <TopBannerWrapper sx={{ flexGrow: 1 }}>
            <Grid container paddingX={2}>
              <Grid item flexGrow={1} alignSelf={'center'}>
                <Stack>
                  <Skeleton variant="rounded" width={140} height={40} />
                </Stack>
              </Grid>
              <Grid item textAlign={'right'} alignItems="center" display="flex">
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="rounded" width={100} height={40} />
                  <Skeleton variant="circular" width={40} height={40} />
                  <Skeleton variant="circular" width={40} height={40} />
                </Stack>
              </Grid>
            </Grid>
          </TopBannerWrapper>
        </header>
        <Main>
          <Stack direction="row" spacing={2} height={'100%'} minHeight={680}>
            <Nav>
              <MenuWrapper
                width={
                  isOpen ? `${MENU_FULL_WIDTH}px` : `${MENU_FOLDED_WIDTH}px`
                }
                spacing={2}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  width="100%"
                  alignItems="center"
                  paddingX={2}
                  paddingY={2}
                >
                  <Skeleton variant="circular" width={22} height={22} />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: '22px', flexGrow: 1 }}
                  />
                </Stack>

                <Stack
                  direction="row"
                  spacing={2}
                  width="100%"
                  alignItems="center"
                  paddingX={2}
                  paddingY={2}
                >
                  <Skeleton variant="circular" width={22} height={22} />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: '22px', flexGrow: 1 }}
                  />
                </Stack>

                <Stack
                  direction="row"
                  spacing={2}
                  width="100%"
                  alignItems="center"
                  paddingX={2}
                  paddingY={2}
                >
                  <Skeleton variant="circular" width={22} height={22} />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: '22px', flexGrow: 1 }}
                  />
                </Stack>

                <Stack
                  direction="row"
                  spacing={2}
                  width="100%"
                  alignItems="center"
                  paddingX={2}
                  paddingY={2}
                >
                  <Skeleton variant="circular" width={22} height={22} />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: '22px', flexGrow: 1 }}
                  />
                </Stack>

                <Stack
                  direction="row"
                  spacing={2}
                  width="100%"
                  alignItems="center"
                  paddingX={2}
                  paddingY={2}
                >
                  <Skeleton variant="circular" width={22} height={22} />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: '22px', flexGrow: 1 }}
                  />
                </Stack>

                <Stack
                  direction="row"
                  spacing={2}
                  width="100%"
                  alignItems="center"
                  paddingX={2}
                  paddingY={2}
                >
                  <Skeleton variant="circular" width={22} height={22} />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: '22px', flexGrow: 1 }}
                  />
                </Stack>
              </MenuWrapper>
            </Nav>
            <RightSideBox open={isOpen}>
              <ContentWrapper height={'100%'} padding={2}>
                <Skeleton
                  variant="text"
                  sx={{ fontSize: '30px' }}
                  width={140}
                />
              </ContentWrapper>
            </RightSideBox>
          </Stack>
        </Main>
      </Stack>
    </Box>
  )
}

export default Loader
