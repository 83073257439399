import { useRecoilValue } from 'recoil'
import FormControl from '@mui/material/FormControl'
import {
  type Control,
  Controller,
  useFieldArray,
  type FieldErrors,
  useWatch,
} from 'react-hook-form'
import { useIntl } from 'react-intl'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Select from '@mui/material/Select'
import { FormHelperText, MenuItem, Stack, styled } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import PhoneIcon from '@mui/icons-material/Phone'
import AddIcon from '@mui/icons-material/Add'
import EmailIcon from '@mui/icons-material/Email'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import {
  EMAIL_MAX_LENGTH,
  PHONE_MAX_LENGTH,
  EMERGENCY_PHONE_NUMBERS,
} from '../../commonConstants'
import {
  ITEM_CONTACT_TITLE_MAX_LENGTH,
  ItemContactMethodType,
} from './itemConstants'
import { type ContactFormData } from 'components/item/ContactEditDialog'
import { type Locale } from 'types'
import { isValidEmail, isValidPhoneNumber } from '../../utils/stringUtils'
import { sortedSupportLanguagesSelector } from 'state/portalSettingStates'
import { type LocalizedContact } from 'components/item/itemTypes'

type ContactEditFieldsProps = {
  index: number
  control: Control<ContactFormData, unknown>
  defaultLanguage: Locale
  defaultValue?: LocalizedContact
  errors?: FieldErrors<ContactFormData>
  onRemoveContact: (index: number) => void
  onCopyContact: (index: number) => void
}

const ContactSelect = styled(Select)`
  > .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  > .MuiInputBase-input {
    padding: 0;
    padding-top: 5px;
  }
`

const ContactFieldWrapper = styled(Box)`
  position: relative;
`

const DeleteButton = styled(IconButton)`
  position: absolute;
  right: -30px;
  top: 5px;
`

const ContactEditFields: React.FC<ContactEditFieldsProps> = ({
  index,
  control,
  defaultValue,
  defaultLanguage,
  errors,
  onRemoveContact,
  onCopyContact,
}) => {
  const { formatMessage } = useIntl()
  const supportLanguages = useRecoilValue(sortedSupportLanguagesSelector)
  const { fields, append, remove } = useFieldArray({
    control,
    name: `contacts.${index}.contacts`,
  })
  const contactMethods = useWatch({
    control,
    name: `contacts.${index}.contacts`,
  })

  const handleAddContactMethod = (): void => {
    append({ type: ItemContactMethodType.PHONE, contact: '' })
  }

  const handleRemoveContactMethod = (index: number): void => {
    remove(index)
  }

  console.log(errors)

  return (
    <Stack paddingX={2} width="100%" spacing={1}>
      <FormControl fullWidth error={!!errors?.contacts?.[index]?.name}>
        <Controller
          name={`contacts.${index}.name`}
          control={control}
          rules={{
            required: true,
            maxLength: ITEM_CONTACT_TITLE_MAX_LENGTH,
          }}
          defaultValue={defaultValue?.name ?? ''}
          render={({ field }) => (
            <TextField
              {...field}
              size="small"
              placeholder={formatMessage({
                id: 'contact_edit_dialog.placeholder.title',
              })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Controller
                      name={`contacts.${index}.language`}
                      control={control}
                      defaultValue={defaultLanguage}
                      render={({ field: selectField }) => (
                        <ContactSelect {...selectField}>
                          {supportLanguages.map((supportLanguage) => (
                            <MenuItem
                              key={supportLanguage}
                              value={supportLanguage.toUpperCase()}
                            >
                              {supportLanguage}
                            </MenuItem>
                          ))}
                        </ContactSelect>
                      )}
                    />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              fullWidth
            />
          )}
        />
        {errors?.contacts?.[index]?.name?.type === 'required' && (
          <FormHelperText>
            {formatMessage({
              id: 'contact_edit_dialog.error.required_title',
            })}
          </FormHelperText>
        )}
        {errors?.contacts?.[index]?.name?.type === 'maxLength' && (
          <FormHelperText>
            {formatMessage(
              {
                id: 'contact_edit_dialog.error.max_length_title',
              },
              {
                max: ITEM_CONTACT_TITLE_MAX_LENGTH,
              },
            )}
          </FormHelperText>
        )}
      </FormControl>

      {fields.map((field, methodIndex) => (
        <ContactFieldWrapper key={field.id}>
          <FormControl
            fullWidth
            error={
              !!errors?.contacts?.[index]?.contacts?.[methodIndex]?.contact
            }
          >
            <Controller
              name={`contacts.${index}.contacts.${methodIndex}.contact`}
              control={control}
              rules={{
                required: true,
                maxLength:
                  contactMethods?.[methodIndex]?.type ===
                  ItemContactMethodType.PHONE
                    ? PHONE_MAX_LENGTH
                    : EMAIL_MAX_LENGTH,
                validate: (val): boolean => {
                  if (!val || !contactMethods?.[methodIndex]) {
                    return true
                  }

                  const { type } = contactMethods[methodIndex]

                  if (type === ItemContactMethodType.PHONE) {
                    return (
                      EMERGENCY_PHONE_NUMBERS.includes(val) ||
                      isValidPhoneNumber(val)
                    )
                  }

                  return isValidEmail(val)
                },
              }}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Controller
                          name={`contacts.${index}.contacts.${methodIndex}.type`}
                          control={control}
                          defaultValue={ItemContactMethodType.PHONE}
                          render={({ field: selectField }) => (
                            <ContactSelect {...selectField}>
                              <MenuItem value={ItemContactMethodType.PHONE}>
                                <PhoneIcon fontSize="small" />
                              </MenuItem>
                              <MenuItem value={ItemContactMethodType.EMAIL}>
                                <EmailIcon fontSize="small" />
                              </MenuItem>
                            </ContactSelect>
                          )}
                        />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            {errors?.contacts?.[index]?.contacts?.[methodIndex]?.contact
              ?.type === 'validate' && (
              <FormHelperText>
                {formatMessage({
                  id:
                    contactMethods?.[methodIndex].type ===
                    ItemContactMethodType.PHONE
                      ? 'general.error.validate_phone'
                      : 'general.error.validate_email',
                })}
              </FormHelperText>
            )}
            {errors?.contacts?.[index]?.contacts?.[methodIndex]?.contact
              ?.type === 'maxLength' && (
              <FormHelperText>
                {formatMessage(
                  {
                    id:
                      contactMethods?.[methodIndex].type ===
                      ItemContactMethodType.PHONE
                        ? 'general.error.max_length_phone'
                        : 'general.error.max_length_email',
                  },
                  { max: EMAIL_MAX_LENGTH },
                )}
              </FormHelperText>
            )}
          </FormControl>

          {fields.length > 1 && (
            <DeleteButton
              size="small"
              onClick={(): void => {
                handleRemoveContactMethod(methodIndex)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </DeleteButton>
          )}
        </ContactFieldWrapper>
      ))}

      <Stack direction="row">
        <Box flexGrow={1}>
          <Button
            size="small"
            startIcon={<AddIcon />}
            onClick={handleAddContactMethod}
          >
            {formatMessage({
              id: 'contact_edit_dialog.button.add_contact',
            })}
          </Button>
        </Box>
        <Stack direction="row">
          <IconButton
            size="small"
            onClick={(): void => {
              onCopyContact(index)
            }}
            aria-label={formatMessage({
              id: 'general.icon_button.copy',
            })}
          >
            <ContentCopyIcon fontSize="inherit" />
          </IconButton>

          <IconButton
            size="small"
            onClick={(): void => {
              onRemoveContact(index)
            }}
            aria-label={formatMessage({
              id: 'general.icon_button.delete',
            })}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ContactEditFields
