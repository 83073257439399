import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'

import { hasNewFileState, processedFilesState } from 'state/formStates'
import { extractFilenameFromUrl } from 'utils/fileUtils'
import { FileType, PromiseStatus } from '../../commonConstants'
import FileUploader from 'components/form/FileUploader'
import { FILE_INPUT_ACCEPT_TYPE } from 'utils/fileConstants'

type ImageUploaderProps = {
  formName: string
  maxWidth: number
  maxHeight: number
  defaultImageUrl?: string
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  formName,
  maxWidth,
  maxHeight,
  defaultImageUrl,
}) => {
  const setProcessedFiles = useSetRecoilState(processedFilesState)
  const setHasNewFile = useSetRecoilState(hasNewFileState)

  useEffect(() => {
    if (defaultImageUrl) {
      const filename = extractFilenameFromUrl(defaultImageUrl)
      setProcessedFiles([
        {
          id: filename,
          formName,
          status: PromiseStatus.FULFILLED,
          file: new File([], filename, { type: 'image/png' }),
          url: defaultImageUrl,
          format: FileType.IMAGE,
        },
      ])
      setHasNewFile(false)
    }
  }, [defaultImageUrl])

  return (
    <FileUploader
      formName={formName}
      limit={1}
      accept={[FILE_INPUT_ACCEPT_TYPE.IMAGE]}
      toJpg={false}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      addFileIcon={AddPhotoAlternateIcon}
    />
  )
}

export default ImageUploader
