import { useEffect, type ReactNode, useRef, useMemo } from 'react'
import styled from '@mui/material/styles/styled'
import Box from '@mui/material/Box'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import EmailIcon from '@mui/icons-material/Email'
import ArticleIcon from '@mui/icons-material/Article'

import { FileType } from '../../commonConstants'
import { getResizedImageUrl } from 'utils/fileUtils'

type ResourceWrapperProps = {
  format?: string
  selected?: boolean
  size?: number
  height?: number | string
  width?: number | string
  base64?: string
  url?: string
  onClick?: () => void
  children?: ReactNode
  imageSize: 'cover' | 'contain'
  hasBorder?: boolean
}

const Wrapper = styled(Box)<{
  url?: string
  size: 'cover' | 'contain'
  selected?: boolean
  onClick?: () => void
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-image: url('${({ url }) => url}');
  background-size: ${({ size }) => size};
  background-position: center;
  background-repeat: no-repeat;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'normal')};
  border-color: ${({ selected, theme }) =>
    selected ? theme.palette.primary.main : theme.palette.divider};
  box-shadow: ${({ selected }) =>
    selected ? '0px 0px 3px 1px rgba(7, 32, 141, 1)' : 'none'};
`

const DEFAULT_SIZE = 70
const THUMBNAIL_SIZE = 200
const MIDDLE_SIZE = 600

const ResourceWrapper: React.FC<ResourceWrapperProps> = ({
  format,
  selected,
  size,
  imageSize,
  hasBorder = true,
  height,
  width,
  base64,
  url,
  onClick,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (selected && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selected])

  const finalWidth = width ?? size ?? DEFAULT_SIZE
  const finalHeight = height ?? size ?? DEFAULT_SIZE

  const finalUrl = useMemo((): string | undefined => {
    if (base64 && format === FileType.IMAGE) {
      return base64
    }

    if (
      !url ||
      format !== FileType.IMAGE ||
      (typeof finalWidth === 'number' && Number(finalHeight) > MIDDLE_SIZE)
    ) {
      return url
    }

    if (
      typeof finalWidth === 'number' &&
      Number(finalHeight) > THUMBNAIL_SIZE
    ) {
      return getResizedImageUrl(url, 'm')
    }

    return getResizedImageUrl(url, 's')
  }, [url, finalHeight, format, base64])

  return (
    <Wrapper
      ref={ref}
      url={finalUrl}
      width={`${finalWidth}${typeof finalWidth === 'number' ? 'px' : ''}`}
      height={`${finalHeight}${typeof finalHeight === 'number' ? 'px' : ''}`}
      minWidth={`${finalWidth}${typeof finalWidth === 'number' ? 'px' : ''}`}
      minHeight={`${finalHeight}${typeof finalHeight === 'number' ? 'px' : ''}`}
      borderRadius={!!size && size < 50 ? 1 : 2}
      size={imageSize}
      onClick={onClick}
      selected={selected}
      border={hasBorder ? '1px solid' : 'none'}
    >
      {format === FileType.PDF && (
        <PictureAsPdfIcon fontSize="large" color="secondary" />
      )}
      {(format === FileType.DOC || format === FileType.OTHER) && (
        <ArticleIcon fontSize="large" color="secondary" />
      )}
      {format === FileType.EMAIL && (
        <EmailIcon fontSize="large" color="secondary" />
      )}
      {children}
    </Wrapper>
  )
}

export default ResourceWrapper
