import { type MouseEvent, useCallback, useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import styled from '@mui/material/styles/styled'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import { type Resource } from 'types'
import { useRecoilState } from 'recoil'
import { selectedResourceIndexState } from 'state/resourceStates'
import { FileType } from '../../commonConstants'
import { getResourceFormat } from 'utils/fileUtils'

type ResourceSliderProps = {
  resources?: Resource[]
  width?: number
  height?: number
}

const Wrapper = styled(Box)`
  position: relative;

  justify-content: center;
  display: flex;
  width: 100%;
  height: calc(100vh - 300px);

  & button {
    background: ${({ theme }) => theme.palette.primary.light};

    :hover {
      background: ${({ theme }) => theme.palette.primary.light};
    }
  }
`

const ArrowRight = ArrowForwardIosIcon
const ArrowLeft = styled(ArrowForwardIosIcon)`
  transform: rotate(180deg);
`

const StyledImage = styled('img')`
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  object-fit: contain;
  cursor: zoom-in;
`

const ImageWrapper = styled(Stack)`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: calc(100% - 200px);
  position: relative;
`

const ZoomedImage = styled('img')`
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: auto;
  max-width: none;
  max-height: none;
  cursor: zoom-out;
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`

const IconBackButton = styled(StyledIconButton)`
  left: 3%;
`

const IconForwardButton = styled(StyledIconButton)`
  right: 3%;
`

const DownloadWrapper = styled(Stack)`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  width: calc(100% - 100px);
  height: 60vh;
  align-items: center;
  justify-content: center;
`

const IndicatorWrapper = styled(Stack)`
  position: absolute;
  z-index: 1;
  bottom: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const IndicatorDot = styled(Box)`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #c2c2c2;
  cursor: pointer;

  &.selected {
    background: white;
  }
`

const ResourceSlider: React.FC<ResourceSliderProps> = ({
  resources,
  width,
  height,
}) => {
  const { formatMessage } = useIntl()
  const [selectedResourceIndex, setSelectedResourceIndex] = useRecoilState(
    selectedResourceIndexState,
  )
  const [isZoomIn, setIsZoomIn] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const imageWrapperRef = useRef<HTMLDivElement>(null)
  const imageElementRef = useRef<HTMLImageElement>(null)

  const toggleZoomIn = useCallback(() => {
    setIsZoomIn((prev) => !prev)
  }, [])

  const handlePrevImage = useCallback(
    (event: MouseEvent): void => {
      if (resources?.length) {
        event.stopPropagation()
        setIsZoomIn(false)
        setSelectedResourceIndex((prevIndex) =>
          prevIndex === 0 ? resources.length - 1 : prevIndex - 1,
        )
      }
    },
    [resources],
  )

  const handleNextImage = useCallback(
    (event: MouseEvent): void => {
      if (resources?.length) {
        event.stopPropagation()
        setIsZoomIn(false)
        setSelectedResourceIndex((prevIndex) =>
          prevIndex === resources.length - 1 ? 0 : prevIndex + 1,
        )
      }
    },
    [resources],
  )

  const handleMouseMove = (e: MouseEvent): void => {
    if (isZoomIn && imageWrapperRef.current && imageElementRef.current) {
      const wrapper = imageWrapperRef.current.getBoundingClientRect()
      const { naturalWidth, naturalHeight } = imageElementRef.current
      const ratio = naturalWidth / naturalHeight
      const { clientX, clientY } = e

      const x = clientX - wrapper.x
      const y =
        ((clientY - wrapper.y) / wrapper.height) *
        ((wrapper.width * 2) / ratio - wrapper.height)
      setPosition({ x, y })
    }
  }

  if (!resources?.length) {
    return null
  }

  return (
    <Wrapper>
      {resources.length > 1 && (
        <IconBackButton
          onClick={handlePrevImage}
          size={'medium'}
          aria-label={formatMessage({
            id: 'general.icon_button.previous',
          })}
          color="primary"
        >
          <ArrowLeft fontSize="inherit" />
        </IconBackButton>
      )}

      {getResourceFormat(resources[selectedResourceIndex]) ===
        FileType.IMAGE && (
        <ImageWrapper onMouseMove={handleMouseMove} ref={imageWrapperRef}>
          {isZoomIn ? (
            <ZoomedImage
              src={resources[selectedResourceIndex]?.uri}
              onClick={toggleZoomIn}
              style={{
                top: -position.y,
                left: -position.x,
              }}
              ref={imageElementRef}
            />
          ) : (
            <StyledImage
              src={resources[selectedResourceIndex]?.uri}
              onClick={toggleZoomIn}
            />
          )}
        </ImageWrapper>
      )}

      {getResourceFormat(resources[selectedResourceIndex]) !==
        FileType.IMAGE && (
        <DownloadWrapper>
          <Link
            href={resources[selectedResourceIndex].uri}
            underline="none"
            download
            target="_blank"
          >
            <Typography variant="h5">
              {formatMessage({ id: 'resource_slider.link.download_file' })}:{' '}
              {resources[selectedResourceIndex].name}
            </Typography>
          </Link>
        </DownloadWrapper>
      )}

      {resources.length > 1 && (
        <IndicatorWrapper direction="row" spacing={1}>
          {resources.map((resource, index) => (
            <IndicatorDot
              key={resource.uri}
              className={index === selectedResourceIndex ? 'selected' : ''}
              onClick={(e) => {
                e.stopPropagation()
                setSelectedResourceIndex(index)
              }}
            ></IndicatorDot>
          ))}
        </IndicatorWrapper>
      )}

      {resources.length > 1 && (
        <IconForwardButton
          onClick={handleNextImage}
          size={'medium'}
          aria-label={formatMessage({
            id: 'general.icon_button.next',
          })}
          color="primary"
        >
          <ArrowRight fontSize="inherit" />
        </IconForwardButton>
      )}
    </Wrapper>
  )
}

export default ResourceSlider
