import { useState } from 'react'
import { useIntl } from 'react-intl'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import MuiLink from '@mui/material/Link'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import AppleStoreIcon from 'assets/icons/apple_store_icon_button.svg'
import GoogleStoreIcon from 'assets/icons/google_store_icon_button.svg'
import EportiIcon from 'assets/icons/eporti_icon.svg'
import QrCodeIcon from 'assets/icons/qr_code_icon_button.svg'
import EportiQrCode from 'assets/icons/eporti_qr_code.svg'

const DownloadLink = styled(MuiLink)`
  display: flex;
`

const DialogTitleWrapper = styled(DialogTitle)`
  display: flex;
  justify-content: center;
  background: #ff0058;
  color: white;
  padding: 36px;
  font-size: 22px;
  font-weight: 500;
`

const PublicDownloadAppLinks: React.FC = () => {
  const { formatMessage } = useIntl()
  const [isQrCodeDialogOpen, setIsQrCodeDialogOpen] = useState(false)

  const closeQrCodeDialog = (): void => {
    setIsQrCodeDialogOpen(false)
  }

  const openQrCodeDialog = (): void => {
    setIsQrCodeDialogOpen(true)
  }

  return (
    <>
      <DownloadLink
        aria-label={formatMessage({
          id: 'general.icon_button.download_ios_app',
        })}
        href={process.env.REACT_APP_IOS_APP_LINK}
        target="_blank"
      >
        <AppleStoreIcon />
      </DownloadLink>
      <DownloadLink
        aria-label={formatMessage({
          id: 'general.icon_button.download_android_app',
        })}
        href={process.env.REACT_APP_ANDROID_APP_LINK}
        target="_blank"
      >
        <GoogleStoreIcon />
      </DownloadLink>
      <DownloadLink
        aria-label={formatMessage({
          id: 'general.icon_button.scan_qr_code',
        })}
        onClick={openQrCodeDialog}
      >
        <QrCodeIcon />
      </DownloadLink>

      <Dialog
        onClose={closeQrCodeDialog}
        open={isQrCodeDialogOpen}
        maxWidth="xs"
      >
        <DialogTitleWrapper textAlign="center">
          <Stack alignItems="center" spacing={2}>
            <EportiIcon />
            <Box textAlign="center">
              {formatMessage({ id: 'general.icon_button.scan_qr_code' })}
            </Box>
          </Stack>
        </DialogTitleWrapper>
        <IconButton
          aria-label={formatMessage({ id: 'general.button.close' })}
          onClick={closeQrCodeDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Stack alignItems="center" spacing={2}>
            <EportiQrCode />
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default PublicDownloadAppLinks
