import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { getAuth, checkActionCode, applyActionCode } from 'firebase/auth'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

type VerifyEmailPageProps = {
  oobCode: string | null
}

const Wrapper = styled(Stack)`
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => 4 * theme.shape.borderRadius}px;
  width: 560px;
  max-width: 100vw;
`

const Header = styled(Typography)`
  font-size: 28px;
  font-weight: 500;
`

const VerifyEmailPage: React.FC<VerifyEmailPageProps> = ({ oobCode }) => {
  const { formatMessage } = useIntl()
  const [isCodeValid, setIsCodeValid] = useState(true)
  const [isVerified, setIsVerified] = useState(false)

  const verifyCode = useCallback(async (): Promise<void> => {
    if (oobCode) {
      try {
        const auth = getAuth()
        await checkActionCode(auth, oobCode)
        await applyActionCode(auth, oobCode)
        setIsVerified(true)
      } catch (error) {
        setIsCodeValid(false)
      }
    }
  }, [oobCode])

  useEffect(() => {
    void verifyCode()
  }, [])

  return (
    <Wrapper padding={7} spacing={2}>
      <Stack alignItems="center">
        <Header>{formatMessage({ id: 'verify_email.header' })}</Header>
      </Stack>

      {!isCodeValid && (
        <Alert severity="error">
          <AlertTitle>
            {formatMessage({ id: 'reset_password.error.invalid_link' })}
          </AlertTitle>
        </Alert>
      )}

      {isVerified && (
        <Alert severity="success">
          <AlertTitle>
            {formatMessage({ id: 'verify_email.verified' })}
          </AlertTitle>
        </Alert>
      )}
    </Wrapper>
  )
}

export default VerifyEmailPage
