import { useIntl } from 'react-intl'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import { SubHeader } from 'components/StyledComponents'
import EmptyResultIcon from 'assets/icons/empty_result.svg'

const UnauthorizedPage: React.FC = () => {
  const { formatMessage } = useIntl()

  return (
    <Stack
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Stack textAlign="center" spacing={2} alignItems="center">
        <EmptyResultIcon />
        <Box width="100%">
          <SubHeader>
            {formatMessage({
              id: 'unauthorized.label.cannot_access_this_page',
            })}
          </SubHeader>
        </Box>
      </Stack>
    </Stack>
  )
}

export default UnauthorizedPage
