import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import MenuItem from '@mui/material/MenuItem'
import styled from '@mui/material/styles/styled'
import ListItemIcon from '@mui/material/ListItemIcon'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import LoadingButton from '@mui/lab/LoadingButton'
import Menu from '@mui/material/Menu'
import ListItemText from '@mui/material/ListItemText'

import {
  CASE_STATUS_LABEL,
  CaseStatus,
  type VirtualPortalCaseStatus,
} from './caseConstants'
import {
  getCaseStatusColor,
  getCaseStatusIcon,
} from 'components/case/caseUtils'

type CaseStatusButtonProps = {
  status: CaseStatus | VirtualPortalCaseStatus
  transitions: CaseStatus[] | VirtualPortalCaseStatus[]
  loading?: boolean
  onUpdateStatus: (status: CaseStatus | VirtualPortalCaseStatus) => void
}

const StatusButton = styled(LoadingButton)<{
  status: CaseStatus | VirtualPortalCaseStatus
}>`
  background: ${({ status }) => getCaseStatusColor(status)};
  color: ${({ status, theme }) =>
    status === CaseStatus.NEW ||
    status === CaseStatus.FORWARDED ||
    status === CaseStatus.NOT_FORWARDED
      ? theme.palette.common.white
      : theme.palette.common.black};
  border: none;
  border-radius: ${({ theme }) => 1.5 * theme.shape.borderRadius}px;
  font-size: 12px;

  :hover {
    border: none;
    background: ${({ status }) => getCaseStatusColor(status)};
  }

  & svg {
    color: ${({ status, theme }) =>
      status === CaseStatus.NEW ||
      status === CaseStatus.FORWARDED ||
      status === CaseStatus.NOT_FORWARDED
        ? theme.palette.common.white
        : theme.palette.common.black};
    font-size: 16px;
  }

  > .MuiSelect-select {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};
    padding: 2px 8px;

    > .MuiListItemIcon-root {
      min-width: 0;
    }
  }
`

const CaseStatusButton: React.FC<CaseStatusButtonProps> = ({
  status,
  transitions,
  loading = false,
  onUpdateStatus,
}) => {
  const { formatMessage } = useIntl()
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(menuEl)

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      if (transitions?.length) {
        setMenuEl(event.currentTarget)
      }
    },
    [transitions],
  )

  const handleCloseMenu = (): void => {
    setMenuEl(null)
  }

  const renderStatusIcon = (
    status: CaseStatus | VirtualPortalCaseStatus,
  ): React.ReactNode => {
    const CaseStatusIcon = getCaseStatusIcon(status)

    return (
      <>
        {CaseStatusIcon && (
          <CaseStatusIcon
            fontSize="small"
            sx={{ color: getCaseStatusColor(status) }}
          />
        )}
      </>
    )
  }

  const handleUpdateStatus = (
    newStatus: CaseStatus | VirtualPortalCaseStatus,
  ): void => {
    onUpdateStatus(newStatus)
    handleCloseMenu()
  }

  return (
    <>
      <StatusButton
        loading={loading}
        variant="outlined"
        size="small"
        status={status}
        startIcon={renderStatusIcon(status)}
        endIcon={
          transitions.length > 0 &&
          (menuEl ? <ExpandLessIcon /> : <ExpandMoreIcon />)
        }
        onClick={handleOpenMenu}
      >
        {formatMessage({
          id: CASE_STATUS_LABEL[status],
        })}
      </StatusButton>

      <Menu anchorEl={menuEl} open={openMenu} onClose={handleCloseMenu}>
        {transitions.map((caseStatus) => (
          <MenuItem
            value={caseStatus}
            key={caseStatus}
            selected={caseStatus === status}
            onClick={(): void => {
              handleUpdateStatus(caseStatus)
            }}
          >
            <ListItemIcon>{renderStatusIcon(caseStatus)}</ListItemIcon>
            <ListItemText
              primary={formatMessage({
                id: CASE_STATUS_LABEL[caseStatus as CaseStatus],
              })}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default CaseStatusButton
