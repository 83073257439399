import { useState } from 'react'
import { useIntl } from 'react-intl'
import styled from '@mui/material/styles/styled'
import Fab from '@mui/material/Fab'
import Slide from '@mui/material/Slide'
import AlertIcon from '@mui/icons-material/Report'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined'

import { type PublicAlertInfo } from './publicCaseTypes'
import Stack from '@mui/material/Stack'
import usePortalSetting from 'hooks/usePortalSetting'
import { SubHeader } from 'components/StyledComponents'
import { Divider, Typography } from '@mui/material'
import { isMobileViewState } from 'state/layoutStates'
import { useRecoilValue } from 'recoil'

type PublicAlertsProps = {
  alerts?: PublicAlertInfo[]
}

const FloatingAlert = styled(Fab)`
  position: absolute;
  right: 16px;
  top: 70px;
  z-index: 1;
  background: #b62948;

  & svg {
    color: white;
  }

  :hover {
    background: #b62948;
  }
`

const AlertWrapper = styled(Stack)`
  position: absolute;
  top: 66px;
  right: 0px;
  z-index: 1;
  background: #b62948;
  color: white;
  padding: 8px 16px;
  border-bottom-left-radius: ${({ theme }) => 4 * theme.shape.borderRadius}px;
  max-width: 400px;
`

const RightArrow = styled(ExpandCircleDownOutlinedIcon)`
  color: white;
  transform: rotate(-90deg);
  cursor: pointer;
`

const PublicAlerts: React.FC<PublicAlertsProps> = ({ alerts }) => {
  const { formatMessage } = useIntl()
  const [isAlertOpen, setIsAlertOpen] = useState(true)
  const { getLocalizedContent } = usePortalSetting()
  const isMobileView = useRecoilValue(isMobileViewState)

  const handleOpenAlert = (): void => {
    setIsAlertOpen(true)
  }

  const handleCloseAlert = (): void => {
    setIsAlertOpen(false)
  }

  if (!alerts?.length) {
    return null
  }

  return (
    <>
      {!isAlertOpen && (
        <FloatingAlert size="small" onClick={handleOpenAlert}>
          <AlertIcon />
        </FloatingAlert>
      )}

      <Slide direction="left" in={isAlertOpen} mountOnEnter>
        <AlertWrapper
          spacing={1}
          sx={{
            width: isMobileView ? '100%' : '400px',
            maxWidth: isMobileView ? '100%' : '400px',
            borderBottomLeftRadius: isMobileView ? 0 : '16px',
          }}
        >
          <Stack direction="row" spacing={1} alignItems={'center'}>
            <Stack
              direction="row"
              spacing={1}
              alignItems={'center'}
              flexGrow={1}
            >
              <AlertIcon />
              <SubHeader>
                {formatMessage({ id: 'public_case.alert_dialog.title' })}
              </SubHeader>
            </Stack>
            <RightArrow onClick={handleCloseAlert} />
          </Stack>

          {alerts?.map((alert, index) => (
            <Stack spacing={1} key={alert.id} sx={{ whiteSpace: 'pre-line' }}>
              <Typography variant="body2">
                {getLocalizedContent(alert.texts)}
              </Typography>
              {index > 0 && index !== alerts.length - 1 && (
                <Divider sx={{ background: 'white' }} />
              )}
            </Stack>
          ))}
        </AlertWrapper>
      </Slide>
    </>
  )
}

export default PublicAlerts
