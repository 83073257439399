import { type CategoryData } from 'components/category/categoryTypes'
import CategorySelectorItem from './CategorySelectorItem'
import { CATEGORY_MAX_DEPTH } from 'commonConstants'

type SubCategorySelectorTreeProps = {
  paths: string[]
  categories: CategoryData[]
  selectedItemId: string | null
  onCategoryChange: (ids: string) => void
}

const SubCategorySelectorTree: React.FC<SubCategorySelectorTreeProps> = ({
  paths,
  categories,
  selectedItemId,
  onCategoryChange,
}) => {
  if (paths.length >= CATEGORY_MAX_DEPTH) {
    return null
  }

  return categories?.map((category, index) => (
    <CategorySelectorItem
      key={category.id}
      paths={[...paths, category.id]}
      category={category}
      selectedItemId={selectedItemId}
      onCategoryChange={onCategoryChange}
    />
  ))
}

export default SubCategorySelectorTree
