import { type MouseEvent, useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from '@mui/material/styles/styled'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ZoomInIcon from '@mui/icons-material/ZoomIn'

import { type Resource } from 'types'
import ResourceWrapper from 'components/resource/ResourceWrapper'
import ResourceGallery from 'components/resource/ResourceGallery'
import { useSetRecoilState } from 'recoil'
import { selectedResourceIndexState } from 'state/resourceStates'
import { getResourceFormat } from 'utils/fileUtils'

type ResourceSliderProps = {
  resources?: Resource[]
  showZoomInButton: boolean
  width?: number | string
  height?: number | string
  imageSize: 'cover' | 'contain'
}

const Wrapper = styled(Box)`
  position: relative;
`

const IndicatorWrapper = styled(Stack)`
  position: absolute;
  z-index: 1;
  bottom: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const IndicatorDot = styled(Box)`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #c2c2c2;

  &.selected {
    background: white;
  }
`

const ArrowRight = ArrowForwardIosIcon

const ArrowLeft = styled(ArrowForwardIosIcon)`
  transform: rotate(180deg);
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background: ${({ theme }) => theme.palette.primary.light};
  color: ${({ theme }) => theme.palette.text.primary};

  &:hover {
    background: ${({ theme }) => theme.palette.primary.light};
  }
`

const IconBackButton = styled(StyledIconButton)`
  left: 3%;
`

const IconForwardButton = styled(StyledIconButton)`
  right: 3%;
`

const ZoomInButton = styled(IconButton)`
  position: absolute;
  z-index: 1;
  color: ${({ theme }) => theme.palette.primary.light};
  right: 0;
`

const ResourceInlineSlider: React.FC<ResourceSliderProps> = ({
  resources,
  showZoomInButton,
  width,
  height,
  imageSize,
}) => {
  const { formatMessage } = useIntl()
  const setSelectedResourceIndex = useSetRecoilState(selectedResourceIndexState)
  const [currentResourceIndex, setCurrentResourceIndex] = useState(0)
  const [isResourceGalleryOpen, setIsResourceGalleryOpen] = useState(false)

  const handleResourceGalleryClose = (): void => {
    setIsResourceGalleryOpen(false)
  }

  const openResourceGallery = (): void => {
    setIsResourceGalleryOpen(true)
    setSelectedResourceIndex(currentResourceIndex)
  }

  if (!resources?.length) {
    return null
  }

  const handlePrevImage = useCallback(
    (event: MouseEvent): void => {
      event.stopPropagation()
      setCurrentResourceIndex((prevIndex) =>
        prevIndex === 0 ? resources.length - 1 : prevIndex - 1,
      )
    },
    [resources],
  )

  const handleNextImage = useCallback(
    (event: MouseEvent): void => {
      event.stopPropagation()
      setCurrentResourceIndex((prevIndex) =>
        prevIndex === resources.length - 1 ? 0 : prevIndex + 1,
      )
    },
    [resources],
  )

  return (
    <Wrapper>
      {resources.length > 1 && (
        <IconBackButton
          onClick={handlePrevImage}
          size={'small'}
          aria-label={formatMessage({
            id: 'general.icon_button.previous',
          })}
        >
          <ArrowLeft fontSize="inherit" />
        </IconBackButton>
      )}

      {showZoomInButton && (
        <ZoomInButton
          size={'large'}
          aria-label={formatMessage({
            id: 'public_case.button.zoom_in',
          })}
          onClick={openResourceGallery}
        >
          <ZoomInIcon fontSize="inherit" />
        </ZoomInButton>
      )}

      <ResourceWrapper
        url={resources[currentResourceIndex]?.uri}
        format={getResourceFormat(resources[currentResourceIndex])}
        width={width}
        height={height}
        imageSize={imageSize}
      />

      {resources.length > 1 && (
        <IndicatorWrapper direction="row" spacing={1}>
          {resources.map((resource, index) => (
            <IndicatorDot
              key={resource.uri}
              className={index === currentResourceIndex ? 'selected' : ''}
              onClick={() => {
                setCurrentResourceIndex(index)
              }}
            ></IndicatorDot>
          ))}
        </IndicatorWrapper>
      )}

      {resources.length > 1 && (
        <IconForwardButton
          onClick={handleNextImage}
          size={'small'}
          aria-label={formatMessage({
            id: 'general.icon_button.next',
          })}
        >
          <ArrowRight fontSize="inherit" />
        </IconForwardButton>
      )}

      <ResourceGallery
        resources={resources ?? []}
        isOpen={isResourceGalleryOpen}
        onClose={handleResourceGalleryClose}
      />
    </Wrapper>
  )
}

export default ResourceInlineSlider
