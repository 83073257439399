import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import { useRecoilValue } from 'recoil'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import LightbulbIcon from '@mui/icons-material/Lightbulb'

import { MainHeader, SubHeader } from 'components/StyledComponents'
import EmptyTeamIcon from 'assets/icons/empty_team.svg'
import useRoute from 'hooks/useNavigate'
import { type Team } from 'types'
import { Path } from '../commonConstants'
import { portalSettingState } from 'state/portalSettingStates'
import useMember from 'hooks/useMember'
import LogoWrapper from 'components/LogoWrapper'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'

const ItemWrapper = styled(Stack)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.divider};
  position: relative;
  cursor: pointer;
`

const TeamWrapper = styled(Stack)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.divider};
  position: relative;
`

const TeamListPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const portalSetting = useRecoilValue(portalSettingState)
  const { data: teamsData, isLoading } = useSWR<Team[]>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/teams`
      : null,
  )
  const { goTo } = useRoute()
  const { checkAccesses } = useMember()

  const handleTeamClick = (teamId: string): void => {
    goTo(`${Path.TEAMS_EDIT}/${teamId}`)
  }

  const sortedTeams = useMemo(
    () =>
      teamsData?.sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }

        return 0
      }),
    [teamsData],
  )

  return (
    <Stack height="100%" width="100%" spacing={2} overflow="auto">
      <Stack direction="row" width="100%" spacing={2}>
        <Box flexGrow={1}>
          <MainHeader>{formatMessage({ id: 'team_list.header' })}</MainHeader>
        </Box>

        {sortedTeams &&
          sortedTeams?.length > 0 &&
          checkAccesses({ [PortalSection.TEAMS]: [FeatureAccess.WRITE] }) && (
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => {
                goTo(Path.TEAMS_ADD)
              }}
              color="secondary"
            >
              {formatMessage({ id: 'team_list.button.create_team' })}
            </Button>
          )}
      </Stack>

      <Stack flexGrow={1} overflow="auto" spacing={2}>
        {!sortedTeams ||
          (sortedTeams.length === 0 && (
            <TeamWrapper
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              {isLoading && <CircularProgress />}

              {!isLoading &&
                checkAccesses({
                  [PortalSection.TEAMS]: [FeatureAccess.WRITE],
                }) && (
                  <Stack textAlign="center" spacing={2} alignItems="center">
                    <EmptyTeamIcon />
                    <Box width="100%">
                      <SubHeader>
                        {formatMessage({
                          id: 'team_list.label.start_creating_team',
                        })}
                      </SubHeader>
                    </Box>
                    <Box width="100%">
                      <Button
                        variant="contained"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => {
                          goTo(Path.TEAMS_ADD)
                        }}
                        color="secondary"
                      >
                        {formatMessage({
                          id: 'team_list.button.create_team',
                        })}
                      </Button>
                    </Box>
                  </Stack>
                )}
            </TeamWrapper>
          ))}

        {sortedTeams?.map((team) => (
          <ItemWrapper
            key={team.id}
            direction="row"
            spacing={2}
            paddingX={4}
            paddingY={2}
            alignItems="center"
            onClick={() => {
              handleTeamClick(team.id)
            }}
          >
            <LogoWrapper size={120} url={team.logoUrl} alt={team.name}>
              {!team.logoUrl && (
                <LightbulbIcon
                  color="primary"
                  fontSize="small"
                  sx={{ fontSize: 80 }}
                />
              )}
            </LogoWrapper>

            <Stack justifyContent="center" spacing={1}>
              <SubHeader>{team.name}</SubHeader>
              <Typography variant="body1">{team.description}</Typography>
            </Stack>
          </ItemWrapper>
        ))}
      </Stack>
    </Stack>
  )
}

export default TeamListPage
