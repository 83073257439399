import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDropzone } from 'react-dropzone'
import styled from '@mui/material/styles/styled'
import { grey } from '@mui/material/colors'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import UploadFileIcon from '@mui/icons-material/UploadFile'

import FileInput from './FileInput'
import useFileUpload from 'hooks/useFileUpload'
import {
  FILE_INPUT_ACCEPT_MAP,
  type FILE_INPUT_ACCEPT_TYPE,
  MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD,
  MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD,
} from 'utils/fileConstants'
import UploadedFilesPreview from 'components/form/UploadedFilesPreview'

interface FileUploaderProps {
  formName: string
  limit: number
  accept: FILE_INPUT_ACCEPT_TYPE[]
  toJpg?: boolean
  maxWidth?: number
  maxHeight?: number
  defaultImageUrl?: string
  addFileIcon?: typeof UploadFileIcon
  isPublic?: boolean
}

const FileUploaderWrapper = styled(Stack)`
  border: 1px dashed;
  border-color: ${({ theme }) => theme.palette.grey[300]};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  background: ${({ theme }) => theme.palette.background.paper};
  color: ${({ theme }) => theme.palette.text.secondary};
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spacing(1)};
`

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}))

const AddImageWhenEmptyWrapper = styled(Stack)`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  width: 100%;
`

const FileUploader: React.FC<FileUploaderProps> = ({
  formName,
  limit,
  accept,
  toJpg = true,
  isPublic = false,
  maxWidth = MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD,
  maxHeight = MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD,
  addFileIcon,
}) => {
  const { formatMessage } = useIntl()
  const { setSelectedFiles, processedFormFiles, handleDeleteFile, errors } =
    useFileUpload({
      formName,
      limit,
      toJpg,
      maxWidth,
      maxHeight,
      isPublic,
    })

  const AddFileIcon = addFileIcon || UploadFileIcon

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      if (acceptedFiles.length > limit) {
        acceptedFiles.length = limit
      }

      setSelectedFiles(acceptedFiles)
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accept.reduce<Partial<typeof FILE_INPUT_ACCEPT_MAP>>((obj, key) => {
      obj[key] = FILE_INPUT_ACCEPT_MAP[key]
      return obj
    }, {}),
  })

  return (
    <Stack width="100%" height="100%">
      <FileUploaderWrapper
        spacing={2}
        height="100%"
        alignItems={limit > 1 ? 'left' : 'center'}
        justifyContent="center"
        {...getRootProps()}
      >
        <UploadedFilesPreview
          processedFormFiles={processedFormFiles}
          onDeleteFile={handleDeleteFile}
          limit={limit}
        />
        {processedFormFiles.length === 0 && (
          <AddImageWhenEmptyWrapper spacing={2}>
            <AddFileIcon fontSize="large" sx={{ color: grey[500] }} />
            <Typography>
              {formatMessage({
                id:
                  limit === 1
                    ? 'image_uploader.label.add_single_file'
                    : 'image_uploader.label.add_files',
              })}
            </Typography>
          </AddImageWhenEmptyWrapper>
        )}

        <FileInput
          {...getInputProps()}
          multiple={limit > 1}
          name="file"
          accept={accept.join(',')}
        />
      </FileUploaderWrapper>

      {errors?.map((error) => (
        <ErrorMessage key={error}>{error}</ErrorMessage>
      ))}
    </Stack>
  )
}

export default FileUploader
