import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSetRecoilState } from 'recoil'
import { useForm, Controller } from 'react-hook-form'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import CloseIcon from '@mui/icons-material/Close'

import { hasUnsavedChangesState } from 'state/formStates'
import {
  type Poll,
  type PollResultConfigFormData,
} from 'components/poll/pollTypes'
import {
  FormFieldsWrapper,
  SelectBox,
  SubSubHeader,
} from 'components/StyledComponents'
import { PollCategory, PollResultExposure } from 'components/poll/pollConstants'
import {
  ExposureAlwaysIcon,
  ExposureAfterIcon,
  ExposureNeverIcon,
} from 'components/icons/Icons'

type PollEditResultConfigDialogProps = {
  poll: Poll | null
  isOpen: boolean
  isSaving: boolean
  onSave: (data: PollResultConfigFormData) => void
  onClose: () => void
}

const PollEditResultConfigDialog: React.FC<PollEditResultConfigDialogProps> = ({
  poll,
  isOpen,
  isSaving,
  onSave,
  onClose,
}) => {
  const { formatMessage } = useIntl()

  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const [disabledExposures, setDisabledExposures] = useState<
    Set<PollResultExposure>
  >(new Set())
  const {
    control,
    getValues,
    setValue,
    formState: { errors, isValid },
    watch,
  } = useForm<PollResultConfigFormData>({
    mode: 'onTouched',
  })

  const exposure = watch('exposure')

  useEffect(() => {
    if (isOpen && poll?.result.configuration) {
      setValue('exposure', poll.result.configuration.exposure)
      setValue('retentionDays', poll.result.configuration.retentionDays)

      if (poll.category === PollCategory.ONGOING) {
        if (
          poll.result.configuration.exposure ===
          PollResultExposure.AFTER_POLL_END
        ) {
          setDisabledExposures(new Set([PollResultExposure.ALWAYS]))
        } else if (
          poll.result.configuration.exposure === PollResultExposure.NEVER
        ) {
          setDisabledExposures(new Set([PollResultExposure.ALWAYS]))
        } else {
          setDisabledExposures(new Set())
        }
      } else if (poll.category === PollCategory.ENDED) {
        if (poll.result.configuration.exposure === PollResultExposure.ALWAYS) {
          setDisabledExposures(new Set([PollResultExposure.AFTER_POLL_END]))
        } else if (
          poll.result.configuration.exposure ===
          PollResultExposure.AFTER_POLL_END
        ) {
          setDisabledExposures(new Set([PollResultExposure.ALWAYS]))
        } else if (
          poll.result.configuration.exposure === PollResultExposure.NEVER
        ) {
          setDisabledExposures(new Set([PollResultExposure.ALWAYS]))
        }
      }
    }
  }, [poll, isOpen])

  const handleSave = useCallback((): void => {
    if (poll) {
      const formData = getValues()
      setHasUnsavedChanges(false)
      onSave({
        ...formData,
        id: poll.id,
      })
    }
  }, [poll])

  const handleClose = (reason: 'backdropClick' | 'escapeKeyDown'): void => {
    if (reason !== 'backdropClick') {
      onClose()
    }
  }

  const disableSubmit = useMemo(
    () => !isValid || Object.keys(errors).length > 0 || isSaving,
    [isValid, errors, isSaving],
  )

  if (!poll) {
    return null
  }

  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        handleClose(reason)
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        {formatMessage({
          id: 'poll_edit.header',
        })}
      </DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
        aria-label={formatMessage({
          id: 'general.button.close',
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack minWidth="500px" spacing={1}>
          <SubSubHeader>
            {formatMessage({
              id: 'poll_edit.subheader.result_exposure',
            })}
          </SubSubHeader>
          <Controller
            name="exposure"
            control={control}
            defaultValue={PollResultExposure.ALWAYS}
            render={({ field }) => (
              <Stack direction={'row'} spacing={0.1}>
                <SelectBox
                  spacing={1}
                  alignItems="center"
                  textAlign="center"
                  selected={
                    exposure === PollResultExposure.ALWAYS ? 'selected' : ''
                  }
                  onClick={() => {
                    if (!disabledExposures.has(PollResultExposure.ALWAYS)) {
                      setValue('exposure', PollResultExposure.ALWAYS)
                    }
                  }}
                  disabled={
                    disabledExposures.has(PollResultExposure.ALWAYS)
                      ? 'disabled'
                      : ''
                  }
                >
                  <ExposureAlwaysIcon fontSize="small" />
                  <SubSubHeader>
                    {formatMessage({
                      id: 'poll_edit.label.exposure.always',
                    })}
                  </SubSubHeader>

                  <Typography variant="caption">
                    {formatMessage({
                      id: 'poll_edit.description.exposure.always',
                    })}
                  </Typography>
                </SelectBox>
                <SelectBox
                  spacing={1}
                  alignItems="center"
                  textAlign="center"
                  selected={
                    exposure === PollResultExposure.AFTER_POLL_END
                      ? 'selected'
                      : ''
                  }
                  onClick={() => {
                    if (
                      !disabledExposures.has(PollResultExposure.AFTER_POLL_END)
                    ) {
                      setValue('exposure', PollResultExposure.AFTER_POLL_END)
                    }
                  }}
                  disabled={
                    disabledExposures.has(PollResultExposure.AFTER_POLL_END)
                      ? 'disabled'
                      : ''
                  }
                >
                  <ExposureAfterIcon fontSize="small" />
                  <SubSubHeader>
                    {formatMessage({
                      id: 'poll_edit.label.exposure.after_poll_end',
                    })}
                  </SubSubHeader>

                  <Typography variant="caption">
                    {formatMessage({
                      id: 'poll_edit.description.exposure.after_poll_end',
                    })}
                  </Typography>
                </SelectBox>
                <SelectBox
                  spacing={1}
                  alignItems="center"
                  textAlign="center"
                  selected={
                    exposure === PollResultExposure.NEVER ? 'selected' : ''
                  }
                  onClick={() => {
                    if (!disabledExposures.has(PollResultExposure.NEVER)) {
                      setValue('exposure', PollResultExposure.NEVER)
                    }
                  }}
                  disabled={
                    disabledExposures.has(PollResultExposure.NEVER)
                      ? 'disabled'
                      : ''
                  }
                >
                  <ExposureNeverIcon fontSize="small" />
                  <SubSubHeader>
                    {formatMessage({
                      id: 'poll_edit.label.exposure.never',
                    })}
                  </SubSubHeader>

                  <Typography variant="caption">
                    {formatMessage({
                      id: 'poll_edit.description.exposure.never',
                    })}
                  </Typography>
                </SelectBox>
              </Stack>
            )}
          />

          <FormFieldsWrapper
            sx={{
              display: exposure === PollResultExposure.NEVER ? 'none' : 'block',
            }}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Box flexGrow={1}>
                <Typography variant="body2">
                  {formatMessage({
                    id: 'poll_edit.description.retention_days',
                  })}
                </Typography>
              </Box>
              <FormControl error={!!errors.retentionDays}>
                <Controller
                  name="retentionDays"
                  control={control}
                  rules={{
                    validate: (val): boolean => {
                      if (!!val && !Number.isNaN(Number(val)) && val > 0) {
                        return true
                      }
                      return false
                    },
                  }}
                  defaultValue={1}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required={true}
                      type="number"
                      error={!!errors?.retentionDays}
                      size="small"
                      InputProps={{
                        endAdornment: formatMessage({
                          id: 'portal_item_edit.visibility_period.days',
                        }),
                      }}
                      variant="outlined"
                    />
                  )}
                />
                {!!errors?.retentionDays && (
                  <FormHelperText>
                    {formatMessage({
                      id: 'portal_item_edit.visibility_period.days.error',
                    })}
                  </FormHelperText>
                )}
              </FormControl>
            </Stack>
          </FormFieldsWrapper>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack width="100%" spacing={1}>
          <LoadingButton
            fullWidth
            onClick={handleSave}
            variant="contained"
            disabled={disableSubmit}
            loading={isSaving}
          >
            {formatMessage({ id: 'general.button.save' })}
          </LoadingButton>
          <Button
            fullWidth
            onClick={onClose}
            variant="outlined"
            disabled={disableSubmit}
          >
            {formatMessage({ id: 'general.button.cancel' })}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

export default PollEditResultConfigDialog
