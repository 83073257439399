import { useIntl } from 'react-intl'
import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'

import AppleStoreIcon from 'assets/icons/apple_store.svg'
import GoogleStoreIcon from 'assets/icons/google_store.svg'
import { LightSubHeader } from 'components/StyledComponents'

const DownloadAppLinks: React.FC = () => {
  const { formatMessage } = useIntl()

  return (
    <Stack alignItems="center" spacing={1}>
      <LightSubHeader>
        {formatMessage({ id: 'download_app_links.header' })}
      </LightSubHeader>
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={1}
      >
        <Link
          aria-label={formatMessage({
            id: 'general.icon_button.download_ios_app',
          })}
          href={process.env.REACT_APP_IOS_APP_LINK}
          target="_blank"
        >
          <AppleStoreIcon />
        </Link>
        <Link
          aria-label={formatMessage({
            id: 'general.icon_button.download_android_app',
          })}
          href={process.env.REACT_APP_ANDROID_APP_LINK}
          target="_blank"
        >
          <GoogleStoreIcon />
        </Link>
      </Stack>
    </Stack>
  )
}

export default DownloadAppLinks
