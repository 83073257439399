import { ThemeMode } from 'commonConstants'
import { atom } from 'recoil'

const isMenuOpenState = atom<boolean>({
  key: 'isMenuOpenState',
  default: true,
})

const isMobileViewState = atom<boolean>({
  key: 'isMobileViewState',
  default: false,
})

const themeModeState = atom<ThemeMode>({
  key: 'themeModeState',
  default: ThemeMode.LIGHT,
})

export { isMenuOpenState, isMobileViewState, themeModeState }
