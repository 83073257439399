import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useForm, type SubmitHandler, Controller } from 'react-hook-form'
import { useRecoilValue } from 'recoil'
import { useSnackbar } from 'notistack'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'

import { portalSettingState } from 'state/portalSettingStates'
import { FILE_DESCRIPTION_MAX_LENGTH } from '../../commonConstants'
import MultiLineInput from 'components/form/MultiLineInput'
import { type Resource } from 'types'
import useApi from 'hooks/useApi'
import FileUploader from 'components/form/FileUploader'
import {
  hasNewFileState,
  isFileUploadingState,
  processedFormFilesState,
} from 'state/formStates'
import { FILE_INPUT_ACCEPT_TYPE } from 'utils/fileConstants'
import ResourceWrapper from 'components/resource/ResourceWrapper'
import { SubHeader } from 'components/StyledComponents'
import { getResourceFormat } from 'utils/fileUtils'
import { CaseFileSource } from 'components/case/caseConstants'

type FileEditFormProps = {
  selectedResource?: Resource | null
  onClose: () => void
  onSave: (newAction: Resource) => void
}

type FileFormData = {
  description: string
}

const FORM_NAME = 'file'

const FileEditForm: React.FC<FileEditFormProps> = ({
  selectedResource,
  onClose,
  onSave,
}) => {
  const { formatMessage } = useIntl()
  const { caseId } = useParams()
  const portalSetting = useRecoilValue(portalSettingState)
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<FileFormData>({
    mode: 'onTouched',
  })
  const { sendPostRequest, sendPutRequest } = useApi()
  const [isSaving, setIsSaving] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const isFileUploading = useRecoilValue(isFileUploadingState(FORM_NAME))
  const processedFiles = useRecoilValue(processedFormFilesState(FORM_NAME))
  const hasNewFile = useRecoilValue(hasNewFileState)

  useEffect(() => {
    if (selectedResource?.description) {
      setValue('description', selectedResource.description)
    }
  }, [selectedResource?.description])

  const onSubmit: SubmitHandler<FileFormData> = useCallback(
    async (data): Promise<void> => {
      const link = processedFiles?.[0]?.url

      if (portalSetting && caseId) {
        try {
          setIsSaving(true)
          const { description } = data

          let response = null
          if (selectedResource) {
            response = await sendPutRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                portalSetting.id
              }/cases/${caseId}/resources/${selectedResource.id}`,
              {
                description,
              },
            )
          } else {
            response = await sendPostRequest(
              `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
                portalSetting.id
              }/cases/${caseId}/resources`,
              {
                uri: link,
                name: processedFiles?.[0]?.file.name,
                format: processedFiles?.[0]?.format,
                source: CaseFileSource.CASE_UPLOAD,
                description,
              },
            )
          }

          const responseData = await response.json()

          enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
            variant: 'success',
          })
          onSave(responseData)
        } catch (error) {
          console.error(error)
        } finally {
          setIsSaving(false)
        }
      }
    },
    [portalSetting, caseId, processedFiles, selectedResource],
  )

  const descriptionErrorMessage = useMemo((): string | undefined => {
    if (errors.description?.type === 'maxLength') {
      return formatMessage(
        {
          id: 'general.error.max_length',
        },
        {
          max: FILE_DESCRIPTION_MAX_LENGTH,
        },
      )
    }

    return undefined
  }, [errors.description])

  const shouldDisableSaving = useMemo(
    () =>
      !isValid ||
      Object.keys(errors).length > 0 ||
      isSaving ||
      isFileUploading ||
      (!hasNewFile && !selectedResource),
    [isValid, errors, isSaving, isFileUploading, hasNewFile, selectedResource],
  )

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Stack spacing={2} height={'100%'}>
        <Stack direction="row" width="100%">
          <Box flexGrow={1}>
            <SubHeader>
              {formatMessage({
                id: selectedResource
                  ? 'file_edit_form.header.edit_file'
                  : 'file_edit_form.header.add_file',
              })}
            </SubHeader>
          </Box>

          <Button onClick={onClose} size="small" variant="outlined">
            {formatMessage({ id: 'general.button.cancel' })}
          </Button>
        </Stack>

        <Divider />

        <Stack flexGrow={1} spacing={2} direction="row">
          {!selectedResource && (
            <Box width="150px" height="150px">
              <FileUploader
                formName={FORM_NAME}
                accept={[
                  FILE_INPUT_ACCEPT_TYPE.IMAGE,
                  FILE_INPUT_ACCEPT_TYPE.DOCUMENT,
                  FILE_INPUT_ACCEPT_TYPE.EMAIL,
                ]}
                limit={1}
              />
            </Box>
          )}

          {selectedResource && (
            <ResourceWrapper
              size={150}
              url={selectedResource.uri}
              format={getResourceFormat(selectedResource)}
              imageSize="cover"
            />
          )}

          <Box flexGrow={1}>
            <FormControl error={!!errors?.description} fullWidth>
              <Controller
                name="description"
                control={control}
                rules={{
                  maxLength: FILE_DESCRIPTION_MAX_LENGTH,
                }}
                defaultValue={''}
                render={({ field }) => (
                  <MultiLineInput
                    {...field}
                    error={!!errors?.description}
                    label={formatMessage({
                      id: 'file_edit_form.label.description',
                    })}
                    maxLength={FILE_DESCRIPTION_MAX_LENGTH}
                    rows={5}
                    variant="outlined"
                    fullWidth
                    helpMessage={descriptionErrorMessage}
                  />
                )}
              />
            </FormControl>
          </Box>
        </Stack>

        <Button
          type="submit"
          size="small"
          variant="contained"
          disabled={shouldDisableSaving}
        >
          {formatMessage({ id: 'general.button.save' })}
        </Button>
      </Stack>
    </form>
  )
}

export default FileEditForm
