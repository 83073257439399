import CheckIcon from '@mui/icons-material/Check'
import BuildIcon from '@mui/icons-material/Build'
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt'
import PersonIcon from '@mui/icons-material/Person'
import ErrorIcon from '@mui/icons-material/Error'

import { CaseTaskStatus } from 'components/case/caseConstants'

export enum TaskAssigneeType {
  MEMBER = 'MEMBER',
  TEAM = 'TEAM',
  SUPPLIER = 'SUPPLIER',
}

const getCaseTaskStatusColor = (status: CaseTaskStatus): string => {
  switch (status) {
    case CaseTaskStatus.UNASSIGNED:
      return '#FF4869'
    case CaseTaskStatus.ASSIGNED:
      return '#1664EF'
    case CaseTaskStatus.IN_PROGRESS:
      return '#FFD850'
    case CaseTaskStatus.DONE:
      return '#7ADCD0'
    case CaseTaskStatus.CANCELLED:
      return '#E2E2E2'
    default:
      return 'white'
  }
}

const getCaseTaskStatusIcon = (
  status: CaseTaskStatus,
): typeof ErrorIcon | null => {
  switch (status) {
    case CaseTaskStatus.UNASSIGNED:
      return ErrorIcon
    case CaseTaskStatus.ASSIGNED:
      return PersonIcon
    case CaseTaskStatus.IN_PROGRESS:
      return BuildIcon
    case CaseTaskStatus.DONE:
      return CheckIcon
    case CaseTaskStatus.CANCELLED:
      return DoDisturbAltIcon
    default:
      return null
  }
}

export { getCaseTaskStatusColor, getCaseTaskStatusIcon }
