import { useCallback, useState, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSnackbar } from 'notistack'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useForm, type SubmitHandler, Controller } from 'react-hook-form'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import Divider from '@mui/material/Divider'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import WorkIcon from '@mui/icons-material/Work'
import GroupsIcon from '@mui/icons-material/Groups'
import FolderCopyIcon from '@mui/icons-material/FolderCopy'
import WorkspacesIcon from '@mui/icons-material/Workspaces'

import {
  BackButtonGrey,
  LightSubHeader,
  MainHeader,
  SettingWrapper,
} from 'components/StyledComponents'
import useRoute from 'hooks/useNavigate'
import {
  hasUnsavedChangesState,
  isFileUploadingState,
  processedFormFilesState,
} from 'state/formStates'
import { portalSettingState } from 'state/portalSettingStates'
import {
  EMAIL_MAX_LENGTH,
  Path,
  PHONE_MAX_LENGTH,
  SHORT_TEXT_MAX_LENGTH,
  USER_NAME_MAX_LENGTH,
} from '../commonConstants'
import useApi from 'hooks/useApi'
import { isValidEmail, isValidPhoneNumber } from 'utils/stringUtils'
import { type TeamBasic } from 'types'
import ImageUploader from 'components/form/ImageUploader'
import usePortalSetting from 'hooks/usePortalSetting'
import { type RoleBasic } from 'components/role/roleTypes'
import { type ItemBasic } from 'components/item/itemTypes'

type MemberFormData = {
  firstName: string
  lastName: string
  email: string
  avatarUrl?: string
  phone?: string
  jobTitle?: string
  roles: string[]
  items: string[]
  teams: string[]
}

const FORM_NAME = 'addMemberForm'

const MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD = 300
const MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD = 300

const MemberAddPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { goTo } = useRoute()
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    getValues,
  } = useForm<MemberFormData>({
    mode: 'onTouched',
  })

  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const portalSetting = useRecoilValue(portalSettingState)
  const isFileUploading = useRecoilValue(isFileUploadingState(FORM_NAME))
  const processedFormFiles = useRecoilValue(processedFormFilesState(FORM_NAME))
  const { sendPostRequest } = useApi()
  const [rolesData, setRolesData] = useState<RoleBasic[]>([])
  const [itemsData, setItemsData] = useState<ItemBasic[]>([])
  const [teamsData, setTeamsData] = useState<TeamBasic[]>([])
  const { getLocalizedContent, formatPhoneNumber } = usePortalSetting()

  const onSubmit: SubmitHandler<MemberFormData> = useCallback(
    async (data): Promise<void> => {
      if (portalSetting) {
        try {
          setIsSaving(true)
          let resultMessage = 'general.text.changes_saved'
          const formData = {
            ...data,
            accessPortal: true,
          }

          if (data.phone) {
            formData.phone = formatPhoneNumber(data.phone)
          }

          const verifyResponse = await sendPostRequest(
            `${process.env.REACT_APP_VIDEO_CALL_API_PATH ?? ''}/verifyUser/`,
            formData,
          )

          const verifyData = await verifyResponse?.json()

          if (processedFormFiles.length) {
            formData.avatarUrl = processedFormFiles[0].url
          }

          if (data.phone) {
            formData.phone = formatPhoneNumber(data.phone)
          }

          await sendPostRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting.id
            }/members`,
            formData,
          )

          if (verifyData.action === 'resetPasswordEmail') {
            const auth = getAuth()
            auth.languageCode = portalSetting.defaultLanguage.toLowerCase()
            await sendPasswordResetEmail(auth, data.email)
            resultMessage =
              'member_edit.text.member_created_with_reset_password_email'
          }

          enqueueSnackbar(
            formatMessage({
              id: resultMessage,
            }),
            {
              variant: 'success',
            },
          )
          goTo(Path.MEMBERS_LIST)
        } catch (error) {
          console.error(error)
        } finally {
          setIsSaving(false)
          setHasUnsavedChanges(false)
        }
      }
    },
    [portalSetting],
  )

  const handleGoBack = (): void => {
    goTo(Path.MEMBERS_LIST)
  }

  const hasUnsavedChanges = useMemo(() => isDirty, [isDirty])

  const shouldDisableSaving = useMemo(
    () =>
      !isValid || Object.keys(errors).length > 0 || isSaving || isFileUploading,
    [isValid, errors, isSaving, isFileUploading],
  )

  useEffect(() => {
    setHasUnsavedChanges(hasUnsavedChanges)
  }, [hasUnsavedChanges])

  const getRoles = useCallback(async (): Promise<void> => {
    try {
      if (portalSetting) {
        const response = await sendPostRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/members:listAssignableRoles`,
        )
        const data = await response.json()
        setRolesData(data)
      }
    } catch (error) {
      console.error(error)
    }
  }, [portalSetting])

  const getItems = useCallback(async (): Promise<void> => {
    try {
      if (portalSetting) {
        const response = await sendPostRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/members:listAssignableItems`,
        )
        const data = await response.json()
        setItemsData(data)
      }
    } catch (error) {
      console.error(error)
    }
  }, [portalSetting])

  const getTeams = useCallback(async (): Promise<void> => {
    try {
      if (portalSetting) {
        const response = await sendPostRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/members:listAssignableTeams`,
        )
        const data = await response.json()
        setTeamsData(data)
      }
    } catch (error) {
      console.error(error)
    }
  }, [portalSetting])

  useEffect(() => {
    if (portalSetting) {
      void getRoles()
      void getItems()
      void getTeams()
    }
  }, [portalSetting])

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Stack height={'100%'} width={'100%'}>
          <Stack direction="row" width="100%" spacing={2} marginBottom={2}>
            <Stack flexGrow={1} direction="row" spacing={1}>
              <BackButtonGrey
                onClick={handleGoBack}
                size="small"
                aria-label={formatMessage({
                  id: 'general.icon_button.go_back',
                })}
              >
                <ArrowBackIcon />
              </BackButtonGrey>

              <MainHeader>
                {formatMessage({
                  id: 'member_add.header',
                })}
              </MainHeader>
            </Stack>

            <LoadingButton
              variant="contained"
              size="small"
              type="submit"
              disabled={shouldDisableSaving}
              loading={isSaving}
              color="secondary"
            >
              {formatMessage({ id: 'general.button.save' })}
            </LoadingButton>
          </Stack>

          <SettingWrapper width="100%" flexGrow={1}>
            <Stack paddingY={2} paddingX={4} spacing={2}>
              <LightSubHeader>
                {formatMessage({
                  id: 'member_edit.subheader.personal_information',
                })}
              </LightSubHeader>

              <Stack direction="row" spacing={2} width="100%">
                <Box width="220px" height="220px">
                  <ImageUploader
                    formName={FORM_NAME}
                    maxWidth={MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD}
                    maxHeight={MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD}
                  />
                </Box>

                <Stack spacing={2} flexGrow={1}>
                  <FormControl error={!!errors.firstName} fullWidth>
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{
                        required: true,
                        maxLength: USER_NAME_MAX_LENGTH,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.firstName}
                          required
                          autoFocus
                          size="small"
                          label={formatMessage({
                            id: 'member_edit.label.first_name',
                          })}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl error={!!errors.lastName} fullWidth>
                    <Controller
                      name="lastName"
                      control={control}
                      rules={{
                        required: true,
                        maxLength: USER_NAME_MAX_LENGTH,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.lastName}
                          required
                          size="small"
                          label={formatMessage({
                            id: 'customer_edit.label.last_name',
                          })}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>

                  <FormControl error={!!errors.email}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        maxLength: EMAIL_MAX_LENGTH,
                        required: true,
                        validate: (val): boolean => {
                          if (!val) {
                            return true
                          }

                          return isValidEmail(val)
                        },
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          size="small"
                          label={formatMessage({
                            id: 'member_edit.label.email',
                          })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon fontSize="small" color="primary" />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />

                    {errors.email?.type === 'validate' && (
                      <FormHelperText>
                        {formatMessage({
                          id: 'general.error.validate_email',
                        })}
                      </FormHelperText>
                    )}
                    {errors.email?.type === 'maxLength' && (
                      <FormHelperText>
                        {formatMessage(
                          {
                            id: 'general.error.max_length_email',
                          },
                          { max: EMAIL_MAX_LENGTH },
                        )}
                      </FormHelperText>
                    )}
                    {errors.email?.type === 'required' && (
                      <FormHelperText>
                        {formatMessage({
                          id: 'general.error.required',
                        })}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl error={!!errors.phone}>
                    <Controller
                      name="phone"
                      control={control}
                      rules={{
                        maxLength: PHONE_MAX_LENGTH,
                        validate: (val): boolean => {
                          if (!val) {
                            return true
                          }

                          return isValidPhoneNumber(val)
                        },
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          label={formatMessage({
                            id: 'member_edit.label.phone',
                          })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PhoneIcon fontSize="small" color="primary" />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />

                    {errors.phone?.type === 'validate' && (
                      <FormHelperText>
                        {formatMessage({
                          id: 'general.error.validate_phone',
                        })}
                      </FormHelperText>
                    )}
                    {errors.phone?.type === 'maxLength' && (
                      <FormHelperText>
                        {formatMessage(
                          {
                            id: 'general.error.max_length_phone',
                          },
                          { max: PHONE_MAX_LENGTH },
                        )}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl error={!!errors.jobTitle}>
                    <Controller
                      name="jobTitle"
                      control={control}
                      rules={{
                        maxLength: SHORT_TEXT_MAX_LENGTH,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          label={formatMessage({
                            id: 'member_edit.label.job_title',
                          })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <WorkIcon fontSize="small" color="primary" />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />

                    {errors.jobTitle?.type === 'maxLength' && (
                      <FormHelperText>
                        {formatMessage(
                          {
                            id: 'general.error.max_length',
                          },
                          { max: SHORT_TEXT_MAX_LENGTH },
                        )}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>

            <Divider />

            <Stack paddingY={2} paddingX={4} spacing={2}>
              <LightSubHeader>
                {formatMessage({
                  id: 'member_edit.subheader.access_preferences',
                })}
              </LightSubHeader>

              <Stack spacing={2} flexGrow={1}>
                <Stack width="100%" spacing={2}>
                  <FormControl error={!!errors.roles} fullWidth>
                    <InputLabel id="roles-label" size="small" required>
                      {formatMessage({
                        id: 'member_edit.label.roles',
                      })}
                    </InputLabel>
                    <Controller
                      name="roles"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          required
                          multiple
                          labelId="roles-label"
                          startAdornment={
                            <InputAdornment position="start">
                              <GroupsIcon fontSize="small" color="primary" />
                            </InputAdornment>
                          }
                          input={
                            <OutlinedInput
                              label={formatMessage({
                                id: 'member_edit.label.roles',
                              })}
                            />
                          }
                          size="small"
                          fullWidth
                          variant="outlined"
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => {
                                const selectedRole = rolesData?.find(
                                  (roleData) => roleData.id === value,
                                )

                                return (
                                  <Chip
                                    key={value}
                                    size="small"
                                    label={selectedRole?.name}
                                  />
                                )
                              })}
                            </Box>
                          )}
                        >
                          {rolesData?.map((roleData) => (
                            <MenuItem key={roleData.id} value={roleData.id}>
                              <Checkbox
                                checked={getValues('roles')?.includes(
                                  roleData.id,
                                )}
                              />
                              <ListItemText primary={roleData.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>

                  <FormControl error={!!errors.items} fullWidth>
                    <InputLabel id="items-label" size="small">
                      {formatMessage({
                        id: 'member_edit.label.items',
                      })}
                    </InputLabel>
                    <Controller
                      name="items"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          multiple
                          labelId="items-label"
                          startAdornment={
                            <InputAdornment position="start">
                              <FolderCopyIcon
                                fontSize="small"
                                color="primary"
                              />
                            </InputAdornment>
                          }
                          input={
                            <OutlinedInput
                              label={formatMessage({
                                id: 'member_edit.label.items',
                              })}
                            />
                          }
                          size="small"
                          fullWidth
                          variant="outlined"
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => {
                                const selectedItem = itemsData?.find(
                                  (itemData) => itemData.id === value,
                                )

                                return (
                                  <Chip
                                    key={value}
                                    size="small"
                                    label={getLocalizedContent(
                                      selectedItem?.names,
                                    )}
                                  />
                                )
                              })}
                            </Box>
                          )}
                        >
                          {itemsData?.map((itemData) => (
                            <MenuItem key={itemData.id} value={itemData.id}>
                              <Checkbox
                                checked={getValues('items')?.includes(
                                  itemData.id,
                                )}
                              />
                              <ListItemText
                                primary={getLocalizedContent(itemData.names)}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="items-label" size="small">
                      {formatMessage({
                        id: 'member_edit.label.teams',
                      })}
                    </InputLabel>
                    <Controller
                      name="teams"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Select
                          {...field}
                          multiple
                          labelId="teams-label"
                          startAdornment={
                            <InputAdornment position="start">
                              <WorkspacesIcon
                                fontSize="small"
                                color="primary"
                              />
                            </InputAdornment>
                          }
                          input={
                            <OutlinedInput
                              label={formatMessage({
                                id: 'member_edit.label.teams',
                              })}
                            />
                          }
                          size="small"
                          fullWidth
                          variant="outlined"
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => {
                                const selectedTeam = teamsData?.find(
                                  (teamData) => teamData.id === value,
                                )

                                return (
                                  <Chip
                                    key={value}
                                    size="small"
                                    label={selectedTeam?.name}
                                  />
                                )
                              })}
                            </Box>
                          )}
                        >
                          {teamsData?.map((teamData) => (
                            <MenuItem key={teamData.id} value={teamData.id}>
                              <Checkbox
                                checked={getValues('teams')?.includes(
                                  teamData.id,
                                )}
                              />
                              <ListItemText primary={teamData.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
          </SettingWrapper>
        </Stack>
      </form>
    </>
  )
}

export default MemberAddPage
