export const ERROR_CODE_MESSAGE: Record<string, string> = {
  'PCE-01': 'error.add_case.portal_not_support_public_case',
  'PCE-02': 'general.error.unknown_error',
  'PCE-03': 'error.empty_result',
  'PCE-04': 'general.error.unknown_error',
  'ATM-01': 'error.add_team.team_name_existed',
  'ATM-02': 'error.update_team.already_active',
  'ATM-03': 'error.update_team.already_inactive',
  'ATM-04': 'general.error.unknown_error',
  'ATM-05': 'general.error.unknown_error',
  'E-1001': 'error.default_language_missing_from_support',
  'E-2001': 'error.update_item.item_name_existed',
  'E-2002': 'error.update_item.item_name_translations_missing',
  'E-2003': 'general.error.unknown_error',
  'E-2004': 'general.error.unknown_error',
  'E-2005': 'general.error.unknown_error',
  'E-2006': 'general.error.unknown_error',
  'E-2800': 'error.update_contact.duplicated_name',
  'E-2801': 'error.update_contact.duplicated_email',
  'E-2903': 'alert_edit.error.duplicated_item',
  'E-3001': 'error.add_customer.duplicate_email',
  'E-3002': 'general.error.unknown_error',
  'E-3003': 'general.error.unknown_error',
  'E-3004': 'error.update_customer.already_active',
  'E-3005': 'error.update_customer.already_inactive',
  'E-4001': 'error.add_member.duplicate_email',
  'E-4002': 'general.error.unknown_error',
  'E-4003': 'error.update_member.already_active',
  'E-4004': 'error.update_member.already_inactive',
  'E-4005': 'error.update_member.missing_roles',
  'E-4006': 'general.error.unknown_error',
  'E-4007': 'general.error.unknown_error',
  'E-4008': 'error.update_member.missing_roles',
  'E-4009': 'general.error.unknown_error',
  'E-4010': 'general.error.unknown_error',
  'E-6001': 'error.add_case.missing_reporter',
  'E-6002': 'general.error.unknown_error',
  'E-7001': 'general.error.unknown_error',
  'E-7002': 'general.error.unknown_error',
  'E-7003': 'general.error.unknown_error',
  'E-7005': 'general.error.unknown_error',
  'E-7006': 'error.update_case.cannot_update_private',
  'E-7007': 'error.update_case.already_shared',
  'E-7010': 'error.no_member_found',
  'E-7904': 'error.forward_case.invalid_status',
  'E-7905': 'error.no_member_found',
  'E-7906': 'error.forward_case.open_tasks',
  'E-8001': 'general.error.unknown_error',
  'E-8002': 'general.error.unknown_error',
  'E-8003': 'error.update_task.already_assigned',
  'E-8004': 'general.error.unknown_error',
  'E-8005': 'general.error.unknown_error',
  'E-8006': 'general.error.unknown_error',
  'E-8007': 'error.update_task.selected_member_inactive',
  'E-8008': 'error.update_task.selected_member_not_allowed',
  'E-8009': 'error.update_task.selected_member_not_allowed',
  'E-8010': 'general.error.unknown_error',
  'E-8011': 'error.update_task.already_claimed',
  'E-8012': 'error.update_task.already_unclaimed',
  'E-8013': 'error.update_task.cannot_unclaimed',
  'E-9001': 'error.add_role.name_existed',
  'E-9002': 'error.add_role.missing_default',
  'E-9003': 'general.error.unknown_error',
  'E-9004': 'general.error.unknown_error',
  'E-9005': 'general.error.unknown_error',
  'E-9006': 'general.error.unknown_error',
  'E-9007': 'error.update_member.already_assigned_role',
  'E-9008': 'error.update_member.unassign_empty_roles',
  'E-9009': 'error.update_role.cannot_delete_default_role',
  'E-9010': 'error.update_role.cannot_delete_assigned_role',
  'E-15000': 'general.error.unknown_error',
  'E-15001': 'error.add_user.email_existed',
  'E-15002': 'error.add_user.email_required',
  'E-15003': 'error.add_user.nick_name_required',
  'E-15004': 'error.add_user.nick_name_required',
  'E-16000': 'general.error.unknown_error',
  'E-16001': 'general.error.unknown_error',
  'E-17001': 'general.error.unknown_error',
  'E-17002': 'general.error.unknown_error',
  'E-17003': 'general.error.unknown_error',
  'E-17004': 'general.error.unknown_error',
  'E-17005': 'general.error.unknown_error',
  'E-17006': 'general.error.unknown_error',
  'E-17007': 'error.item.already_mark_favorite',
  'E-17008': 'error.item.already_mark_not_favorite',
  'E-18001': 'general.error.unknown_error',
  'E-18002': 'general.error.unknown_error',
  'E-19001': 'general.error.unknown_error',
  'E-19002': 'general.error.unknown_error',
}
