import { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Popover, { type PopoverActions } from '@mui/material/Popover'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import MuiAccordion, { type AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, {
  type AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'

import { type CommentTemplate } from 'components/commentTemplate/commentTemplateTypes'
import usePortalSetting from 'hooks/usePortalSetting'

type TemplateSelectDialogProps = {
  isOpen: boolean
  anchorEl: HTMLElement | null
  itemId: string
  onSelectTemplate: (content: string) => void
  onClose: () => void
  commentTemplates: CommentTemplate[]
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.info.light,
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const TemplateWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(2)};
  cursor: pointer;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.divider};
  box-shadow: none;
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  white-space: pre-line;

  :hover {
    border: 1.5px solid;
    border-color: ${({ theme }) => theme.palette.primary.main};
    box-shadow: 0px 0px 0px 3px #bbc6f1;
  }
`

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

const TemplateSelectDialog: React.FC<TemplateSelectDialogProps> = ({
  isOpen,
  anchorEl,
  itemId,
  onSelectTemplate,
  onClose,
  commentTemplates,
}) => {
  const { getLocalizedContent } = usePortalSetting()
  const popoverActionRef = useRef<PopoverActions | null>(null)
  const [expanded, setExpanded] = useState<string | boolean>(false)

  useEffect(() => {
    if (!isOpen) {
      setExpanded(false)
    }
  }, [isOpen])

  useEffect(() => {
    setTimeout((): void => {
      if (popoverActionRef.current) {
        popoverActionRef.current.updatePosition()
      }
    }, 1)
  }, [expanded])

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      action={popoverActionRef}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Stack width={500}>
        {commentTemplates.map((template) => (
          <Accordion
            key={template.id}
            expanded={expanded === template.id}
            onChange={handleChange(template.id)}
            slotProps={{ transition: { timeout: 0 } }}
          >
            <AccordionSummary>
              <Stack
                direction="row"
                spacing={1}
                width="100%"
                alignItems="center"
              >
                <Typography flexGrow={1}>
                  {getLocalizedContent(template.names)}
                </Typography>
                <Stack direction="row" spacing={1}>
                  {template.templates.map((template) => (
                    <Button
                      key={`language-${template.language}`}
                      size="small"
                      variant="outlined"
                      endIcon={<ArrowForwardIcon />}
                      onClick={(e): void => {
                        e.stopPropagation()
                        onSelectTemplate(template.content)
                      }}
                    >
                      {template.language}
                    </Button>
                  ))}
                </Stack>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={2}>
                {template.templates.map((template) => (
                  <TemplateWrapper
                    key={template.language}
                    onClick={(): void => {
                      onSelectTemplate(template.content)
                    }}
                  >
                    <Chip label={template.language} color="info" size="small" />
                    <Typography>{template.content}</Typography>
                  </TemplateWrapper>
                ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Popover>
  )
}

export default TemplateSelectDialog
