import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import { useRecoilValue } from 'recoil'
import AddIcon from '@mui/icons-material/Add'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import CircularProgress from '@mui/material/CircularProgress'

import { MainHeader, SettingWrapper } from 'components/StyledComponents'
import { type Role } from 'components/role/roleTypes'
import { Path } from '../commonConstants'
import MemberRoleDetail from 'components/role/RoleDetail'
import useRoute from 'hooks/useNavigate'
import RoleList from 'components/role/RoleList'
import ErrorIcon from 'assets/icons/error_icon.svg'
import { portalSettingState } from 'state/portalSettingStates'
import useApi from 'hooks/useApi'
import { useSnackbar } from 'notistack'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import useMember from 'hooks/useMember'

const MemberRoleListPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { selectedIndex } = useParams()
  const index = selectedIndex ? parseInt(selectedIndex, 10) : 0
  const { goTo } = useRoute()
  const [selectedRole, setSelectedRole] = useState<Role | null>(null)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const { checkAccesses } = useMember()
  const portalSetting = useRecoilValue(portalSettingState)
  const { sendDeleteRequest } = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const {
    data: rolesData,
    isLoading,
    mutate: mutateRoles,
  } = useSWR<Role[]>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/roles`
      : null,
  )

  const sortedRoles = useMemo(
    () =>
      rolesData?.sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }

        return 0
      }),
    [rolesData],
  )

  useEffect(() => {
    if (!!sortedRoles?.length && index >= 0 && index < sortedRoles.length) {
      setSelectedRole(sortedRoles[index])
    } else if (index < 0 || index >= (sortedRoles?.length ?? 0)) {
      setSelectedRole(null)
    }
  }, [index, sortedRoles])

  const handleRoleClick = (index: number): void => {
    goTo(`${Path.ROLES_LIST}/${index}`)
  }

  const handleAdd = (): void => {
    goTo(`${Path.ROLES_EDIT}/-1`)
  }

  const handleEdit = (): void => {
    goTo(`${Path.ROLES_EDIT}/${index}`)
  }

  const handleDelete = (): void => {
    setIsConfirmDialogOpen(true)
  }

  const handleCopy = (): void => {
    goTo(`${Path.ROLES_EDIT}/${index}?isCopy=true`)
  }

  const handleCloseConfirmDialog = (): void => {
    setIsConfirmDialogOpen(false)
  }

  const handleConfirmedDeleteRole = useCallback(async (): Promise<void> => {
    try {
      if (portalSetting && selectedRole) {
        await sendDeleteRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/roles/${selectedRole.id}`,
        )

        enqueueSnackbar(
          formatMessage({
            id: 'general.text.changes_saved',
          }),
          {
            variant: 'success',
          },
        )
        await mutateRoles()
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsConfirmDialogOpen(false)
    }
  }, [portalSetting, selectedRole])

  return (
    <Stack height="100%" width="100%" spacing={2} overflow="auto">
      <Stack direction="row" width="100%" spacing={2}>
        <Box flexGrow={1}>
          <MainHeader>
            {formatMessage({ id: 'member_role_list.header' })}
          </MainHeader>
        </Box>

        {checkAccesses({ [PortalSection.ROLES]: [FeatureAccess.WRITE] }) && (
          <Button
            variant="contained"
            size="small"
            onClick={handleAdd}
            startIcon={<AddIcon />}
            color="secondary"
          >
            {formatMessage({ id: 'member_role_list.button.add_role' })}
          </Button>
        )}
      </Stack>

      <Stack flexGrow={1}>
        {isLoading && <CircularProgress />}

        {!!sortedRoles?.length && (
          <SettingWrapper width="100%" flexGrow={1}>
            <Stack
              width={'100%'}
              direction={'row'}
              flexGrow={1}
              height={'100%'}
              overflow="auto"
            >
              <RoleList
                roles={sortedRoles}
                selectedRole={selectedRole}
                handleRoleClick={handleRoleClick}
              />

              <Stack width={'100%'} direction={'row'}>
                {selectedRole && (
                  <MemberRoleDetail
                    role={selectedRole}
                    onCopy={handleCopy}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                  />
                )}
              </Stack>
            </Stack>
          </SettingWrapper>
        )}
      </Stack>

      <Dialog open={isConfirmDialogOpen} maxWidth="xs">
        <DialogTitle textAlign="center">
          <Stack alignItems="center" spacing={2}>
            <ErrorIcon />
            <Box textAlign="center">
              {formatMessage({ id: 'member_role_detail.delete_confirm.title' })}
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText textAlign="center">
            {formatMessage({ id: 'member_role_detail.delete_confirm.message' })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Stack spacing={1} width="100%">
            <Button
              onClick={(): void => {
                void handleConfirmedDeleteRole()
              }}
              variant="contained"
              fullWidth
              color="secondary"
            >
              {formatMessage({
                id: 'member_role_detail.delete_confirm.button.delete_role',
              })}
            </Button>
            <Button
              onClick={handleCloseConfirmDialog}
              variant="outlined"
              fullWidth
              color="secondary"
              autoFocus
            >
              {formatMessage({
                id: 'general.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default MemberRoleListPage
