import { useIntl } from 'react-intl'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import {
  CASE_STATUS_LABEL,
  CaseStatus,
  type VirtualPortalCaseStatus,
} from './caseConstants'
import {
  getCaseStatusColor,
  getCaseStatusIcon,
} from 'components/case/caseUtils'

type CaseStatusBadgeProps = {
  status: CaseStatus | VirtualPortalCaseStatus
}

const Wrapper = styled(Stack)<{
  status: CaseStatus | VirtualPortalCaseStatus
}>`
  background: ${({ status }) => getCaseStatusColor(status)};

  color: ${({ status, theme }) =>
    status === CaseStatus.NEW ||
    status === CaseStatus.FORWARDED ||
    status === CaseStatus.NOT_FORWARDED
      ? theme.palette.common.white
      : theme.palette.common.black};
  border: none;

  & svg {
    color: ${({ status, theme }) =>
      status === CaseStatus.NEW ||
      status === CaseStatus.FORWARDED ||
      status === CaseStatus.NOT_FORWARDED
        ? theme.palette.common.white
        : theme.palette.common.black};
    font-size: 16px;
  }
  align-items: center;
  border-bottom-left-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border-bottom-right-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  font-size: 12px;
  min-width: 110px;
  font-weight: 500;
`

const CaseStatusBadge: React.FC<CaseStatusBadgeProps> = ({ status }) => {
  const { formatMessage } = useIntl()
  const CaseStatusIcon = getCaseStatusIcon(status)

  return (
    <Wrapper
      status={status}
      direction="row"
      spacing={1}
      paddingX={1}
      paddingY={1}
    >
      {CaseStatusIcon && <CaseStatusIcon />}
      <Box>
        {formatMessage({
          id: CASE_STATUS_LABEL[status],
        })}
      </Box>
    </Wrapper>
  )
}

export default CaseStatusBadge
