import { useCallback, useContext, useMemo } from 'react'
import { useSetRecoilState } from 'recoil'

import AuthContext from 'context/AuthContext'
import { networkErrorState } from 'state/errorStates'
import useRoute from 'hooks/useNavigate'
import { Path } from '../commonConstants'

type SendGetRequest = (url: string) => Promise<Response | null>
type SendPostRequest = (url: string, data?: object) => Promise<Response>
type SendPutRequest = (url: string, data: object) => Promise<Response>
type SendDeleteRequest = (url: string) => Promise<Response | null>
type SendUploadFileRequest = (url: string, file: File) => Promise<Response>

export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

type UseApiReturn = {
  sendPostRequest: SendPostRequest
  sendPutRequest: SendPutRequest
  sendDeleteRequest: SendDeleteRequest
  sendGetRequest: SendGetRequest
  sendUploadFileRequest: SendUploadFileRequest
}

const useApi = (): UseApiReturn => {
  const { authData } = useContext(AuthContext)
  const setNetworkError = useSetRecoilState(networkErrorState)
  const { goTo } = useRoute()

  const sendRequest = useCallback(
    async ({
      method,
      url,
      data,
      file,
    }: {
      method: RequestMethod
      url: string
      data?: object
      file?: File
    }): Promise<Response> => {
      const token = await authData?.getIdToken()
      const headers = new Headers()

      if (token) {
        headers.append('Authorization', `Bearer ${token}`)
      }

      if ((method === RequestMethod.POST || RequestMethod.PUT) && data) {
        headers.append('Content-Type', 'application/json')
      } else if (file) {
        headers.append('Content-Type', 'multipart/form-data')
      }

      const options: RequestInit = {
        method,
        headers,
      }

      if (data) {
        options.body = JSON.stringify(data)
      }

      if (file) {
        const formData = new FormData()
        formData.append('file', file)
        options.body = formData
      }

      const response = await fetch(url, options)

      if (response.status === 401) {
        goTo(Path.UNAUTHORIZED)
        return await Promise.reject(new Error('UNAUTHORIZED'))
      } else if (!response.status.toString().startsWith('2')) {
        const errorMessage = await response?.text()
        setNetworkError(errorMessage)
        return await Promise.reject(new Error(errorMessage))
      }

      return response
    },
    [authData],
  )

  const sendGetRequest = async (url: string): Promise<Response> =>
    await sendRequest({ method: RequestMethod.GET, url })

  const sendPostRequest = async (
    url: string,
    data?: object,
  ): Promise<Response> =>
    await sendRequest({ method: RequestMethod.POST, url, data })

  const sendPutRequest = async (url: string, data: object): Promise<Response> =>
    await sendRequest({ method: RequestMethod.PUT, url, data })

  const sendDeleteRequest = async (url: string): Promise<Response> =>
    await sendRequest({ method: RequestMethod.DELETE, url })

  const sendUploadFileRequest = async (
    url: string,
    file: File,
  ): Promise<Response> =>
    await sendRequest({ method: RequestMethod.POST, url, file })

  const memoizedApiValue = useMemo(
    () => ({
      sendGetRequest,
      sendPostRequest,
      sendPutRequest,
      sendDeleteRequest,
      sendUploadFileRequest,
    }),
    [
      sendGetRequest,
      sendPostRequest,
      sendPutRequest,
      sendDeleteRequest,
      sendUploadFileRequest,
    ],
  )

  return memoizedApiValue
}

export default useApi
