import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useSnackbar } from 'notistack'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Typography from '@mui/material/Typography'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Tooltip from '@mui/material/Tooltip'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import ExpiryIcon from '@mui/icons-material/HourglassTop'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import CopyIcon from '@mui/icons-material/ContentCopy'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ViewsCountIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import InfoIcon from '@mui/icons-material/InfoOutlined'

import { InfoText, MainHeader, SubHeader } from 'components/StyledComponents'
import EmptyAlertIcon from 'assets/icons/empty_alert.svg'
import { portalSettingState } from 'state/portalSettingStates'
import { convertDataToLocalizedString, shortenUuid } from 'utils/stringUtils'
import useApi from 'hooks/useApi'
import { AlertCategory } from 'components/alert/alertConstants'
import usePortalSetting from 'hooks/usePortalSetting'
import { hasUnsavedChangesState } from 'state/formStates'
import AlertEditDialog from 'components/alert/AlertEditDialog'
import {
  type Alert,
  type AlertFormData,
  type NewAlert,
  type AlertExpiryFormData,
} from 'components/alert/alertTypes'
import AlertAddDialog from 'components/alert/AlertAddDialog'
import AlertEditExpiryDialog from 'components/alert/AlertEditExpiryDialog'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import useMember from 'hooks/useMember'

const Wrapper = styled(Stack)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  position: relative;
`

const ViewTabsWrapper = styled(Stack)`
  background: ${({ theme }) => theme.palette.info.light};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;

  & .MuiButton-endIcon .counter {
    padding: 0px 8px;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    background: ${({ theme }) => theme.palette.primary.light};
    font-size: 12px;
    color: ${({ theme }) => theme.palette.text.primary};
  }

  & .MuiButton-text {
    & .counter {
      background: ${({ theme }) => theme.palette.primary.light};
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }
`

const ItemText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  white-space: pre-line;
`

const StatusWrapper = styled(Box)`
  padding: 8px 20px;
  border-top-left-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border-bottom-right-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
`

const ActiveStatus = styled(StatusWrapper)`
  background: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.secondary.contrastText};
`

const DraftStatus = styled(StatusWrapper)`
  background: ${({ theme }) => theme.palette.primary.light};
  color: ${({ theme }) => theme.palette.text.primary};
`

const InactiveStatus = styled(StatusWrapper)`
  background: ${({ theme }) => theme.palette.background.default};
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  border-right: 1px solid ${({ theme }) => theme.palette.divider};
`

const AlertListPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const portalSetting = useRecoilValue(portalSettingState)
  const {
    data: alertsData,
    isLoading,
    mutate: mutateAlerts,
  } = useSWR<Alert[]>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/alerts`
      : null,
  )
  const { sendPostRequest, sendPutRequest } = useApi()
  const [hasUnsavedChanges, setHasUnsavedChanges] = useRecoilState(
    hasUnsavedChangesState,
  )
  const { getLocalizedContent, formatDate } = usePortalSetting()
  const { checkAccesses } = useMember()
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [isEditExpiryDialogOpen, setIsEditExpiryDialogOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [currentView, setCurrentView] = useState<AlertCategory | 'logs'>(
    AlertCategory.ACTIVE,
  )
  const [isUnsavedConfirmDialogOpen, setIsUnsavedConfirmDialogOpen] =
    useState(false)
  const [isActiveConfirmDialogOpen, setIsActiveConfirmDialogOpen] =
    useState(false)
  const [isInactiveConfirmDialogOpen, setIsInactiveConfirmDialogOpen] =
    useState(false)
  const [selectedAlert, setSelectedAlert] = useState<Alert | null>(null)
  const [openAlertsTranslation, setOpenAlertsTranslation] = useState<
    Set<string>
  >(new Set())
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(menuEl)

  const handleAddDialogOpen = (): void => {
    setSelectedAlert(null)
    setIsAddDialogOpen(true)
  }

  const addOrUpdateAlert = useCallback(
    async (data: AlertFormData, isActive: boolean): Promise<void> => {
      if (!portalSetting) {
        return
      }

      try {
        setIsSaving(true)
        const formData: NewAlert = {
          itemId: data.itemId,
          texts: convertDataToLocalizedString(data.translations),
          active: isActive,
        }

        if (data.expiry) {
          formData.expiry = data.expiry.format()
        }

        if (data.id) {
          await sendPutRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting?.id
            }/alerts/${data.id}`,
            formData,
          )

          await mutateAlerts()
        } else {
          const response = await sendPostRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting?.id
            }/alerts`,
            formData,
          )

          const alertData = await response?.json()
          await mutateAlerts([...(alertsData ?? []), alertData])
        }

        enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
          variant: 'success',
        })
      } catch (error) {
        console.error(error)
      } finally {
        setIsSaving(false)
        handleAddDialogClose()
        handleEditDialogClose()
      }
    },
    [portalSetting, alertsData],
  )

  const handleAddDialogClose = useCallback((): void => {
    if (hasUnsavedChanges) {
      setIsUnsavedConfirmDialogOpen(true)
    } else {
      setIsAddDialogOpen(false)
      setSelectedAlert(null)
    }
  }, [hasUnsavedChanges])

  const handleEditDialogClose = useCallback((): void => {
    if (hasUnsavedChanges) {
      setIsUnsavedConfirmDialogOpen(true)
    } else {
      setIsEditDialogOpen(false)
    }
  }, [hasUnsavedChanges])

  const handleSaveAsActive = (data: AlertFormData): void => {
    void addOrUpdateAlert(data, true)
    setIsAddDialogOpen(false)
  }

  const handleSaveAsDraft = (data: AlertFormData): void => {
    void addOrUpdateAlert(data, false)
    setIsAddDialogOpen(false)
  }

  const activeAlerts = useMemo(
    () =>
      alertsData?.filter((alert) => alert.category === AlertCategory.ACTIVE) ??
      [],
    [alertsData],
  )

  const draftAlerts = useMemo(
    () =>
      alertsData?.filter((alert) => alert.category === AlertCategory.DRAFT) ??
      [],
    [alertsData],
  )

  const inactiveAlerts = useMemo(
    () =>
      alertsData?.filter(
        (alert) => alert.category === AlertCategory.INACTIVE,
      ) ?? [],
    [alertsData],
  )

  const currentAlerts = useMemo(
    () =>
      currentView === AlertCategory.ACTIVE
        ? activeAlerts
        : currentView === AlertCategory.DRAFT
        ? draftAlerts
        : currentView === AlertCategory.INACTIVE
        ? inactiveAlerts
        : alertsData ?? [],
    [currentView, activeAlerts, draftAlerts, inactiveAlerts, alertsData],
  )

  useEffect(() => {
    const activeAlertIds =
      alertsData
        ?.filter((alert) => alert.category === AlertCategory.ACTIVE)
        .map((alert) => alert.id) ?? []
    if (activeAlertIds) {
      const prevIds = new Set(openAlertsTranslation)
      activeAlertIds.forEach((id) => {
        prevIds.add(id)
      })
      setOpenAlertsTranslation(prevIds)
    }
  }, [alertsData])

  const handleCloseActiveConfirmDialog = (): void => {
    setIsActiveConfirmDialogOpen(false)
    setSelectedAlert(null)
  }

  const handleCloseInactiveConfirmDialog = (): void => {
    setIsInactiveConfirmDialogOpen(false)
    setSelectedAlert(null)
  }

  const setInactive = useCallback(async (): Promise<void> => {
    if (!portalSetting || !selectedAlert) {
      return
    }

    try {
      setIsSaving(true)
      await sendPostRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting?.id
        }/alerts/${selectedAlert.id}:disable`,
      )

      handleCloseInactiveConfirmDialog()
      enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
        variant: 'success',
      })

      await mutateAlerts()
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }, [portalSetting, selectedAlert])

  const handleSetInactive = (alert: Alert): void => {
    setSelectedAlert(alert)
    setIsInactiveConfirmDialogOpen(true)
  }

  const setActive = useCallback(async (): Promise<void> => {
    if (!portalSetting || !selectedAlert) {
      return
    }

    try {
      setIsSaving(true)
      await sendPostRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting?.id
        }/alerts/${selectedAlert.id}:activate`,
      )

      handleCloseActiveConfirmDialog()
      enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
        variant: 'success',
      })

      await mutateAlerts()
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }, [portalSetting, selectedAlert])

  const deleteDraftAlert = useCallback(async (): Promise<void> => {
    if (!portalSetting || !selectedAlert) {
      return
    }

    try {
      setIsSaving(true)
      await sendPostRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting?.id
        }/alerts/${selectedAlert.id}:remove`,
      )

      enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
        variant: 'success',
      })

      await mutateAlerts()
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }, [portalSetting, selectedAlert])

  const handleSetActive = (alert: Alert): void => {
    setSelectedAlert(alert)
    setIsActiveConfirmDialogOpen(true)
  }

  const handleCopyToDraft = (alert: Alert): void => {
    setSelectedAlert(alert)
    setIsAddDialogOpen(true)
  }

  const handleDiscard = (): void => {
    setIsUnsavedConfirmDialogOpen(false)
    setIsAddDialogOpen(false)
    setIsEditDialogOpen(false)
    setHasUnsavedChanges(false)
    setSelectedAlert(null)
  }

  const handleContinue = (): void => {
    setIsUnsavedConfirmDialogOpen(false)
  }

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    alert: Alert,
  ): void => {
    setMenuEl(event.currentTarget)
    setSelectedAlert(alert)
  }

  const handleCloseMenu = (): void => {
    setMenuEl(null)
  }

  const handleCopyAlert = (): void => {
    handleCloseMenu()
    setIsAddDialogOpen(true)
  }

  const handleEditAlert = (): void => {
    handleCloseMenu()
    setIsEditDialogOpen(true)
  }

  const handleEditAlertExpiry = (): void => {
    handleCloseMenu()
    setIsEditExpiryDialogOpen(true)
  }

  const handleDeleteDraftAlert = (): void => {
    handleCloseMenu()
    void deleteDraftAlert()
  }

  const getActivateTooltip = useCallback((alert: Alert): string | null => {
    if (alert._operations.canActivate) {
      return null
    }

    if (alert.expiry) {
      const expiredDate = new Date(alert.expiry)
      console.log(expiredDate < new Date())
      if (expiredDate < new Date()) {
        return formatMessage({ id: 'alert_list.tooltip.expired_alert' })
      }
    }

    return formatMessage({ id: 'alert_list.tooltip.exist_active_alert' })
  }, [])

  const handleUpdateExpiry = useCallback(
    async (data: AlertExpiryFormData): Promise<void> => {
      if (!portalSetting) {
        return
      }

      try {
        setIsSaving(true)
        await sendPostRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting?.id
          }/alerts/${data.id}:updateExpiry${
            data.expiry
              ? `?timestamp=${encodeURIComponent(data.expiry.format())}`
              : ''
          }`,
        )

        enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
          variant: 'success',
        })

        await mutateAlerts()
      } catch (error) {
        console.error(error)
      } finally {
        setIsSaving(false)
        setIsEditExpiryDialogOpen(false)
      }
    },
    [portalSetting],
  )

  const handleEditExpiryDialogClose = (): void => {
    setIsEditExpiryDialogOpen(false)
  }

  return (
    <Stack height="100%" width="100%" spacing={2} overflow="auto">
      <Stack direction="row" width="100%" spacing={2}>
        <Box flexGrow={1}>
          <MainHeader>
            {formatMessage({ id: 'alert_list.header' })}{' '}
            <Tooltip
              title={formatMessage({
                id: 'alert_list.label.creating_alert_help_text',
              })}
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          </MainHeader>
        </Box>

        {alertsData &&
          alertsData?.length > 0 &&
          checkAccesses({ [PortalSection.ALERTS]: [FeatureAccess.WRITE] }) && (
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={handleAddDialogOpen}
              color="secondary"
            >
              {formatMessage({ id: 'alert_list.button.create_alert' })}
            </Button>
          )}
      </Stack>

      <ViewTabsWrapper direction="row" padding={1} spacing={1}>
        <Button
          size="small"
          variant={currentView === AlertCategory.ACTIVE ? 'contained' : 'text'}
          onClick={() => {
            setCurrentView(AlertCategory.ACTIVE)
          }}
          endIcon={
            activeAlerts.length > 0 && (
              <Box className="counter">{activeAlerts.length}</Box>
            )
          }
        >
          {formatMessage({ id: 'alert_list.view.active' })}
        </Button>
        <Button
          size="small"
          variant={currentView === AlertCategory.DRAFT ? 'contained' : 'text'}
          onClick={() => {
            setCurrentView(AlertCategory.DRAFT)
          }}
          endIcon={
            draftAlerts.length > 0 && (
              <Box className="counter">{draftAlerts.length}</Box>
            )
          }
        >
          {formatMessage({ id: 'alert_list.view.draft' })}
        </Button>
        <Button
          size="small"
          variant={
            currentView === AlertCategory.INACTIVE ? 'contained' : 'text'
          }
          onClick={() => {
            setCurrentView(AlertCategory.INACTIVE)
          }}
          endIcon={
            inactiveAlerts.length > 0 && (
              <Box className="counter">{inactiveAlerts.length}</Box>
            )
          }
        >
          {formatMessage({ id: 'alert_list.view.inactive' })}
        </Button>

        {/* <Button
          size="small"
          variant={currentView === 'logs' ? 'contained' : 'text'}
          onClick={() => {
            setCurrentView('logs')
          }}
        >
          {formatMessage({ id: 'alert_list.view.logs' })}
        </Button> */}
      </ViewTabsWrapper>

      <Stack flexGrow={1} overflow="auto" spacing={2}>
        {alertsData &&
          alertsData.length === 0 &&
          checkAccesses({ [PortalSection.ALERTS]: [FeatureAccess.WRITE] }) && (
            <Wrapper
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Stack textAlign="center" spacing={2} alignItems="center">
                <EmptyAlertIcon />
                <Box width="60%">
                  <SubHeader>
                    {formatMessage({
                      id: 'alert_list.label.start_creating_alert',
                    })}
                  </SubHeader>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'alert_list.label.creating_alert_help_text',
                    })}
                  </Typography>
                </Box>
                <Box width="100%">
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={handleAddDialogOpen}
                    color="secondary"
                  >
                    {formatMessage({
                      id: 'alert_list.button.create_alert',
                    })}
                  </Button>
                </Box>
              </Stack>
            </Wrapper>
          )}

        {isLoading && <CircularProgress />}

        {currentAlerts?.map((alert) => (
          <Wrapper key={alert.id} spacing={1}>
            <Stack
              direction="row"
              width={'100%'}
              alignItems="center"
              spacing={1}
              paddingRight={1}
            >
              <Stack flexGrow={1} direction="row" spacing={1}>
                {alert.category === AlertCategory.ACTIVE && (
                  <ActiveStatus>
                    {formatMessage({
                      id: 'alert_list.view.active',
                    })}
                  </ActiveStatus>
                )}
                {alert.category === AlertCategory.DRAFT && (
                  <DraftStatus>
                    {formatMessage({
                      id: 'alert_list.view.draft',
                    })}
                  </DraftStatus>
                )}
                {alert.category === AlertCategory.INACTIVE && (
                  <InactiveStatus>
                    {formatMessage({
                      id: 'alert_list.view.inactive',
                    })}
                  </InactiveStatus>
                )}

                {alert.category !== AlertCategory.DRAFT && (
                  <Stack
                    flexGrow={1}
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <Tooltip
                      title={formatMessage({
                        id: 'alert_list.tooltip.viewed',
                      })}
                      placement="bottom"
                    >
                      <ViewsCountIcon />
                    </Tooltip>{' '}
                    <Typography>{alert.viewsCount}</Typography>
                  </Stack>
                )}
              </Stack>

              {alert.expiry && (
                <>
                  <Tooltip
                    title={formatMessage({
                      id: 'alert_list.tooltip.expiration',
                    })}
                    placement="bottom"
                  >
                    <InfoText>
                      <ExpiryIcon fontSize="small" />
                      {formatDate(alert.expiry)}
                    </InfoText>
                  </Tooltip>
                  <Divider orientation="vertical" flexItem />
                </>
              )}

              <InfoText
                sx={{
                  cursor: 'pointer',
                }}
              >
                ID {shortenUuid(alert.id)}
              </InfoText>

              {currentView !== AlertCategory.INACTIVE &&
                checkAccesses({
                  [PortalSection.ALERTS]: [FeatureAccess.WRITE],
                }) && (
                  <IconButton
                    onClick={(event) => {
                      handleOpenMenu(event, alert)
                    }}
                    size="small"
                    aria-label={formatMessage({
                      id: 'general.icon_button.see_more',
                    })}
                    role="button"
                    color="inherit"
                  >
                    <MoreVertIcon
                      sx={{
                        fontSize: 16,
                      }}
                    />
                  </IconButton>
                )}

              {alert._operations.canDisable &&
                checkAccesses({
                  [PortalSection.ALERTS]: [FeatureAccess.WRITE],
                }) && (
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      handleSetInactive(alert)
                    }}
                  >
                    {formatMessage({ id: 'alert_list.button.set_inactive' })}
                  </Button>
                )}

              {currentView === AlertCategory.DRAFT &&
                checkAccesses({
                  [PortalSection.ALERTS]: [FeatureAccess.WRITE],
                }) && (
                  <Tooltip title={getActivateTooltip(alert)}>
                    <span>
                      <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          handleSetActive(alert)
                        }}
                        disabled={!alert._operations.canActivate}
                      >
                        {formatMessage({ id: 'alert_list.button.set_active' })}
                      </Button>
                    </span>
                  </Tooltip>
                )}

              {alert._operations.canCopy &&
                checkAccesses({
                  [PortalSection.ALERTS]: [FeatureAccess.WRITE],
                }) &&
                currentView === AlertCategory.INACTIVE && (
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => {
                      handleCopyToDraft(alert)
                    }}
                  >
                    {formatMessage({ id: 'alert_list.button.copy_to_draft' })}
                  </Button>
                )}
            </Stack>
            <Stack spacing={1} padding={2}>
              {openAlertsTranslation.has(alert.id) && (
                <Stack spacing={1}>
                  {alert.texts.map((text) => (
                    <Stack key={text.language}>
                      <Box>
                        <Chip label={text.language} color="info" size="small" />
                      </Box>
                      <ItemText>{text.content}</ItemText>
                    </Stack>
                  ))}
                  <Box>
                    <Button
                      variant="text"
                      size="small"
                      endIcon={<ArrowUpIcon />}
                      onClick={() => {
                        setOpenAlertsTranslation((prev) => {
                          prev.delete(alert.id)
                          return new Set(prev)
                        })
                      }}
                    >
                      {formatMessage({
                        id: 'alert_list.button.hide_translations',
                      })}
                    </Button>
                  </Box>
                </Stack>
              )}

              {!openAlertsTranslation.has(alert.id) && (
                <Stack spacing={1}>
                  <ItemText>{getLocalizedContent(alert.texts)}</ItemText>
                  <Box>
                    <Button
                      variant="text"
                      size="small"
                      endIcon={<ArrowDownIcon />}
                      onClick={(): void => {
                        setOpenAlertsTranslation((prev) => {
                          prev.add(alert.id)
                          return new Set(prev)
                        })
                      }}
                    >
                      {formatMessage({
                        id: 'alert_list.button.show_translations',
                      })}
                    </Button>
                  </Box>
                </Stack>
              )}

              <Box>
                <Chip
                  label={getLocalizedContent(alert.item.names)}
                  size="small"
                />
              </Box>
            </Stack>
          </Wrapper>
        ))}
      </Stack>

      <Menu
        anchorEl={menuEl}
        open={openMenu}
        onClose={handleCloseMenu}
        role="menu"
      >
        {selectedAlert?._operations.canCopy && (
          <MenuItem onClick={handleCopyAlert}>
            <ListItemIcon>
              <CopyIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>
              {formatMessage({
                id: 'general.icon_button.copy',
              })}
            </ListItemText>
          </MenuItem>
        )}

        {selectedAlert?._operations.canUpdateExpiry &&
          currentView === AlertCategory.ACTIVE && (
            <MenuItem onClick={handleEditAlertExpiry}>
              <ListItemIcon>
                <EditIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>
                {formatMessage({
                  id: 'alert_list.button.edit_expiry',
                })}
              </ListItemText>
            </MenuItem>
          )}

        {selectedAlert?._operations.canEdit &&
          currentView === AlertCategory.DRAFT && (
            <MenuItem onClick={handleEditAlert}>
              <ListItemIcon>
                <EditIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>
                {formatMessage({
                  id: 'general.icon_button.edit',
                })}
              </ListItemText>
            </MenuItem>
          )}

        {currentView === AlertCategory.DRAFT && (
          <MenuItem onClick={handleDeleteDraftAlert}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>
              {formatMessage({
                id: 'general.icon_button.delete',
              })}
            </ListItemText>
          </MenuItem>
        )}
      </Menu>

      <AlertAddDialog
        alert={selectedAlert}
        isOpen={isAddDialogOpen}
        isSaving={isSaving}
        onSaveAsActive={handleSaveAsActive}
        onSaveAsDraft={handleSaveAsDraft}
        onClose={handleAddDialogClose}
      />

      <AlertEditDialog
        alert={selectedAlert}
        isOpen={isEditDialogOpen}
        isSaving={isSaving}
        onSaveAsActive={handleSaveAsActive}
        onSaveAsDraft={handleSaveAsDraft}
        onClose={handleEditDialogClose}
      />

      <AlertEditExpiryDialog
        alert={selectedAlert}
        isOpen={isEditExpiryDialogOpen}
        isSaving={isSaving}
        onSave={(data) => {
          void handleUpdateExpiry(data)
        }}
        onClose={handleEditExpiryDialogClose}
      />

      <Dialog open={isUnsavedConfirmDialogOpen} maxWidth="xs">
        <DialogTitle sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'general.unsaved_confirm_dialog.title' })}
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'general.unsaved_confirm_dialog.content' })}
        </DialogContent>
        <DialogActions>
          <Stack width="100%" spacing={1}>
            <Button
              fullWidth
              onClick={handleContinue}
              autoFocus
              variant="contained"
            >
              {formatMessage({
                id: 'general.unsaved_confirm_dialog.button.continue',
              })}
            </Button>
            <Button fullWidth onClick={handleDiscard} variant="outlined">
              {formatMessage({
                id: 'general.unsaved_confirm_dialog.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog open={isActiveConfirmDialogOpen} maxWidth="xs">
        <DialogTitle sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'alert_list.dialog.active.title' })}
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'alert_list.dialog.active.content' })}
        </DialogContent>
        <DialogActions>
          <Stack width="100%" spacing={1}>
            <LoadingButton
              loading={isSaving}
              fullWidth
              onClick={(): void => {
                void setActive()
              }}
              autoFocus
              variant="contained"
              color="error"
            >
              {formatMessage({
                id: 'alert_list.button.set_active',
              })}
            </LoadingButton>
            <Button
              fullWidth
              onClick={handleCloseActiveConfirmDialog}
              variant="outlined"
              color="error"
            >
              {formatMessage({
                id: 'general.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog open={isInactiveConfirmDialogOpen} maxWidth="xs">
        <DialogTitle sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'alert_list.dialog.inactive.title' })}
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'alert_list.dialog.inactive.content' })}
        </DialogContent>
        <DialogActions>
          <Stack width="100%" spacing={1}>
            <LoadingButton
              loading={isSaving}
              fullWidth
              onClick={(): void => {
                void setInactive()
              }}
              autoFocus
              variant="contained"
            >
              {formatMessage({
                id: 'alert_list.button.set_inactive',
              })}
            </LoadingButton>
            <Button
              fullWidth
              onClick={handleCloseInactiveConfirmDialog}
              variant="outlined"
            >
              {formatMessage({
                id: 'general.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Stack>
  )
}

export default AlertListPage
