import SubCategoryListTree from './SubCategoryListTree'
import { type CategoryRawData } from 'components/category/categoryTypes'
import { TreeView } from 'components/category/CategoryStyledComponents'

type CategoryListTreeProps = {
  categories: CategoryRawData[]
}

const CategoryListTree: React.FC<CategoryListTreeProps> = ({ categories }) => {
  return (
    <TreeView>
      <SubCategoryListTree paths={[]} categories={categories} />
    </TreeView>
  )
}

export default CategoryListTree
