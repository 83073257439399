import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'

import { CaseStatus } from './caseConstants'
import {
  getCaseStatusColor,
  getCaseStatusIcon,
} from 'components/case/caseUtils'

type CaseStatusIconProps = {
  status: CaseStatus
  size?: 'small' | 'medium' | 'large'
}

const Wrapper = styled(Stack)<{
  status: CaseStatus
  size: 'small' | 'medium' | 'large'
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ status }) => getCaseStatusColor(status)};

  color: ${({ status, theme }) =>
    status === CaseStatus.NEW ||
    status === CaseStatus.FORWARDED ||
    status === CaseStatus.NOT_FORWARDED
      ? theme.palette.common.white
      : theme.palette.common.black};
  border: none;

  & svg {
    color: ${({ status, theme }) =>
      status === CaseStatus.NEW ||
      status === CaseStatus.FORWARDED ||
      status === CaseStatus.NOT_FORWARDED
        ? theme.palette.common.white
        : theme.palette.common.black};
    font-size: ${({ size, theme }) => {
      switch (size) {
        case 'small':
          return '14px'
        case 'large':
          return '24px'
        default:
          return '16px'
      }
    }};
  }
  align-items: center;
  border-radius: ${({ theme }) => 1.5 * theme.shape.borderRadius}px;
  font-size: ${({ size, theme }) => {
    switch (size) {
      case 'small':
        return '11px'
      case 'large':
        return '14px'
      default:
        return '12px'
    }
  }};
  padding: ${({ size, theme }) => {
    switch (size) {
      case 'small':
        return '4px'
      case 'large':
        return '8px'
      default:
        return '6px'
    }
  }};
`

const CaseStatusIcon: React.FC<CaseStatusIconProps> = ({
  status,
  size = 'medium',
}) => {
  const CaseStatusIcon = getCaseStatusIcon(status)
  return (
    <Wrapper status={status} direction="row" size={size}>
      {CaseStatusIcon && <CaseStatusIcon />}
    </Wrapper>
  )
}

export default CaseStatusIcon
