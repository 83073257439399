import { useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useForm, useFieldArray } from 'react-hook-form'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'

import { type Locale } from 'types'
import ContactEditFields from 'components/item/ContactEditFields'
import { ItemContactMethodType } from './itemConstants'
import { type LocalizedContact } from 'components/item/itemTypes'
import { FormFieldsWrapper } from 'components/StyledComponents'

type ContactEditDialogProps = {
  isOpen: boolean
  title: string
  defaultLanguage: Locale
  defaultValue?: LocalizedContact[]
  onSave: (data: LocalizedContact[]) => void
  onClose: () => void
}

export type ContactFormData = {
  contacts: LocalizedContact[]
}

const ContactEditDialog: React.FC<ContactEditDialogProps> = ({
  isOpen,
  title,
  defaultLanguage,
  defaultValue = [],
  onSave,
  onClose,
}) => {
  const { formatMessage } = useIntl()
  const {
    control,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<ContactFormData>({
    mode: 'onTouched',
    defaultValues: {
      contacts: defaultValue,
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  })

  useEffect(() => {
    setValue('contacts', defaultValue)
  }, [JSON.stringify(defaultValue)])

  const handleSave = useCallback((): void => {
    const { contacts } = getValues()
    onSave(contacts)
  }, [])

  const handleAddContact = (): void => {
    append({
      name: '',
      language: defaultLanguage,
      contacts: [{ type: ItemContactMethodType.PHONE, contact: '' }],
    })
  }

  const handleRemoveContact = (index: number): void => {
    remove(index)
  }

  const handleCopyContact = (index: number): void => {
    const { contacts } = getValues()

    append(contacts[index])
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={handleSave}>
      <DialogTitle>{title}</DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
        aria-label={formatMessage({
          id: 'general.button.close',
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack spacing={2}>
          {fields.map((contact, index) => (
            <FormFieldsWrapper key={contact.id} spacing={1}>
              <ContactEditFields
                index={index}
                control={control}
                defaultLanguage={defaultLanguage}
                defaultValue={contact}
                errors={errors}
                onRemoveContact={handleRemoveContact}
                onCopyContact={handleCopyContact}
              />
            </FormFieldsWrapper>
          ))}

          <Box width="100%">
            <Button
              size="small"
              startIcon={<AddIcon />}
              onClick={handleAddContact}
            >
              {formatMessage({
                id: 'portal_item_edit.button.add_contact',
              })}
            </Button>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          onClick={handleSave}
          variant="contained"
          disabled={!isValid || Object.keys(errors).length > 0}
        >
          {formatMessage({ id: 'general.button.save' })}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ContactEditDialog
