import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useIntl } from 'react-intl'
import { Controller, useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import styled from '@mui/material/styles/styled'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import LoadingButton from '@mui/lab/LoadingButton'
import CloseIcon from '@mui/icons-material/Close'

import useApi from 'hooks/useApi'
import { portalSettingState } from 'state/portalSettingStates'
import {
  type EmailTemplate,
  type ContactBasic,
} from 'components/contact/contactTypes'
import { FormFieldsWrapper, SubSubHeader } from 'components/StyledComponents'
import Divider from '@mui/material/Divider'
import {
  EMAIL_BODY_MAX_LENGTH,
  EMAIL_SUBJECT_MAX_LENGTH,
  FORWARDING_REPLY_EMAIL,
} from 'components/contact/contactConstants'
import MultiLineInput from 'components/form/MultiLineInput'
import { ForwardingArrowIcon } from 'components/icons/Icons'
import { hasUnsavedChangesState } from 'state/formStates'
import Chip from '@mui/material/Chip'
import Alert from '@mui/material/Alert'

const TemplateLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  width: 120px;
`

const DisabledText = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.disabled};
`

const ForwardingIconWrapper = styled(Stack)`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-color: ${({ theme }) => theme.palette.info.light};
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
`

type CaseForwardingDialogProps = {
  isOpen: boolean
  hasUnfinishedTasks: boolean
  onClose: () => void
}

export type CaseForwardingFormData = {
  contactId: string
  emailTemplate: EmailTemplate
}

const CaseForwardingDialog: React.FC<CaseForwardingDialogProps> = ({
  isOpen,
  hasUnfinishedTasks,
  onClose,
}) => {
  const { formatMessage } = useIntl()
  const { caseId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const portalSetting = useRecoilValue(portalSettingState)
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const { sendPostRequest } = useApi()
  const [contacts, setContacts] = useState<ContactBasic[]>([])
  const [selectedContact, setSelectedContact] = useState<ContactBasic | null>(
    null,
  )
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const {
    control,
    setValue,
    getValues,
    formState: { errors, isValid, isDirty },
    trigger,
  } = useForm<CaseForwardingFormData>({
    mode: 'onTouched',
  })

  const getContacts = useCallback(async (): Promise<void> => {
    if (portalSetting) {
      const response = await sendPostRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/cases/${caseId}:listContacts`,
      )
      const data = await response.json()

      if (data) {
        setContacts(data)
      }
    }
  }, [portalSetting, caseId])

  useEffect(() => {
    if (isOpen) {
      void getContacts()
    }
  }, [isOpen])

  useEffect(() => {
    if (selectedContact) {
      setValue('contactId', selectedContact.id)
      setValue('emailTemplate', selectedContact.emailTemplate)
      void trigger()
    }
  }, [selectedContact])

  useEffect(() => {
    if (isDirty) {
      setHasUnsavedChanges(true)
    }
  }, [isDirty])

  const handleForwardCase = useCallback(async (): Promise<void> => {
    try {
      if (portalSetting) {
        setIsSending(true)
        const data = getValues()

        await sendPostRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting.id
          }/cases/${caseId}:forward`,
          {
            contactId: data.contactId,
            title: data.emailTemplate.title,
            body: data.emailTemplate.body,
          },
        )

        handleCloseConfirmDialog()
        onClose()
        enqueueSnackbar(
          formatMessage({
            id: 'general.text.changes_saved',
          }),
          {
            variant: 'success',
          },
        )
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsSending(false)
      setHasUnsavedChanges(false)
    }
  }, [portalSetting, caseId])

  const handleSelectContact = (event: SelectChangeEvent): void => {
    const selectedContactId = event.target.value
    const result = contacts.find((contact) => contact.id === selectedContactId)
    if (result) {
      setSelectedContact(result)
    }
  }

  const handleOpenConfirmDialog = (): void => {
    setIsConfirmDialogOpen(true)
  }

  const handleCloseConfirmDialog = (): void => {
    setIsConfirmDialogOpen(false)
  }

  const handleClose = (): void => {
    setHasUnsavedChanges(false)
    onClose()
  }

  return (
    <>
      <Dialog fullWidth maxWidth="md" open={isOpen} onClose={handleClose}>
        <DialogTitle>
          {formatMessage({ id: 'case_forwarding_dialog.title' })}
        </DialogTitle>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          aria-label={formatMessage({
            id: 'general.button.close',
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Stack spacing={2}>
            {hasUnfinishedTasks && (
              <Alert severity="error" variant="filled">
                {formatMessage({
                  id: 'error.forward_case.open_tasks',
                })}
              </Alert>
            )}

            <FormFieldsWrapper textAlign="center">
              <SubSubHeader>
                {formatMessage({
                  id: 'case_forwarding_dialog.sub.not_your_case',
                })}
              </SubSubHeader>
              <Typography variant="caption">
                {formatMessage({
                  id: 'case_forwarding_dialog.description.not_your_case',
                })}
              </Typography>
            </FormFieldsWrapper>

            <FormControl fullWidth size="small">
              <InputLabel id="select-contact-label">
                {formatMessage({
                  id: 'case_forwarding_dialog.label.select_contact',
                })}
              </InputLabel>
              <Select
                labelId="select-contact-label"
                value={selectedContact?.id}
                label={formatMessage({
                  id: 'case_forwarding_dialog.label.select_contact',
                })}
                onChange={handleSelectContact}
                size="small"
                disabled={hasUnfinishedTasks}
              >
                {contacts.map((contact) => (
                  <MenuItem key={contact.id} value={contact.id}>
                    {contact.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {selectedContact && (
              <FormFieldsWrapper>
                <SubSubHeader>
                  {formatMessage({
                    id: 'case_forwarding_dialog.sub.email_content',
                  })}
                </SubSubHeader>
                <Typography variant="caption">
                  {formatMessage({
                    id: 'case_forwarding_dialog.description.email_content',
                  })}
                </Typography>

                <Stack spacing={1} flexGrow={1}>
                  <Stack direction="row" spacing={2}>
                    <TemplateLabel>
                      {formatMessage({
                        id: 'contact_edit.email_template.from',
                      })}
                    </TemplateLabel>
                    <DisabledText>{FORWARDING_REPLY_EMAIL}</DisabledText>
                  </Stack>
                  <Divider />
                  <Stack direction="row" spacing={2}>
                    <TemplateLabel>
                      {formatMessage({
                        id: 'contact_edit.email_template.to',
                      })}
                    </TemplateLabel>
                    <DisabledText>{selectedContact.email}</DisabledText>
                  </Stack>
                  <Divider />
                  <Stack direction="row" spacing={2}>
                    <TemplateLabel>
                      {formatMessage({
                        id: 'contact_edit.email_template.cc',
                      })}
                    </TemplateLabel>

                    {selectedContact.ccEmails?.map((cc) => (
                      <Chip key={cc} label={cc} />
                    ))}
                  </Stack>
                  <Divider />

                  <Stack spacing={2} paddingTop={2}>
                    <FormControl error={!!errors.emailTemplate?.title}>
                      <Controller
                        name="emailTemplate.title"
                        control={control}
                        rules={{
                          maxLength: EMAIL_SUBJECT_MAX_LENGTH,
                          required: true,
                        }}
                        defaultValue={''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            required
                            size="small"
                            label={formatMessage({
                              id: 'contact_edit.email_template.subject',
                            })}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />

                      {errors.emailTemplate?.title?.type === 'required' && (
                        <FormHelperText>
                          {formatMessage({
                            id: 'general.error.required',
                          })}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <FormControl error={!!errors.emailTemplate?.body}>
                      <Controller
                        name="emailTemplate.body"
                        control={control}
                        rules={{
                          maxLength: EMAIL_BODY_MAX_LENGTH,
                          required: true,
                        }}
                        defaultValue={''}
                        render={({ field }) => (
                          <MultiLineInput
                            {...field}
                            required
                            maxLength={EMAIL_BODY_MAX_LENGTH}
                            label={formatMessage({
                              id: 'contact_edit.email_template.body',
                            })}
                            variant="outlined"
                            fullWidth
                            rows={7}
                            error={!!errors.emailTemplate?.body}
                            helpMessage={
                              errors.emailTemplate?.body?.type === 'required'
                                ? formatMessage({
                                    id: 'general.error.required',
                                  })
                                : undefined
                            }
                          />
                        )}
                      />
                      {}
                    </FormControl>
                  </Stack>
                </Stack>
              </FormFieldsWrapper>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            onClick={handleOpenConfirmDialog}
            variant="contained"
            disabled={
              !isValid || Object.keys(errors).length > 0 || !selectedContact
            }
          >
            {formatMessage({
              id: 'case_forwarding_dialog.button.forward_case',
            })}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isConfirmDialogOpen} maxWidth="sm">
        <DialogTitle sx={{ textAlign: 'center' }}>
          <Stack alignItems="center" paddingY={1}>
            <ForwardingIconWrapper>
              <ForwardingArrowIcon />
            </ForwardingIconWrapper>
          </Stack>

          {formatMessage({ id: 'case_forwarding_dialog.confirm.title' })}
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'case_forwarding_dialog.confirm.content' })}
        </DialogContent>
        <DialogActions>
          <Stack width="100%" spacing={1}>
            <LoadingButton
              loading={isSending}
              onClick={() => {
                void handleForwardCase()
              }}
              variant="contained"
            >
              {formatMessage({
                id: 'case_forwarding_dialog.confirm.button.forward',
              })}
            </LoadingButton>
            <Button
              onClick={handleCloseConfirmDialog}
              autoFocus
              variant="outlined"
            >
              {formatMessage({
                id: 'general.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CaseForwardingDialog
