import { useSearchParams } from 'react-router-dom'

import ResetPasswordPage from 'pages/ResetPasswordPage'
import VerifyEmailPage from 'pages/VerifyEmailPage'

enum Mode {
  RESET_PASSWORD = 'resetPassword',
  VERIFY_EMAIL = 'verifyEmail',
  RECOVER_EMAIL = 'recoverEmail',
}

const AuthActionPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const oobCode = searchParams.get('oobCode')
  const mode = searchParams.get('mode')

  if (mode === Mode.RESET_PASSWORD) {
    return <ResetPasswordPage oobCode={oobCode} />
  } else if (mode === Mode.VERIFY_EMAIL) {
    return <VerifyEmailPage oobCode={oobCode} />
  } else {
    throw new Error(`Invalid mode: ${mode}`)
  }
}

export default AuthActionPage
