import { useCallback, useContext, useMemo, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import { useForm, Controller, type SubmitHandler } from 'react-hook-form'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import styled from '@mui/material/styles/styled'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

import useRoute from 'hooks/useNavigate'
import {
  EMAIL_MAX_LENGTH,
  PHONE_MAX_LENGTH,
  Path,
  USER_NAME_MAX_LENGTH,
} from '../commonConstants'
import { isValidEmail, isValidPhoneNumber } from 'utils/stringUtils'
import useApi from 'hooks/useApi'
import AuthContext from 'context/AuthContext'

type SignUpFormData = {
  email: string
  firstName: string
  lastName: string
  phone: string
  language: string
}

const Wrapper = styled(Stack)`
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => 4 * theme.shape.borderRadius}px;
  width: 560px;
  max-width: 100vw;
`

const Header = styled(Typography)`
  font-size: 28px;
  font-weight: 500;
`

const SignUpPage: React.FC = () => {
  const { formatMessage, locale } = useIntl()
  const { goTo } = useRoute()
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)
  const { sendPostRequest } = useApi()
  const { authData } = useContext(AuthContext)
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<SignUpFormData>({
    mode: 'onTouched',
  })

  const onSubmit: SubmitHandler<SignUpFormData> = useCallback(
    async (data): Promise<void> => {
      try {
        setIsSaving(true)
        await sendPostRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/pusers`,
          {
            ...data,
            language: 'EN',
          },
        )

        const auth = getAuth()
        auth.languageCode = locale.toLowerCase()
        await sendPasswordResetEmail(auth, data.email)

        enqueueSnackbar(
          formatMessage(
            { id: 'forgot_password.text.email_sent' },
            { email: data.email },
          ),
          {
            variant: 'success',
          },
        )

        goTo(Path.LOGIN)
      } catch (error) {
        enqueueSnackbar(formatMessage({ id: 'general.error.unknown_error' }), {
          variant: 'error',
        })
      } finally {
        setIsSaving(false)
      }
    },
    [],
  )

  const shouldDisableSaving = useMemo(
    () => !isValid || Object.keys(errors).length > 0 || isSaving,
    [isValid, errors, isSaving],
  )

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper padding={7} spacing={2}>
        <Stack alignItems="center">
          <Header>{formatMessage({ id: 'sign_up.header' })}</Header>
        </Stack>

        <FormControl error={!!errors.firstName} fullWidth>
          <Controller
            name="firstName"
            control={control}
            rules={{
              required: true,
              maxLength: USER_NAME_MAX_LENGTH,
            }}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.firstName}
                required
                autoFocus
                size="small"
                label={formatMessage({
                  id: 'member_edit.label.first_name',
                })}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </FormControl>

        <FormControl error={!!errors.lastName} fullWidth>
          <Controller
            name="lastName"
            control={control}
            rules={{
              required: true,
              maxLength: USER_NAME_MAX_LENGTH,
            }}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.lastName}
                required
                size="small"
                label={formatMessage({
                  id: 'customer_edit.label.last_name',
                })}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </FormControl>

        <FormControl error={!!errors.email}>
          <Controller
            name="email"
            control={control}
            rules={{
              maxLength: EMAIL_MAX_LENGTH,
              required: true,
              validate: (val): boolean | string => {
                if (!val) {
                  return true
                }

                return isValidEmail(val)
              },
            }}
            defaultValue={authData?.email ?? ''}
            render={({ field }) => (
              <TextField
                {...field}
                required
                size="small"
                label={formatMessage({
                  id: 'member_edit.label.email',
                })}
                variant="outlined"
                fullWidth
              />
            )}
          />

          {!!errors.email?.message && (
            <FormHelperText>{errors.email?.message}</FormHelperText>
          )}

          {errors.email?.type === 'validate' && !errors.email?.message && (
            <FormHelperText>
              {formatMessage({
                id: 'general.error.validate_email',
              })}
            </FormHelperText>
          )}

          {errors.email?.type === 'maxLength' && (
            <FormHelperText>
              {formatMessage(
                {
                  id: 'general.error.max_length_email',
                },
                { max: EMAIL_MAX_LENGTH },
              )}
            </FormHelperText>
          )}
          {errors.email?.type === 'required' && (
            <FormHelperText>
              {formatMessage({
                id: 'general.error.required',
              })}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl error={!!errors.phone}>
          <Controller
            name="phone"
            control={control}
            rules={{
              maxLength: PHONE_MAX_LENGTH,
              validate: (val): boolean => {
                if (!val) {
                  return true
                }

                return isValidPhoneNumber(val)
              },
            }}
            defaultValue={''}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label={formatMessage({
                  id: 'member_edit.label.phone',
                })}
                variant="outlined"
                fullWidth
              />
            )}
          />

          {errors.phone?.type === 'validate' && (
            <FormHelperText>
              {formatMessage({
                id: 'general.error.validate_phone',
              })}
            </FormHelperText>
          )}
          {errors.phone?.type === 'maxLength' && (
            <FormHelperText>
              {formatMessage(
                {
                  id: 'general.error.max_length_phone',
                },
                { max: PHONE_MAX_LENGTH },
              )}
            </FormHelperText>
          )}
        </FormControl>

        <LoadingButton
          variant="contained"
          size="large"
          type="submit"
          disabled={shouldDisableSaving}
          loading={isSaving}
        >
          {formatMessage({ id: 'sign_up.button.sign_up' })}
        </LoadingButton>
      </Wrapper>
    </form>
  )
}

export default SignUpPage
