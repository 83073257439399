import { useIntl } from 'react-intl'
import { useRecoilValue } from 'recoil'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import MuiLink from '@mui/material/Link'

import { isMobileViewState } from 'state/layoutStates'
import { PREPORTI_SUPPORT_LOCALES_MAP } from '../../commonConstants'

const Wrapper = styled(Stack)`
  width: 100%;
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: 10px;
`

const Link = styled(MuiLink)`
  color: inherit;
  font-weight: 500;
  line-height: 30px;
`

const LoginFooter: React.FC = () => {
  const { formatMessage, locale } = useIntl()
  const isMobileView = useRecoilValue(isMobileViewState)

  return (
    <Wrapper alignItems="center" justifyContent="center">
      <Stack
        direction={isMobileView ? 'column' : 'row'}
        width={560}
        alignItems="center"
        justifyContent="space-around"
        maxWidth="100vw"
      >
        <Link
          data-testid="faq-link"
          href={`https://www.preporti.com/${
            PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
          }faq`}
          target="_blank"
        >
          {formatMessage({ id: 'login_footer.link.faq' })}
        </Link>
        <Link
          data-testid="terms-link"
          href={`https://www.preporti.com/${
            PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
          }terms-of-use`}
          target="_blank"
        >
          {formatMessage({ id: 'login_footer.link.terms' })}
        </Link>
        <Link
          data-testid="privacy-link"
          href={`https://www.preporti.com/${
            PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
          }privacy-policy`}
          target="_blank"
        >
          {formatMessage({ id: 'login_footer.link.privacy_policy' })}
        </Link>
        <Link
          data-testid="about-us-link"
          href={`https://www.preporti.com/${
            PREPORTI_SUPPORT_LOCALES_MAP[locale] ?? ''
          }`}
          target="_blank"
        >
          {formatMessage({ id: 'login_footer.link.about_us' })}
        </Link>
      </Stack>
    </Wrapper>
  )
}

export default LoginFooter
