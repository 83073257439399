import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import styled from '@mui/material/styles/styled'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Input from '@mui/material/Input'
import Checkbox from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import OutlinedInput from '@mui/material/OutlinedInput'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'

import {
  casesDataState,
  currentCaseListViewState,
  keywordState,
  offsetState,
  selectedCasePeriodState,
  selectedCaseShareabilitiesState,
  selectedCaseStatusesState,
} from '../../state/caseListStates'
import CaseStatusIcon from 'components/case/CaseStatusIcon'
import CaseStatusTag from 'components/case/CaseStatusTag'
import { portalSettingState } from 'state/portalSettingStates'
import CaseShareabilityIcon from 'components/case/CaseShareabilityIcon'

import {
  Shareability,
  CaseStatus,
  Period,
  CurrentCaseListView,
  CASE_SHAREABILITY_LABEL,
  VirtualPortalCaseStatus,
  PERIOD_LABEL,
} from './caseConstants'
import { SubContentWrapper } from 'components/StyledComponents'

const FilterWrapper = styled(Stack)`
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
`

const CaseFilter: React.FC = () => {
  const { formatMessage } = useIntl()
  const [selectedCaseShareabilities, setSelectedCaseShareabilities] =
    useRecoilState(selectedCaseShareabilitiesState)
  const [selectedCaseStatuses, setSelectedCaseStatuses] = useRecoilState(
    selectedCaseStatusesState,
  )
  const [selectedCasePeriod, setSelectedCasePeriod] = useRecoilState(
    selectedCasePeriodState,
  )
  const setCasesData = useSetRecoilState(casesDataState)
  const setOffset = useSetRecoilState(offsetState)
  const [currentCaseListView, setCurrentCaseListView] = useRecoilState(
    currentCaseListViewState,
  )
  const portalSetting = useRecoilValue(portalSettingState)
  const [search, setSearch] = useState('')
  const [keyword, setKeyword] = useRecoilState(keywordState)

  useEffect(() => {
    setSearch(keyword)
  }, [keyword])

  const clearCases = (): void => {
    setCasesData([])
    setOffset(0)
  }

  const handleShareabilityChange = (
    event: SelectChangeEvent<Shareability[]>,
  ): void => {
    const {
      target: { value },
    } = event
    if (typeof value === 'string') {
      setSelectedCaseShareabilities([value as Shareability])
    } else {
      setSelectedCaseShareabilities(value.sort())
    }
    clearCases()
  }

  const handleStatusChange = (event: SelectChangeEvent<CaseStatus[]>): void => {
    const {
      target: { value },
    } = event
    if (typeof value === 'string') {
      setSelectedCaseStatuses([value as CaseStatus])
    } else {
      setSelectedCaseStatuses(value.sort())
    }
    clearCases()
  }

  const handlePeriodChange = (event: SelectChangeEvent): void => {
    setSelectedCasePeriod(event.target.value as Period)
    clearCases()
  }

  const handleSearch = (): void => {
    setKeyword(search)
    clearCases()
  }

  const handleClearSearch = useCallback((): void => {
    if (!keyword) {
      setSearch('')
    } else {
      clearCases()
      setKeyword('')
    }
  }, [keyword])

  return (
    <FilterWrapper width={'100%'} direction={'row'} marginY={1}>
      <SubContentWrapper
        direction={'row'}
        spacing={2}
        alignItems={'center'}
        flexGrow={1}
      >
        <Stack flexGrow={1} direction="row" alignItems="center" spacing={2}>
          <Button
            size="small"
            variant={
              currentCaseListView === CurrentCaseListView.LIST
                ? 'contained'
                : 'text'
            }
            onClick={() => {
              setCurrentCaseListView(CurrentCaseListView.LIST)
              clearCases()
            }}
            sx={{ height: 42 }}
          >
            {formatMessage({ id: 'case_list.list_view' })}
          </Button>
          <Button
            size="small"
            variant={
              currentCaseListView === CurrentCaseListView.MAP
                ? 'contained'
                : 'text'
            }
            onClick={() => {
              setCurrentCaseListView(CurrentCaseListView.MAP)
              clearCases()
            }}
          >
            {formatMessage({ id: 'case_list.map_view' })}
          </Button>

          <FormControl sx={{ m: 1, width: 400 }} variant="outlined">
            <OutlinedInput
              endAdornment={
                <Stack direction="row">
                  {search && (
                    <IconButton
                      data-testid="case-search-clear-button"
                      type="button"
                      aria-label={formatMessage({
                        id: 'general.button.clear_search',
                      })}
                      onClick={handleClearSearch}
                      size="small"
                    >
                      <ClearIcon fontSize="inherit" />
                    </IconButton>
                  )}

                  <IconButton
                    data-testid="case-search-button"
                    type="button"
                    aria-label={formatMessage({
                      id: 'general.button.search',
                    })}
                    onClick={handleSearch}
                    size="small"
                  >
                    <SearchIcon fontSize="inherit" />
                  </IconButton>
                </Stack>
              }
              inputProps={{
                'aria-label': formatMessage({
                  id: 'general.button.search',
                }),
                'data-testid': 'case-search-input',
              }}
              size="small"
              placeholder={formatMessage({
                id: 'case_filter.placeholder.search',
              })}
              value={search}
              onChange={(event) => {
                setSearch(event.target.value)
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleSearch()
                }
              }}
            />
          </FormControl>
        </Stack>

        <FormControl variant="standard">
          <InputLabel id="case-shareability-select-label">
            {formatMessage({
              id: 'case_list.cases.header.shareability',
            })}
          </InputLabel>
          <Select
            labelId="case-shareability-select-label"
            multiple
            size="small"
            value={selectedCaseShareabilities}
            onChange={handleShareabilityChange}
            input={<Input />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <CaseShareabilityIcon
                    key={value}
                    shareability={value}
                    size="small"
                  />
                ))}
              </Box>
            )}
          >
            {Object.values(Shareability).map((shareability) => (
              <MenuItem key={shareability} value={shareability}>
                <Checkbox
                  checked={selectedCaseShareabilities.includes(shareability)}
                />
                <CaseShareabilityIcon shareability={shareability} />
                <Typography marginLeft={1}>
                  {formatMessage({
                    id: CASE_SHAREABILITY_LABEL[shareability],
                  })}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="standard">
          <InputLabel id="case-status-select-label">
            {formatMessage({
              id: 'case_filter.label.status',
            })}
          </InputLabel>
          <Select
            labelId="case-status-select-label"
            multiple
            size="small"
            value={selectedCaseStatuses}
            onChange={handleStatusChange}
            input={<Input />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <CaseStatusIcon key={value} status={value} size="small" />
                ))}
              </Box>
            )}
          >
            {Object.values(
              portalSetting?.virtual ? VirtualPortalCaseStatus : CaseStatus,
            ).map((caseStatus) => (
              <MenuItem key={caseStatus} value={caseStatus}>
                <Checkbox checked={selectedCaseStatuses.includes(caseStatus)} />
                <CaseStatusTag status={caseStatus} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="standard">
          <InputLabel id="case-date-select-label">
            {formatMessage({
              id: 'case_filter.label.created',
            })}
          </InputLabel>
          <Select
            labelId="case-date-select-label"
            value={selectedCasePeriod}
            onChange={handlePeriodChange}
            size="small"
            renderValue={(selected) => (
              <Chip
                size="small"
                label={formatMessage({
                  id: PERIOD_LABEL[selected],
                })}
              />
            )}
          >
            {Object.values(Period).map((period) => (
              <MenuItem key={period} value={period}>
                {formatMessage({
                  id: PERIOD_LABEL[period],
                })}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </SubContentWrapper>
    </FilterWrapper>
  )
}

export default CaseFilter
