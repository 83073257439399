import { useIntl } from 'react-intl'
import styled from '@mui/material/styles/styled'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import { type Role } from 'components/role/roleTypes'

type RoleListProps = {
  roles: Role[]
  selectedRole: Role | null
  handleRoleClick: (index: number) => void
}

const RoleListWrapper = styled(Stack)`
  background: ${({ theme }) => theme.palette.info.light};
  padding: 16px;
  border-right: 1px solid;
  border-right-color: ${({ theme }) => theme.palette.divider};
  font-size: 16px;
  font-weight: 500;

  .MuiList-root {
    .MuiMenuItem-root {
      border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
      padding: 8px 16px;
    }
    .Mui-selected {
      background: ${({ theme }) => theme.palette.primary.main};

      .MuiListItemText-root {
        color: ${({ theme }) => theme.palette.primary.contrastText};
      }
    }
  }
`

const RoleCounter = styled(Box)`
  background: #d5dcf7;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 12px;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: ${({ theme }) => 1.5 * theme.shape.borderRadius}px;
`

const RoleList: React.FC<RoleListProps> = ({
  roles,
  selectedRole,
  handleRoleClick,
}) => {
  const { formatMessage } = useIntl()

  return (
    <RoleListWrapper width={400} overflow={'auto'} height="100%">
      <MenuList>
        {roles.map((role, index) => (
          <MenuItem
            key={role.id}
            selected={selectedRole?.id === role.id}
            onClick={() => {
              handleRoleClick(index)
            }}
            tabIndex={0}
          >
            <ListItemText>
              {role.name}{' '}
              {role.default &&
                `(${formatMessage({
                  id: 'member_role_list.label.default_role',
                })})`}
            </ListItemText>

            {role.membersCount && (
              <RoleCounter>{role.membersCount}</RoleCounter>
            )}
          </MenuItem>
        ))}
      </MenuList>
    </RoleListWrapper>
  )
}

export default RoleList
