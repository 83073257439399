import { useEffect, useRef } from 'react'

type CanvasProps = {
  draw: (context: CanvasRenderingContext2D, frameCount: number) => void
  needToClear: boolean
  width: number
  height: number
}

const Canvas: React.FC<CanvasProps> = ({
  draw,
  needToClear,
  width,
  height,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const animationFrameIdRef = useRef(0)

  const clearAnimationFrame = (): void => {
    window.cancelAnimationFrame(animationFrameIdRef.current)
    const canvas = canvasRef.current
    const context = canvas?.getContext('2d')
    if (context && canvas) {
      context.clearRect(0, 0, canvas.width, canvas.height)
    }
  }

  useEffect(() => {
    if (needToClear) {
      clearAnimationFrame()
    }
  }, [needToClear])

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas?.getContext('2d')
    let frameCount = 0

    if (context) {
      const render = (): void => {
        frameCount++
        draw(context, frameCount)
        animationFrameIdRef.current = window.requestAnimationFrame(render)
      }
      render()
    }

    return () => {
      clearAnimationFrame()
    }
  }, [draw])

  return <canvas ref={canvasRef} width={width} height={height} />
}

export default Canvas
