import { type ReactNode } from 'react'
import styled from '@mui/material/styles/styled'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

import LoginTopBanner from 'components/login/LoginTopBanner'
import LoginFooter from 'components/login/LoginFooter'
import DownloadAppLinks from 'components/login/DownloadAppLinks'

type LoginLayoutProps = {
  children: ReactNode
}

const Wrapper = styled('main')`
  display: flex;
  flex-direction: column;
  width: 560px;
  max-width: 100vw;
  gap: 20px;
`

const LoginLayout: React.FC<LoginLayoutProps> = ({ children }) => (
  <Box height={'100vh'} width="100%">
    <Stack height={'100%'} width="100%" overflow="hidden">
      <header>
        <LoginTopBanner />
      </header>
      <Stack
        flexGrow={1}
        overflow="auto"
        justifyContent="center"
        alignItems="center"
      >
        <Wrapper>
          {children}
          <DownloadAppLinks />
        </Wrapper>
      </Stack>

      <footer>
        <LoginFooter />
      </footer>
    </Stack>
  </Box>
)

export default LoginLayout
