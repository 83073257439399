import { useCallback, useEffect, useRef, useState } from 'react'
import Canvas from './Canvas'

export type Position = {
  x: number
  y: number
}

type DrawFocusProps = {
  position: Position | null
  width: number
  height: number
}

const DrawFocus: React.FC<DrawFocusProps> = ({ position, width, height }) => {
  const [needToClear, setNeedToClear] = useState(false)
  const timeoutIdRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current)
    }

    timeoutIdRef.current = setTimeout(() => {
      setNeedToClear(true)
    }, 2000)

    return () => {
      clearTimeout(timeoutIdRef.current)
      setNeedToClear(false)
    }
  }, [position])

  const handleDraw = useCallback(
    (context: CanvasRenderingContext2D, frameCount: number): void => {
      if (position) {
        context.clearRect(0, 0, context.canvas.width, context.canvas.height)
        context.strokeStyle = '#FC3527'
        context.beginPath()
        context.arc(
          position.x,
          position.y,
          (Math.floor(frameCount / 2.5) % 13) + 3,
          0,
          2 * Math.PI,
        )
        context.closePath()
        context.stroke()
      }
    },
    [position],
  )

  return (
    <Canvas
      draw={handleDraw}
      needToClear={needToClear}
      width={width}
      height={height}
    />
  )
}

export default DrawFocus
