import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useForm, Controller, type SubmitHandler } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import { useSnackbar } from 'notistack'
import LoadingButton from '@mui/lab/LoadingButton'
import styled from '@mui/material/styles/styled'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import FormHelperText from '@mui/material/FormHelperText'
import Divider from '@mui/material/Divider'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import TopicIcon from '@mui/icons-material/Topic'
import LanguageIcon from '@mui/icons-material/Language'
import EditIcon from '@mui/icons-material/Edit'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Slider from '@mui/material/Slider'
import Radio from '@mui/material/Radio'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import FeedIcon from '@mui/icons-material/Feed'

import {
  FormFieldsWrapper,
  MainHeader,
  SmallText,
  SubSubHeader,
  Switch,
  RadioGroup,
  RadioWrapper,
  BackButtonGrey,
  SettingWrapper,
  LightSubHeader,
} from 'components/StyledComponents'
import useRoute from 'hooks/useNavigate'
import { type Locale } from 'types'
import {
  type NewItem,
  type Item,
  type ItemPostalDetails,
  type LocalizedContact,
  type ItemNotificationsDetails,
} from 'components/item/itemTypes'
import {
  PortalType,
  SHORT_TEXT_MAX_LENGTH,
  EMAIL_MAX_LENGTH,
  SUPPORT_REGIONS,
  SUPPORT_REGION_LABEL,
  Path,
} from '../commonConstants'
import {
  ITEM_DESCRIPTION_MAX_LENGTH,
  ITEM_FEED_URL_MAX_LENGTH,
  ITEM_NAME_MAX_LENGTH,
  ITEM_WEBSITE_MAX_LENGTH,
  ITEM_REPORT_DISCLAIMER_MAX_LENGTH,
  ITEM_ZIP_CODES_MAX_LENGTH,
  ItemContactMethodType,
  ItemPrivacyMode,
  ITEM_PUBLIC_RETENTION_DAYS,
} from 'components/item/itemConstants'
import AddressInput from 'components/form/AddressInput'
import { portalSettingState } from 'state/portalSettingStates'
import MultiLineInput from 'components/form/MultiLineInput'
import {
  hasNewFileState,
  isFileUploadingState,
  hasUnsavedChangesState,
  processedFormFilesState,
} from 'state/formStates'
import { selectedLocationInfoState, hasNewAddressState } from 'state/mapStates'
import TranslationDialog from 'components/TranslationDialog'
import ContactEditDialog from 'components/item/ContactEditDialog'
import useApi from 'hooks/useApi'
import ImageUploader from 'components/form/ImageUploader'
import CommunityIcon from 'assets/icons/community.svg'
import CommunityPrivateIcon from 'assets/icons/community_private.svg'
import OpenModeIcon from 'assets/icons/open_mode.svg'
import OpenModePrivateIcon from 'assets/icons/open_mode_private.svg'
import usePortalSetting from 'hooks/usePortalSetting'
import {
  convertDataToLocalizedString,
  convertLocalizedStringToData,
} from 'utils/stringUtils'

const FORM_NAME = 'item'

const MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD = 500
const MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD = 500

export type ItemFormData = {
  names: Record<string, string>
  website: string
  address?: string
  descriptions: Record<string, string>
  newsFeedUrls: Record<string, string>
  eventsFeedUrls: Record<string, string>
  disclaimers: Record<string, string>
  contacts: LocalizedContact[]
  searchRadius?: number
  reportingEmail?: string
  privacyMode: ItemPrivacyMode
  anonymousReporting: boolean
  postalDetails?: ItemPostalDetails
  publicCaseRetentionDays?: number
  notificationsDetails: ItemNotificationsDetails
}

const ItemNameWrapper = styled(Box)`
  margin-top: 0;
`

const PrivacyModeCheckIcon = styled(CheckCircleIcon)`
  color: #7adcd0;
  font-size: 14px;
`

const PrivacyTitle = styled('h3')`
  font-weight: 500;
  font-size: 16px;
`

const ItemEditPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { itemId } = useParams()
  const { goTo } = useRoute()
  const isFileUploading = useRecoilValue(isFileUploadingState(FORM_NAME))
  const processedFormFiles = useRecoilValue(processedFormFilesState(FORM_NAME))
  const selectedLocationInfo = useRecoilValue(selectedLocationInfoState)
  const { sendPostRequest, sendPutRequest } = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)
  const portalSetting = useRecoilValue(portalSettingState)
  const [isTranslationDialogOpen, setIsTranslationDialogOpen] = useState(false)
  const [translationDialogTitle, setTranslationDialogTitle] = useState('')
  const [translationInputRows, setTranslationInputRows] = useState(3)
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false)
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const hasNewFile = useRecoilValue(hasNewFileState)
  const hasNewAddress = useRecoilValue(hasNewAddressState)
  const { formatPhoneNumber } = usePortalSetting()
  const translationContext = useRef<
    | 'names'
    | 'descriptions'
    | 'newsFeedUrls'
    | 'eventsFeedUrls'
    | 'disclaimers'
    | null
  >(null)
  const [maxTranslationLength, setMaxTranslationLength] = useState(
    ITEM_DESCRIPTION_MAX_LENGTH,
  )
  const { data: itemData } = useSWR<Item>(
    portalSetting && itemId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/items/${itemId}`
      : null,
  )
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isDirty, dirtyFields },
    getValues,
    trigger,
    watch,
  } = useForm<ItemFormData>({
    mode: 'onTouched',
    defaultValues: {
      contacts: [],
    },
  })

  const privacyMode = watch('privacyMode')
  const names = watch('names')
  const descriptions = watch('descriptions')
  const newsFeedUrls = watch('newsFeedUrls')
  const eventsFeedUrls = watch('eventsFeedUrls')
  const disclaimers = watch('disclaimers')
  const contacts = watch('contacts')
  const searchRadius = watch('searchRadius')

  useEffect(() => {
    if (
      portalSetting?.mapConfiguration?.region &&
      portalSetting.type === PortalType.CITY
    ) {
      setValue(
        'postalDetails.countryPrefix',
        portalSetting.mapConfiguration.region,
      )
    }
  }, [portalSetting])

  useEffect(() => {
    if (itemData) {
      setValue('names', convertLocalizedStringToData(itemData.names))
      setValue('website', itemData.website)
      setValue(
        'descriptions',
        convertLocalizedStringToData(itemData.descriptions),
      )
      setValue(
        'newsFeedUrls',
        convertLocalizedStringToData(itemData.newsFeedUrls),
      )
      setValue(
        'eventsFeedUrls',
        convertLocalizedStringToData(itemData.eventsFeedUrls),
      )
      setValue('contacts', itemData.contacts)
      setValue(
        'disclaimers',
        convertLocalizedStringToData(itemData.disclaimers),
      )

      if (itemData.postalDetails) {
        setValue('postalDetails', itemData.postalDetails)
      }

      setValue(
        'address',
        itemData.address || itemData.locationDetails?.location?.address || '',
      )

      if (itemData.locationDetails?.searchRadius) {
        setValue('searchRadius', itemData.locationDetails?.searchRadius)
      }
      setValue('reportingEmail', itemData.reportingEmail)
      setValue('privacyMode', itemData.privacyMode)
      setValue('anonymousReporting', itemData.anonymousReporting)
      setValue('publicCaseRetentionDays', itemData.publicCaseRetentionDays)
      setValue('notificationsDetails', itemData.notificationsDetails)
    }
  }, [itemData])

  const hasUnsavedChanges = useMemo(
    () => hasNewFile || hasNewAddress || isDirty,
    [hasNewFile, hasNewAddress, isDirty],
  )

  useEffect(() => {
    setHasUnsavedChanges(hasUnsavedChanges)
  }, [hasUnsavedChanges])

  useEffect(() => {
    if (selectedLocationInfo?.address && !itemId && !dirtyFields.address) {
      setValue('address', selectedLocationInfo.address)
    }
  }, [selectedLocationInfo?.address, itemId, dirtyFields.address])

  const onSubmit: SubmitHandler<ItemFormData> = useCallback(
    async (data): Promise<void> => {
      if (!portalSetting) {
        return
      }

      try {
        setIsSaving(true)
        const formData: NewItem = {
          ...data,
          address: data.address,
          names: convertDataToLocalizedString(data.names),
          descriptions: convertDataToLocalizedString(data.descriptions),
          newsFeedUrls: convertDataToLocalizedString(data.newsFeedUrls),
          eventsFeedUrls: convertDataToLocalizedString(data.eventsFeedUrls),
          disclaimers: convertDataToLocalizedString(data.disclaimers),
        }

        if (selectedLocationInfo) {
          formData.locationDetails = {
            searchRadius: data.searchRadius,
            location: selectedLocationInfo,
          }
        }

        if (processedFormFiles.length) {
          formData.logoUrl = processedFormFiles[0].url
        }

        if (data.contacts.length) {
          formData.contacts = data.contacts.map((localizedContacts) => ({
            ...localizedContacts,
            contacts: localizedContacts.contacts.map((contact) => {
              if (contact.type === ItemContactMethodType.PHONE) {
                return {
                  type: ItemContactMethodType.PHONE,
                  contact: formatPhoneNumber(contact.contact),
                }
              }

              return contact
            }),
          }))
        }

        if (itemData) {
          await sendPutRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting?.id
            }/items/${itemData.id}`,
            formData,
          )
        } else {
          await sendPostRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting?.id
            }/items`,
            formData,
          )
        }

        setHasUnsavedChanges(false)
        enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
          variant: 'success',
        })

        goTo(Path.ITEMS_LIST, true)
      } catch (error) {
        console.error(error)
      } finally {
        setIsSaving(false)
      }
    },
    [processedFormFiles, selectedLocationInfo, itemData],
  )

  const handleGoBack = (): void => {
    goTo(Path.ITEMS_LIST)
  }

  const handleOpenNameTranslationDialog = (): void => {
    setIsTranslationDialogOpen(true)
    translationContext.current = 'names'
    setMaxTranslationLength(ITEM_NAME_MAX_LENGTH)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_localization',
      }),
    )
    setTranslationInputRows(1)
  }

  const handleOpenDescriptionTranslationDialog = (): void => {
    setIsTranslationDialogOpen(true)
    translationContext.current = 'descriptions'
    setMaxTranslationLength(ITEM_DESCRIPTION_MAX_LENGTH)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_localization',
      }),
    )
    setTranslationInputRows(3)
  }

  const handleOpenNewsFeedTranslationDialog = (): void => {
    setIsTranslationDialogOpen(true)
    translationContext.current = 'newsFeedUrls'
    setMaxTranslationLength(ITEM_FEED_URL_MAX_LENGTH)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_feeds',
      }),
    )
    setTranslationInputRows(1)
  }

  const handleOpenEventsFeedTranslationDialog = (): void => {
    setIsTranslationDialogOpen(true)
    translationContext.current = 'eventsFeedUrls'
    setMaxTranslationLength(ITEM_FEED_URL_MAX_LENGTH)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_feeds',
      }),
    )
    setTranslationInputRows(1)
  }

  const handleOpenDisclaimerTranslationDialog = (): void => {
    setIsTranslationDialogOpen(true)
    translationContext.current = 'disclaimers'
    setMaxTranslationLength(ITEM_DESCRIPTION_MAX_LENGTH)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_localization',
      }),
    )
    setTranslationInputRows(3)
  }

  const handleSaveTranslation = (data: Record<Locale, string>): void => {
    if (translationContext.current) {
      setValue(translationContext.current, data, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      })
      void trigger(translationContext.current)
    }
    setIsTranslationDialogOpen(false)
  }

  const handleCloseTranslationDialog = (): void => {
    setIsTranslationDialogOpen(false)
  }

  const handleOpenManageContactDialog = (): void => {
    setIsContactDialogOpen(true)
  }

  const handleCloseManageContactDialog = (): void => {
    setIsContactDialogOpen(false)
  }

  const handleSaveContact = (data: LocalizedContact[]): void => {
    setValue('contacts', data)
    void trigger('contacts')
    setIsContactDialogOpen(false)
  }

  const getTranslationDefaultLanguageValue = useCallback((): Record<
    string,
    string
  > => {
    if (translationContext.current) {
      return getValues(translationContext.current)
    }

    return {}
  }, [
    descriptions,
    newsFeedUrls,
    eventsFeedUrls,
    disclaimers,
    names,
    translationContext.current,
  ])

  if (!portalSetting) {
    return null
  }

  const validateTranslations = (
    name: 'names' | 'descriptions' | 'disclaimers',
  ): null | string => {
    const data = getValues(name)
    let missingTranslations: Locale[] = [...portalSetting.supportedLanguages]

    if (data) {
      const keys = Object.keys(data)
      keys.forEach((key) => {
        if (data[key]) {
          missingTranslations = missingTranslations.filter(
            (item) => item !== key,
          )
        }
      })

      if (missingTranslations.length) {
        return missingTranslations.join(', ')
      }
    }

    return null
  }

  const missingNamesTranslation = useMemo(
    () => !!dirtyFields.names && !!validateTranslations('names'),
    [names],
  )

  const shouldDisableSaving = useMemo(
    () =>
      (!isValid ||
        Object.keys(errors).length > 0 ||
        isFileUploading ||
        isSaving ||
        missingNamesTranslation ||
        (!selectedLocationInfo &&
          portalSetting?.type === PortalType.PROPERTY)) &&
      !hasNewFile &&
      !hasNewAddress,
    [
      isValid,
      errors,
      isFileUploading,
      isSaving,
      hasNewFile,
      hasNewAddress,
      selectedLocationInfo,
      portalSetting?.type,
      missingNamesTranslation,
    ],
  )

  const defaultLanguageContacts = useMemo((): LocalizedContact[] | null => {
    const results = contacts?.filter(
      (contact) => contact.language === portalSetting?.defaultLanguage,
    )

    return results
  }, [portalSetting?.defaultLanguage, contacts])

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Stack height={'100%'} width={'100%'}>
          <Stack direction="row" width="100%" spacing={2} marginBottom={2}>
            <Stack flexGrow={1} direction="row" spacing={1}>
              <BackButtonGrey
                onClick={handleGoBack}
                size="small"
                aria-label={formatMessage({
                  id: 'general.icon_button.go_back',
                })}
              >
                <ArrowBackIcon />
              </BackButtonGrey>

              <MainHeader>
                {itemId && formatMessage({ id: 'portal_item_edit.header' })}
                {!itemId && formatMessage({ id: 'portal_item_add.header' })}
              </MainHeader>
            </Stack>

            <LoadingButton
              variant="contained"
              size="small"
              type="submit"
              disabled={shouldDisableSaving}
              loading={isSaving}
              color="secondary"
            >
              {formatMessage({ id: 'general.button.save' })}
            </LoadingButton>
          </Stack>

          <SettingWrapper width="100%" flexGrow={1}>
            <Stack paddingY={2} paddingX={4} spacing={2}>
              <LightSubHeader>
                {formatMessage({
                  id: 'portal_item_edit.step.item_info',
                })}
              </LightSubHeader>

              <Typography variant="body2">
                {formatMessage({
                  id: 'portal_item_edit.step.item_info.text.info_visible_to_users',
                })}
              </Typography>

              <Stack direction="row" spacing={2} width="100%">
                <Box width="150px" height="150px">
                  <ImageUploader
                    formName={FORM_NAME}
                    maxWidth={MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD}
                    maxHeight={MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD}
                    defaultImageUrl={
                      itemData?.logoUrl || portalSetting?.logoUrl
                    }
                  />
                </Box>

                <Stack spacing={2} flexGrow={1}>
                  <ItemNameWrapper>
                    <Stack direction="row" alignItems="center">
                      <Box flexGrow={1}>
                        <FormHelperText error>
                          {!!dirtyFields.names &&
                            !!validateTranslations('names') &&
                            formatMessage(
                              {
                                id: 'portal_item_edit.error.missing_translations',
                              },
                              {
                                missingTranslations:
                                  validateTranslations('names'),
                              },
                            )}
                        </FormHelperText>
                      </Box>
                      <Button
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={handleOpenNameTranslationDialog}
                      >
                        {formatMessage({
                          id: 'portal_item_edit.button.manage_localization',
                        })}
                      </Button>
                    </Stack>

                    <Controller
                      name={`names.${portalSetting.defaultLanguage}`}
                      control={control}
                      rules={{
                        maxLength: ITEM_NAME_MAX_LENGTH,
                        required: true,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          error={!!errors.names}
                          size="small"
                          label={formatMessage({
                            id: 'portal_item_edit.step.item_info.label.name',
                          })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <TopicIcon fontSize="small" color="primary" />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                    {errors.names?.[portalSetting.defaultLanguage]?.type ===
                      'maxLength' && (
                      <FormHelperText error>
                        {formatMessage(
                          {
                            id: 'portal_item_edit.step.item_info.error.max_length_name',
                          },
                          {
                            max: ITEM_NAME_MAX_LENGTH,
                          },
                        )}
                      </FormHelperText>
                    )}
                  </ItemNameWrapper>

                  <FormControl error={!!errors.website} fullWidth>
                    <Controller
                      name="website"
                      control={control}
                      rules={{
                        maxLength: ITEM_WEBSITE_MAX_LENGTH,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          error={!!errors.website}
                          size="small"
                          label={formatMessage({
                            id: 'portal_item_edit.step.item_info.website',
                          })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LanguageIcon
                                  fontSize="small"
                                  color="primary"
                                />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                    {errors.website?.type === 'maxLength' && (
                      <FormHelperText>
                        {formatMessage(
                          {
                            id: 'portal_item_edit.step.item_info.error.max_length_website',
                          },
                          {
                            max: ITEM_WEBSITE_MAX_LENGTH,
                          },
                        )}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <Stack width="100%">
                    <Stack direction="row" alignItems="center">
                      <Box flexGrow={1}>
                        <FormHelperText error>
                          {!!dirtyFields.descriptions &&
                            !!validateTranslations('descriptions') &&
                            formatMessage(
                              {
                                id: 'portal_item_edit.error.missing_translations',
                              },
                              {
                                missingTranslations:
                                  validateTranslations('descriptions'),
                              },
                            )}
                        </FormHelperText>
                      </Box>
                      <Button
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={handleOpenDescriptionTranslationDialog}
                      >
                        {formatMessage({
                          id: 'portal_item_edit.button.manage_localization',
                        })}
                      </Button>
                    </Stack>

                    <FormControl>
                      <Controller
                        name={`descriptions.${portalSetting.defaultLanguage}`}
                        control={control}
                        defaultValue=""
                        rules={{
                          maxLength: ITEM_DESCRIPTION_MAX_LENGTH,
                        }}
                        render={({ field }) => (
                          <MultiLineInput
                            {...field}
                            maxLength={ITEM_DESCRIPTION_MAX_LENGTH}
                            error={
                              !!errors?.descriptions?.[
                                portalSetting.defaultLanguage
                              ]
                            }
                            label={formatMessage(
                              {
                                id: 'portal_item_edit.step.item_info.label.description',
                              },
                              {
                                defaultLanguage: portalSetting.defaultLanguage,
                              },
                            )}
                            variant="outlined"
                            fullWidth
                            rows={3}
                          />
                        )}
                      />
                    </FormControl>

                    <Box textAlign="right">
                      <Button
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={handleOpenNewsFeedTranslationDialog}
                      >
                        {formatMessage({
                          id: 'portal_item_edit.button.manage_feeds',
                        })}
                      </Button>
                    </Box>
                    <FormControl>
                      <Controller
                        name={`newsFeedUrls.${portalSetting.defaultLanguage}`}
                        control={control}
                        defaultValue=""
                        rules={{
                          maxLength: ITEM_FEED_URL_MAX_LENGTH,
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={!!errors.names}
                            size="small"
                            label={formatMessage(
                              {
                                id: 'portal_item_edit.step.item_info.label.news_feed',
                              },
                              {
                                defaultLanguage: portalSetting.defaultLanguage,
                              },
                            )}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <NewspaperIcon
                                    fontSize="small"
                                    color="primary"
                                  />
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>

                    <Box textAlign="right">
                      <Button
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={handleOpenEventsFeedTranslationDialog}
                      >
                        {formatMessage({
                          id: 'portal_item_edit.button.manage_feeds',
                        })}
                      </Button>
                    </Box>
                    <FormControl>
                      <Controller
                        name={`eventsFeedUrls.${portalSetting.defaultLanguage}`}
                        control={control}
                        defaultValue=""
                        rules={{
                          maxLength: ITEM_FEED_URL_MAX_LENGTH,
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={!!errors.names}
                            size="small"
                            label={formatMessage(
                              {
                                id: 'portal_item_edit.step.item_info.label.events_feed',
                              },
                              {
                                defaultLanguage: portalSetting.defaultLanguage,
                              },
                            )}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <FeedIcon fontSize="small" color="primary" />
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Divider />

            <Stack
              paddingY={2}
              paddingX={4}
              spacing={2}
              width={'100%'}
              data-testid="portal-preferences"
            >
              <Stack spacing={2} width="100%">
                {portalSetting.type === PortalType.PROPERTY && (
                  <Stack width="100%" spacing={1}>
                    <SubSubHeader>
                      {formatMessage({
                        id: 'portal_item_edit.step.item_info.label.location',
                      })}{' '}
                      *
                    </SubSubHeader>
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'portal_item_edit.step.item_info.info.location',
                      })}
                    </Typography>

                    {portalSetting && (
                      <AddressInput
                        mapHeight={400}
                        selectedPosition={
                          itemData?.locationDetails?.location?.position
                        }
                        center={portalSetting.mapConfiguration?.centre}
                        markerRadius={searchRadius}
                        region={portalSetting.mapConfiguration?.region}
                        language={portalSetting.defaultLanguage.toLowerCase()}
                        zoom={portalSetting.mapConfiguration?.zoomLevel}
                      />
                    )}

                    <FormFieldsWrapper>
                      <Stack direction="row">
                        <Box width={300}>
                          <Typography variant="body2">
                            {formatMessage({
                              id: 'portal_item_edit.step.item_info.info.search_range',
                            })}
                          </Typography>
                        </Box>
                        <Box flexGrow={1}>
                          <Controller
                            name="searchRadius"
                            control={control}
                            defaultValue={50}
                            render={({ field }) => (
                              <Slider
                                {...field}
                                step={25}
                                size="small"
                                min={25}
                                max={1000}
                                valueLabelDisplay="on"
                                marks={[
                                  {
                                    value: 25,
                                    label: '25m',
                                  },
                                  {
                                    value: 1000,
                                    label: '1km',
                                  },
                                ]}
                              />
                            )}
                          />
                        </Box>
                      </Stack>
                    </FormFieldsWrapper>
                  </Stack>
                )}

                {portalSetting.type === PortalType.CITY && (
                  <Stack width="100%" spacing={1}>
                    <SubSubHeader>
                      {formatMessage({
                        id: 'portal_item_edit.step.item_info.label.area',
                      })}{' '}
                    </SubSubHeader>
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'portal_item_edit.step.item_info.text.info_area',
                      })}
                    </Typography>
                    <FormControl
                      fullWidth
                      error={!!errors.postalDetails?.countryPrefix}
                    >
                      <InputLabel id="country-code-label" size="small" required>
                        {formatMessage({
                          id: 'portal_item_edit.label.country_code',
                        })}
                      </InputLabel>
                      <Controller
                        name="postalDetails.countryPrefix"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        defaultValue={
                          itemData?.postalDetails?.countryPrefix ??
                          SUPPORT_REGIONS[0]
                        }
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="country-code-label"
                            label={formatMessage({
                              id: 'portal_item_edit.label.country_code',
                            })}
                            size="small"
                            fullWidth
                            variant="outlined"
                          >
                            {SUPPORT_REGIONS.map((region) => (
                              <MenuItem key={region} value={region}>
                                {formatMessage({
                                  id: SUPPORT_REGION_LABEL[region],
                                })}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                    <FormControl error={!!errors.postalDetails?.postalCodes}>
                      <Controller
                        name="postalDetails.postalCodes"
                        control={control}
                        rules={{
                          maxLength: ITEM_ZIP_CODES_MAX_LENGTH,
                          required: true,
                        }}
                        defaultValue=""
                        render={({ field }) => (
                          <MultiLineInput
                            {...field}
                            required
                            maxLength={ITEM_ZIP_CODES_MAX_LENGTH}
                            label={formatMessage({
                              id: 'portal_item_edit.step.item_info.label.zip_codes',
                            })}
                            variant="outlined"
                            fullWidth
                            rows={3}
                            error={!!errors.postalDetails?.postalCodes}
                            helpMessage={formatMessage({
                              id: 'portal_item_edit.help_text.zip_codes',
                            })}
                          />
                        )}
                      />
                    </FormControl>
                  </Stack>
                )}

                <SubSubHeader>
                  {formatMessage({
                    id: 'portal_item_edit.step.item_info.label.address',
                  })}{' '}
                  {portalSetting.type === PortalType.PROPERTY && '*'}
                </SubSubHeader>
                <Typography variant="body2">
                  {formatMessage({
                    id: 'portal_item_edit.city.info.address',
                  })}
                </Typography>
                <FormFieldsWrapper>
                  <FormControl error={!!errors.address} fullWidth>
                    <Controller
                      name="address"
                      control={control}
                      rules={{
                        maxLength: SHORT_TEXT_MAX_LENGTH,
                        required: portalSetting.type === PortalType.PROPERTY,
                      }}
                      defaultValue={''}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required={portalSetting.type === PortalType.PROPERTY}
                          error={!!errors.address}
                          size="small"
                          label={formatMessage({
                            id: 'portal_item_edit.step.item_info.label.address',
                          })}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LocationOnIcon
                                  fontSize="small"
                                  color="primary"
                                />
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                    {errors.address?.type === 'maxLength' && (
                      <FormHelperText>
                        {formatMessage(
                          {
                            id: 'general.error.max_length',
                          },
                          {
                            max: SHORT_TEXT_MAX_LENGTH,
                          },
                        )}
                      </FormHelperText>
                    )}
                    {errors.address?.type === 'required' && (
                      <FormHelperText>
                        {formatMessage({
                          id: 'general.error.required',
                        })}
                      </FormHelperText>
                    )}
                  </FormControl>
                </FormFieldsWrapper>

                <Stack width="100%" spacing={1}>
                  <SubSubHeader>
                    {formatMessage({
                      id: 'portal_item_edit.step.item_info.label.contact_info',
                    })}
                  </SubSubHeader>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'portal_item_edit.step.item_info.text.contacts_info',
                    })}
                  </Typography>
                  <FormFieldsWrapper>
                    <Box textAlign="right">
                      <Button
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={handleOpenManageContactDialog}
                      >
                        {formatMessage({
                          id: 'portal_item_edit.button.manage_contact',
                        })}
                      </Button>
                    </Box>

                    {defaultLanguageContacts?.map((defaultLanguageContact) => (
                      <Stack
                        key={defaultLanguageContact.name}
                        direction="row"
                        spacing={2}
                      >
                        <Stack flexGrow={1}>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <AccountBoxIcon fontSize="small" color="primary" />
                            <Box>{defaultLanguageContact.name}</Box>
                          </Stack>
                        </Stack>
                        <Divider orientation="vertical" flexItem></Divider>
                        <Stack spacing={1} width="30%">
                          {defaultLanguageContact.contacts.map((contact) => (
                            <Stack
                              key={contact.contact}
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              {contact.type === ItemContactMethodType.PHONE && (
                                <PhoneIcon fontSize="small" />
                              )}

                              {contact.type === ItemContactMethodType.EMAIL && (
                                <EmailIcon fontSize="small" />
                              )}

                              <Box>{contact.contact}</Box>
                            </Stack>
                          ))}
                        </Stack>
                      </Stack>
                    ))}
                  </FormFieldsWrapper>
                </Stack>
              </Stack>
            </Stack>

            <Divider />

            <Stack paddingY={2} paddingX={4} spacing={2}>
              <LightSubHeader>
                {formatMessage({
                  id: 'portal_item_edit.step.item_privacy_modes',
                })}
              </LightSubHeader>

              <Stack spacing={2} flexGrow={1}>
                <FormFieldsWrapper>
                  <Controller
                    name="privacyMode"
                    control={control}
                    defaultValue={
                      itemData?.privacyMode ||
                      ItemPrivacyMode.COMMUNITY_MODE_PRIVATE_REPORTING
                    }
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        onChange={(e) => {
                          field.onChange(e)
                          setValue(
                            'privacyMode',
                            e.target.value as ItemPrivacyMode,
                          )
                        }}
                      >
                        <RadioWrapper
                          key={ItemPrivacyMode.COMMUNITY_MODE_PRIVATE_REPORTING}
                          value={
                            ItemPrivacyMode.COMMUNITY_MODE_PRIVATE_REPORTING
                          }
                          selected={
                            privacyMode ===
                            ItemPrivacyMode.COMMUNITY_MODE_PRIVATE_REPORTING
                              ? 'selected'
                              : ''
                          }
                          control={<Radio />}
                          labelPlacement="start"
                          label={
                            <Stack spacing={1} flexGrow={1}>
                              <Stack direction={'row'} spacing={1}>
                                <CommunityPrivateIcon />
                                <PrivacyTitle>
                                  {formatMessage({
                                    id: 'portal_item_edit.label.community_mode_private_reporting',
                                  })}
                                </PrivacyTitle>
                              </Stack>

                              <Stack spacing={0.4} paddingLeft={4}>
                                <Stack direction="row" spacing={1}>
                                  <PrivacyModeCheckIcon />
                                  <SmallText>
                                    {formatMessage({
                                      id: 'portal_item_edit.text.registered_customer_view_info',
                                    })}
                                  </SmallText>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                  <PrivacyModeCheckIcon />
                                  <SmallText>
                                    {formatMessage({
                                      id: 'portal_item_edit.text.registered_customer_can_report',
                                    })}
                                  </SmallText>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                  <PrivacyModeCheckIcon />
                                  <SmallText>
                                    {formatMessage({
                                      id: 'portal_item_edit.text.cases_visible_for_reporter',
                                    })}
                                  </SmallText>
                                </Stack>
                              </Stack>
                            </Stack>
                          }
                        />
                        <RadioWrapper
                          key={ItemPrivacyMode.COMMUNITY_MODE}
                          value={ItemPrivacyMode.COMMUNITY_MODE}
                          selected={
                            privacyMode === ItemPrivacyMode.COMMUNITY_MODE
                              ? 'selected'
                              : ''
                          }
                          control={<Radio />}
                          labelPlacement="start"
                          label={
                            <Stack spacing={1} flexGrow={1}>
                              <Stack direction={'row'} spacing={1}>
                                <CommunityIcon />
                                <PrivacyTitle>
                                  {formatMessage({
                                    id: 'portal_item_edit.label.community_mode',
                                  })}
                                </PrivacyTitle>
                              </Stack>

                              <Stack spacing={0.4} paddingLeft={4}>
                                <Stack direction="row" spacing={1}>
                                  <PrivacyModeCheckIcon />
                                  <SmallText>
                                    {formatMessage({
                                      id: 'portal_item_edit.text.registered_customer_view_info',
                                    })}
                                  </SmallText>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                  <PrivacyModeCheckIcon />
                                  <SmallText>
                                    {formatMessage({
                                      id: 'portal_item_edit.text.registered_customer_can_report',
                                    })}
                                  </SmallText>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                  <PrivacyModeCheckIcon />
                                  <Stack>
                                    <SmallText margin={0}>
                                      {formatMessage({
                                        id: 'portal_item_edit.text.shared_cases_visible_for_registered_customers',
                                      })}
                                    </SmallText>
                                    <SmallText margin={0}>
                                      {formatMessage({
                                        id: 'portal_item_edit.text.private_cases_visible_for_reporter',
                                      })}
                                    </SmallText>
                                  </Stack>
                                </Stack>
                              </Stack>
                            </Stack>
                          }
                        />
                        <RadioWrapper
                          key={ItemPrivacyMode.OPEN_MODE_PRIVATE_REPORTING}
                          value={ItemPrivacyMode.OPEN_MODE_PRIVATE_REPORTING}
                          selected={
                            privacyMode ===
                            ItemPrivacyMode.OPEN_MODE_PRIVATE_REPORTING
                              ? 'selected'
                              : ''
                          }
                          control={<Radio />}
                          labelPlacement="start"
                          label={
                            <Stack spacing={1} flexGrow={1}>
                              <Stack direction={'row'} spacing={1}>
                                <OpenModePrivateIcon />
                                <PrivacyTitle>
                                  {formatMessage({
                                    id: 'portal_item_edit.label.open_mode_private_reporting',
                                  })}
                                </PrivacyTitle>
                              </Stack>

                              <Stack spacing={0.4} paddingLeft={4}>
                                <Stack direction="row" spacing={1}>
                                  <PrivacyModeCheckIcon />
                                  <SmallText>
                                    {formatMessage({
                                      id: 'portal_item_edit.anyone_can_see_info',
                                    })}
                                  </SmallText>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                  <PrivacyModeCheckIcon />
                                  <SmallText>
                                    {formatMessage({
                                      id: 'portal_item_edit.anyone_can_report_via_app',
                                    })}
                                  </SmallText>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                  <PrivacyModeCheckIcon />
                                  <SmallText>
                                    {formatMessage({
                                      id: 'portal_item_edit.text.cases_visible_for_reporter',
                                    })}
                                  </SmallText>
                                </Stack>
                              </Stack>
                            </Stack>
                          }
                        />
                        <RadioWrapper
                          key={ItemPrivacyMode.OPEN_MODE}
                          value={ItemPrivacyMode.OPEN_MODE}
                          selected={
                            privacyMode === ItemPrivacyMode.OPEN_MODE
                              ? 'selected'
                              : ''
                          }
                          control={<Radio />}
                          labelPlacement="start"
                          label={
                            <Stack spacing={1} flexGrow={1}>
                              <Stack direction={'row'} spacing={1}>
                                <OpenModeIcon />
                                <PrivacyTitle>
                                  {formatMessage({
                                    id: 'portal_item_edit.label.open_mode',
                                  })}
                                </PrivacyTitle>
                              </Stack>

                              <Stack spacing={0.4} paddingLeft={4}>
                                <Stack direction="row" spacing={1}>
                                  <PrivacyModeCheckIcon />
                                  <SmallText>
                                    {formatMessage({
                                      id: 'portal_item_edit.anyone_can_see_info',
                                    })}
                                  </SmallText>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                  <PrivacyModeCheckIcon />
                                  <SmallText>
                                    {formatMessage({
                                      id: 'portal_item_edit.anyone_can_report',
                                    })}
                                  </SmallText>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                  <PrivacyModeCheckIcon />
                                  <Stack>
                                    <SmallText>
                                      {formatMessage({
                                        id: 'portal_item_edit.text.shared_cases_visible_anyone',
                                      })}
                                    </SmallText>
                                    <SmallText>
                                      {formatMessage({
                                        id: 'portal_item_edit.text.private_cases_visible_for_reporter',
                                      })}
                                    </SmallText>
                                  </Stack>
                                </Stack>
                              </Stack>
                            </Stack>
                          }
                        />
                      </RadioGroup>
                    )}
                  />
                </FormFieldsWrapper>

                {(privacyMode === ItemPrivacyMode.COMMUNITY_MODE ||
                  privacyMode === ItemPrivacyMode.OPEN_MODE) && (
                  <FormFieldsWrapper>
                    <SubSubHeader>
                      {formatMessage({
                        id: 'portal_item_edit.subheader.visibility_period',
                      })}
                    </SubSubHeader>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Box flexGrow={1}>
                        <Typography variant="body2">
                          {formatMessage({
                            id: 'portal_item_edit.visibility_period.description',
                          })}
                        </Typography>
                      </Box>
                      <FormControl error={!!errors.publicCaseRetentionDays}>
                        <Controller
                          name="publicCaseRetentionDays"
                          control={control}
                          rules={{
                            validate: (val): boolean => {
                              if (
                                !!val &&
                                !Number.isNaN(Number(val)) &&
                                val > 0
                              ) {
                                return true
                              }
                              return false
                            },
                          }}
                          defaultValue={ITEM_PUBLIC_RETENTION_DAYS}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              required={true}
                              type="number"
                              error={!!errors.publicCaseRetentionDays}
                              size="small"
                              InputProps={{
                                endAdornment: formatMessage({
                                  id: 'portal_item_edit.visibility_period.days',
                                }),
                              }}
                              variant="outlined"
                            />
                          )}
                        />
                        {!!errors.publicCaseRetentionDays && (
                          <FormHelperText>
                            {formatMessage({
                              id: 'portal_item_edit.visibility_period.days.error',
                            })}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Stack>
                  </FormFieldsWrapper>
                )}
              </Stack>
            </Stack>

            <Divider />

            <Stack paddingY={2} paddingX={4} spacing={2}>
              <LightSubHeader>
                {formatMessage({
                  id: 'portal_item_edit.step.report_preferences',
                })}
              </LightSubHeader>

              <Stack spacing={2} flexGrow={1}>
                {portalSetting.virtual && (
                  <FormFieldsWrapper>
                    <Typography variant="body2">
                      {formatMessage({
                        id: 'portal_item_edit.label.reporting_email',
                      })}
                    </Typography>
                    <FormControl error={!!errors.reportingEmail} fullWidth>
                      <Controller
                        name="reportingEmail"
                        control={control}
                        rules={{
                          maxLength: EMAIL_MAX_LENGTH,
                          required: true,
                        }}
                        defaultValue={''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={!!errors.reportingEmail}
                            required
                            size="small"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailIcon fontSize="small" />
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                      {errors.reportingEmail?.type === 'maxLength' && (
                        <FormHelperText>
                          {formatMessage(
                            {
                              id: 'general.error.max_length',
                            },
                            {
                              max: EMAIL_MAX_LENGTH,
                            },
                          )}
                        </FormHelperText>
                      )}
                      {errors.reportingEmail?.type === 'required' && (
                        <FormHelperText>
                          {formatMessage({
                            id: 'general.error.required',
                          })}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </FormFieldsWrapper>
                )}

                <FormFieldsWrapper>
                  <Stack width="100%">
                    <Stack direction="row" alignItems="center">
                      <Box flexGrow={1}>
                        <FormHelperText error>
                          {!!dirtyFields.disclaimers &&
                            !!validateTranslations('disclaimers') &&
                            formatMessage(
                              {
                                id: 'portal_item_edit.error.missing_translations',
                              },
                              {
                                missingTranslations:
                                  validateTranslations('disclaimers'),
                              },
                            )}
                        </FormHelperText>
                      </Box>
                      <Button
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={handleOpenDisclaimerTranslationDialog}
                      >
                        {formatMessage({
                          id: 'portal_item_edit.button.manage_localization',
                        })}
                      </Button>
                    </Stack>

                    <FormControl>
                      <Controller
                        name={`disclaimers.${portalSetting.defaultLanguage}`}
                        control={control}
                        rules={{
                          maxLength: ITEM_REPORT_DISCLAIMER_MAX_LENGTH,
                        }}
                        defaultValue=""
                        render={({ field }) => (
                          <MultiLineInput
                            {...field}
                            error={
                              !!errors?.disclaimers?.[
                                portalSetting.defaultLanguage
                              ]
                            }
                            maxLength={ITEM_REPORT_DISCLAIMER_MAX_LENGTH}
                            label={formatMessage(
                              {
                                id: 'portal_item_edit.step.report_preferences.label.disclaimer',
                              },
                              {
                                defaultLanguage: portalSetting.defaultLanguage,
                              },
                            )}
                            variant="outlined"
                            fullWidth
                            rows={3}
                          />
                        )}
                      />
                    </FormControl>
                  </Stack>
                </FormFieldsWrapper>

                <FormFieldsWrapper>
                  <Stack direction="row" alignItems="center">
                    <Box flexGrow={1}>
                      <Typography variant="body2">
                        {formatMessage({
                          id: 'portal_item_edit.label.allow_anonymous_reporting',
                        })}
                      </Typography>
                    </Box>
                    <Controller
                      name="anonymousReporting"
                      control={control}
                      defaultValue={itemData?.anonymousReporting ?? false}
                      render={({ field }) => (
                        <Switch
                          {...field}
                          checked={field.value}
                          color="primary"
                          onChange={(e) => {
                            field.onChange(e)
                            setValue('anonymousReporting', e.target.checked)
                          }}
                        />
                      )}
                    />
                  </Stack>
                </FormFieldsWrapper>

                <SubSubHeader>
                  {formatMessage({
                    id: 'portal_item_edit.label.auto_email_notifications',
                  })}
                </SubSubHeader>
                <Typography variant="body2">
                  {formatMessage({
                    id: 'portal_item_edit.text.auto_email_notification_info',
                  })}
                </Typography>
                <FormFieldsWrapper>
                  <Stack>
                    <Stack direction="row" alignItems="center">
                      <Box flexGrow={1}>
                        <Typography variant="body2">
                          {formatMessage({
                            id: 'portal_item_edit.label.send_notification_when_case_created',
                          })}
                        </Typography>
                      </Box>
                      <Controller
                        name="notificationsDetails.emailToReporterWhenCaseCreated"
                        control={control}
                        defaultValue={
                          itemData?.notificationsDetails
                            .emailToReporterWhenCaseCreated ?? false
                        }
                        render={({ field }) => (
                          <Switch
                            {...field}
                            checked={field.value}
                            color="primary"
                            onChange={(e) => {
                              field.onChange(e)
                              setValue(
                                'notificationsDetails.emailToReporterWhenCaseCreated',
                                e.target.checked,
                              )
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <Box flexGrow={1}>
                        <Typography variant="body2">
                          {formatMessage({
                            id: 'portal_item_edit.label.send_notification_when_comment_added',
                          })}
                        </Typography>
                      </Box>
                      <Controller
                        name="notificationsDetails.emailToReporterWhenCaseCommentedByMember"
                        control={control}
                        defaultValue={
                          itemData?.notificationsDetails
                            .emailToReporterWhenCaseCommentedByMember ?? false
                        }
                        render={({ field }) => (
                          <Switch
                            {...field}
                            checked={field.value}
                            color="primary"
                            onChange={(e) => {
                              field.onChange(e)
                              setValue(
                                'notificationsDetails.emailToReporterWhenCaseCommentedByMember',
                                e.target.checked,
                              )
                            }}
                          />
                        )}
                      />
                    </Stack>
                  </Stack>
                </FormFieldsWrapper>
              </Stack>
            </Stack>
          </SettingWrapper>
        </Stack>
      </form>

      <TranslationDialog
        isOpen={isTranslationDialogOpen}
        title={translationDialogTitle}
        rows={translationInputRows}
        defaultValue={getTranslationDefaultLanguageValue()}
        maxLength={maxTranslationLength}
        onSave={handleSaveTranslation}
        onClose={handleCloseTranslationDialog}
      />
      <ContactEditDialog
        isOpen={isContactDialogOpen}
        defaultLanguage={portalSetting.defaultLanguage.toUpperCase()}
        defaultValue={getValues('contacts')}
        title={formatMessage({
          id: 'portal_item_edit.button.manage_contact',
        })}
        onSave={handleSaveContact}
        onClose={handleCloseManageContactDialog}
      />
    </>
  )
}

export default ItemEditPage
