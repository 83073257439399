import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import styled from '@mui/material/styles/styled'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import DarkThemeIcon from '@mui/icons-material/Brightness4'
import LightThemeIcon from '@mui/icons-material/Brightness7'

import {
  currentLocaleState,
  portalSettingState,
} from 'state/portalSettingStates'
import {
  DEFAULT_LOCALE,
  SUPPORT_LOCALES,
  SUPPORT_LOCALE_LABEL,
  ThemeMode,
} from '../../commonConstants'
import { type Locale } from 'types'
import Select from '@mui/material/Select'
import EportiLogo from 'assets/icons/eporti_logo.svg'
import EportiLogoDark from 'assets/icons/eporti_logo_dark.svg'
import { themeModeState } from 'state/layoutStates'

const TopBannerWrapper = styled(Stack)`
  box-shadow: 0px 4px 8px 0px rgba(35, 45, 66, 0.05);
  background: ${({ theme }) => theme.palette.background.paper};
  height: 56px;
  align-items: center;
  justify-content: center;
`

const LogoWrapper = styled(Box)`
  position: relative;
  display: flex;
  overflow: hidden;
  max-height: 50px;
  flex-grow: 1;

  & img {
    max-width: 100%;
    max-height: 50px;
    display: block;
    cursor: pointer;
  }

  font-size: 25px;
  font-weight: 500;
`

const LoginTopBanner: React.FC = () => {
  const { formatMessage } = useIntl()
  const portalSetting = useRecoilValue(portalSettingState)
  const [userLanguage, setUserLanguage] = useState(
    localStorage.getItem('userLanguage') ??
      portalSetting?.defaultLanguage ??
      DEFAULT_LOCALE,
  )
  const setCurrentLocale = useSetRecoilState(currentLocaleState)
  const [themeMode, setThemeMode] = useRecoilState(themeModeState)

  const handleSelectLanguage = (language: Locale): void => {
    localStorage.setItem('userLanguage', String(language))
    setUserLanguage(language)
    setCurrentLocale(language)
  }

  const handleToggleTheme = useCallback((): void => {
    const newThemeMode =
      themeMode === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT
    localStorage.setItem('themeMode', newThemeMode)
    setThemeMode(newThemeMode)
  }, [themeMode])

  return (
    <TopBannerWrapper sx={{ flexGrow: 1 }}>
      <Stack direction="row" width="560px" maxWidth="100vw">
        <LogoWrapper>
          {themeMode === ThemeMode.LIGHT ? <EportiLogo /> : <EportiLogoDark />}
        </LogoWrapper>

        <Select
          data-testid="language-select"
          value={userLanguage}
          size="small"
          aria-label={formatMessage({
            id: 'general.select.language',
          })}
        >
          {SUPPORT_LOCALES.map((language) => (
            <MenuItem
              key={language}
              value={language}
              onClick={(): void => {
                handleSelectLanguage(language)
              }}
            >
              {SUPPORT_LOCALE_LABEL[language]}
            </MenuItem>
          ))}
        </Select>

        <IconButton
          sx={{ ml: 1 }}
          onClick={handleToggleTheme}
          color="inherit"
          aria-label={formatMessage({
            id:
              themeMode === ThemeMode.DARK
                ? 'top_banner.light_theme'
                : 'top_banner.dark_theme',
          })}
        >
          {themeMode === ThemeMode.DARK ? (
            <LightThemeIcon />
          ) : (
            <DarkThemeIcon />
          )}
        </IconButton>
      </Stack>
    </TopBannerWrapper>
  )
}

export default LoginTopBanner
