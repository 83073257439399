import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { type ThemeOptions } from '@mui/material/styles/createTheme'

const CommonThemeOptions: ThemeOptions = {
  typography: {
    fontFamily: [
      'Poppins',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
          boxShadow: 'none',
          ':hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: ExpandMoreIcon,
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontSize: '14px',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        expandIconWrapper: {
          background: '#F5F6FE',
          borderRadius: '12px',
          color: '#07208D',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          background: '#E9ECFC',
          color: '#07208d',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          textDecoration: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '20px 24px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
  },
}

export const lightThemeOptions: ThemeOptions = {
  ...CommonThemeOptions,
  palette: {
    mode: 'light',
    common: {
      black: '#000621',
    },
    primary: {
      main: '#07208D',
      dark: '#061A71',
      light: '#E9ECFC',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FF4869',
      dark: '#CF3E5D',
      light: '#FFE8F2',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#00C875',
      dark: '#009055',
      light: '#DCF5E7',
    },
    error: {
      main: '#DF2E4A',
      dark: '#B62948',
      light: '#FFF0F3',
    },
    background: {
      default: '#F3F3F7',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#041355',
      secondary: '#4C5DA6',
      disabled: '#A9AFCA',
    },
    divider: '#E9ECFC',
    info: {
      main: '#F5F6FE',
      light: '#F5F6FE',
      dark: '#F7F7F7',
    },
  },
}

export const darkThemeOptions: ThemeOptions = {
  ...CommonThemeOptions,
  palette: {
    mode: 'dark',
    common: {
      black: '#000621',
    },
    primary: {
      main: '#A3B2EC',
      dark: '#6A79BB',
      light: '#565880',
      contrastText: '#000621',
    },
    secondary: {
      main: '#FF4869',
      dark: '#CF3E5D',
      light: '#6C112C',
    },
    success: {
      main: '#00C875',
      dark: '#009055',
      light: '#1F4434',
    },
    error: {
      main: '#FF8594',
      dark: '#B62948',
      light: '#55384D',
    },
    background: {
      default: '#000621',
      paper: '#30324E',
    },
    text: {
      primary: '#E9ECFC',
      secondary: '#92A0D8',
      disabled: '#777D99',
    },
    divider: '#4C4E69',
    info: {
      main: '#A3B2EC',
      light: '#202339',
    },
  },
  components: {
    ...CommonThemeOptions.components,
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
          boxShadow: 'none',
          ':hover': {
            boxShadow: 'none',
          },
        },
        text: {
          color: '#E9ECFC',
        },
      },
    },
  },
}
