import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import MenuItem from '@mui/material/MenuItem'
import styled from '@mui/material/styles/styled'
import ListItemIcon from '@mui/material/ListItemIcon'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import LoadingButton from '@mui/lab/LoadingButton'
import LockIcon from '@mui/icons-material/Lock'
import Menu from '@mui/material/Menu'
import ListItemText from '@mui/material/ListItemText'
import Chip from '@mui/material/Chip'

import { CASE_SHAREABILITY_LABEL, Shareability } from './caseConstants'
import { getShareabilityIcon } from 'components/case/caseUtils'

type CaseShareabilityButtonProps = {
  shareability?: Shareability
  loading?: boolean
  onUpdateShareability: (shareability: Shareability) => void
}

const ShareabilityButton = styled(LoadingButton)<{
  shareability: Shareability
}>`
  background: ${({ shareability, theme }) => {
    if (shareability === Shareability.SHAREABLE) {
      return theme.palette.primary.light
    }

    if (shareability === Shareability.PRIVATE) {
      return theme.palette.secondary.main
    }

    return theme.palette.primary.main
  }};

  color: ${({ shareability, theme }) =>
    shareability === Shareability.SHAREABLE
      ? theme.palette.primary.main
      : 'white'};
  border: none;
  border-radius: ${({ theme }) => 1.5 * theme.shape.borderRadius}px;
  font-size: 12px;

  :hover {
    border: none;
    background: ${({ shareability, theme }) => {
      if (shareability === Shareability.SHAREABLE) {
        return theme.palette.primary.light
      }

      if (shareability === Shareability.PRIVATE) {
        return theme.palette.secondary.main
      }

      return theme.palette.primary.main
    }};
  }

  & svg {
    font-size: 16px;
  }

  > .MuiSelect-select {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};
    padding: 2px 8px;

    > .MuiListItemIcon-root {
      min-width: 0;
    }
  }
`

const CaseShareabilityButton: React.FC<CaseShareabilityButtonProps> = ({
  shareability,
  loading = false,
  onUpdateShareability,
}) => {
  const { formatMessage } = useIntl()
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(menuEl)

  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      setMenuEl(event.currentTarget)
    },
    [shareability],
  )

  const handleCloseMenu = (): void => {
    setMenuEl(null)
  }

  const renderShareabilityMenuIcon = (
    shareability: Shareability,
  ): React.ReactNode => {
    const Icon = getShareabilityIcon(shareability)

    return <>{Icon && <Icon fontSize="small" />}</>
  }

  const renderShareabilityButtonIcon = (
    shareability: Shareability,
  ): React.ReactNode => {
    const Icon = getShareabilityIcon(shareability)

    return <>{Icon && <Icon fontSize="small" />}</>
  }

  const handleUpdateShareability = (shareability: Shareability): void => {
    onUpdateShareability(shareability)
    handleCloseMenu()
  }

  if (!shareability) {
    return null
  }

  if (shareability === Shareability.PRIVATE) {
    return (
      <Chip
        icon={<LockIcon sx={{ fontSize: 16 }} />}
        label={formatMessage({
          id: CASE_SHAREABILITY_LABEL[Shareability.PRIVATE],
        })}
        color="secondary"
        sx={{
          height: 26,
        }}
      />
    )
  }

  return (
    <>
      <ShareabilityButton
        loading={loading}
        variant="outlined"
        size="small"
        shareability={shareability}
        startIcon={renderShareabilityButtonIcon(shareability)}
        endIcon={menuEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={handleOpenMenu}
      >
        {formatMessage({
          id: CASE_SHAREABILITY_LABEL[shareability],
        })}
      </ShareabilityButton>

      <Menu anchorEl={menuEl} open={openMenu} onClose={handleCloseMenu}>
        {Object.values(Shareability)
          .filter((value) => value !== shareability)
          .map((value) => (
            <MenuItem
              value={value}
              key={value}
              selected={value === shareability}
              onClick={(): void => {
                handleUpdateShareability(value)
              }}
            >
              <ListItemIcon>{renderShareabilityMenuIcon(value)}</ListItemIcon>
              <ListItemText
                primary={formatMessage({
                  id: CASE_SHAREABILITY_LABEL[value],
                })}
              />
            </MenuItem>
          ))}
      </Menu>
    </>
  )
}

export default CaseShareabilityButton
