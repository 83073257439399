import { nanoid } from 'nanoid'
import { type ResizedImageData } from './fileTypes'
import {
  IMAGE_EXTENSIONS,
  DOCUMENT_EXTENSIONS,
  EMAIL_EXTENSIONS,
} from 'utils/fileConstants'
import { FileType } from '../commonConstants'
import { type Resource } from 'types'

const getFileExtension = (filename: string): string | null => {
  const filenameArray = filename.split('.')
  const extension = filenameArray.pop()

  return extension?.toLowerCase() ?? null
}

const isImageFile = (filename: string): boolean => {
  const extension = getFileExtension(filename)
  return !!extension && IMAGE_EXTENSIONS.includes(`.${extension}`)
}

const isPdfFile = (filename: string): boolean => {
  const extension = getFileExtension(filename)
  return extension === 'pdf'
}

const isDocFile = (filename: string): boolean => {
  const extension = getFileExtension(filename)
  return !!extension && DOCUMENT_EXTENSIONS.includes(`.${extension}`)
}

const isEmailFile = (filename: string): boolean => {
  const filenameArray = filename.split('.')
  const extension = filenameArray.pop()

  return !!extension && EMAIL_EXTENSIONS.includes(`.${extension}`)
}

const generateFileFormat = (filename: string): FileType => {
  if (isImageFile(filename)) {
    return FileType.IMAGE
  } else if (isPdfFile(filename)) {
    return FileType.PDF
  } else if (isDocFile(filename)) {
    return FileType.DOC
  } else if (isEmailFile(filename)) {
    return FileType.EMAIL
  }

  return FileType.OTHER
}

function generateCanvasElement(args: {
  width: number
  height: number
}): HTMLCanvasElement {
  const { width, height } = args
  const canvasElement = window.document.createElement('canvas')
  canvasElement.height = height
  canvasElement.width = width

  return canvasElement
}

async function createImage(base64: string): Promise<HTMLImageElement> {
  return await new Promise((resolve, reject) => {
    try {
      const image = new Image()
      image.src = base64

      image.onerror = (e): void => {
        reject(e)
      }

      image.onload = (): void => {
        resolve(image)
      }
    } catch (e) {
      reject(e)
    }
  })
}

async function convertToBlobViaCanvas(
  canvas: HTMLCanvasElement,
  toJpg: boolean,
): Promise<Blob> {
  return await new Promise((resolve, reject) => {
    try {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            const newBlob = new Blob([blob], {
              type: toJpg ? 'image/jpeg' : 'image/png',
            })
            resolve(newBlob)
          } else {
            reject(new Error('Could not create blob'))
          }
        },
        toJpg ? 'image/jpeg' : undefined,
        toJpg ? 0.8 : undefined,
      )
    } catch (e) {
      reject(e)
    }
  })
}

async function generateBase64FromBlob(blob: Blob): Promise<string> {
  return await new Promise((resolve, reject) => {
    try {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onload = (event): void => {
        if (event.target && typeof event.target.result === 'string') {
          resolve(event.target.result)
        }
      }

      reader.onerror = (event): void => {
        reject(event)
      }
    } catch (e) {
      reject(e)
    }
  })
}

async function shrinkImageViaCanvasElement(args: {
  imageBase64: string
  maxWidth: number
  maxHeight: number
  toJpg: boolean
}): Promise<ResizedImageData> {
  const { imageBase64, maxWidth, maxHeight, toJpg } = args
  const image = await createImage(imageBase64)

  let { width, height } = image

  const canvasElement = generateCanvasElement({ width, height })
  const canvasContext = canvasElement.getContext('2d')

  if (!canvasContext) {
    throw new Error('No canvas context found')
  }

  canvasContext.drawImage(image, 0, 0, width, height)

  // Trim the image
  const imgData = canvasContext.getImageData(0, 0, width, height)
  const { data } = imgData
  let top = height
  let bottom = 0
  let left = width
  let right = 0

  const isWhiteOrTransparent = (
    r: number,
    g: number,
    b: number,
    a: number,
  ): boolean => (r === 255 && g === 255 && b === 255 && a === 255) || a === 0

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const index = (y * width + x) * 4
      if (
        !isWhiteOrTransparent(
          data[index],
          data[index + 1],
          data[index + 2],
          data[index + 3],
        )
      ) {
        if (y < top) top = y
        if (y > bottom) bottom = y
        if (x < left) left = x
        if (x > right) right = x
      }
    }
  }

  const trimmedWidth = right - left
  const trimmedHeight = bottom - top

  const trimmedCanvasElement = generateCanvasElement({
    width: trimmedWidth,
    height: trimmedHeight,
  })
  const trimmedCanvasContext = trimmedCanvasElement.getContext('2d')

  if (!trimmedCanvasContext) {
    throw new Error('No canvas context found')
  }

  trimmedCanvasContext.drawImage(
    canvasElement,
    left,
    top,
    trimmedWidth,
    trimmedHeight,
    0,
    0,
    trimmedWidth,
    trimmedHeight,
  )

  width = trimmedWidth
  height = trimmedHeight

  if (width > maxWidth || height > maxHeight) {
    const aspectRatio = width / height

    if (width > maxWidth) {
      width = maxWidth
      height = width / aspectRatio
    }

    if (height > maxHeight) {
      height = maxHeight
      width = height * aspectRatio
    }
  }

  const resizedCanvasElement = generateCanvasElement({ width, height })
  const resizedCanvasContext = resizedCanvasElement.getContext('2d')

  if (!resizedCanvasContext) {
    throw new Error('No canvas context found')
  }

  resizedCanvasContext.drawImage(trimmedCanvasElement, 0, 0, width, height)

  const blob = await convertToBlobViaCanvas(resizedCanvasElement, toJpg)
  const base64 = await generateBase64FromBlob(blob)

  return {
    base64,
    blob,
  }
}

const generateFilenameWithType = (filename: string, type: string): string => {
  const filenameArray = filename.split('.')
  const extname = filenameArray.pop()

  if (!extname) {
    return filename
  }

  return `${filenameArray.join('.')}.${type}`
}

const generateFilenameForUpload = (filename: string): string => {
  let newFilename = filename.replace(/ /g, '_')
  const filenameArray = newFilename.split('.')
  const extname = filenameArray.pop()

  if (!extname) {
    return filename
  }

  newFilename = `${filenameArray.join('.')}_${nanoid(6)}.${extname}`

  return newFilename
}

const extractFilenameFromUrl = (url: string): string => {
  const urlParts = url.split('?')
  const baseUrl = urlParts[0]
  const urlPartsArray = baseUrl.split('/')
  const filename = urlPartsArray[urlPartsArray.length - 1]

  if (!filename.includes('.')) {
    return ''
  }

  return filename.substring(0, 100)
}

const getResourceFormat = (resource: Resource): string => {
  return resource.name ? generateFileFormat(resource.name) : resource.format
}

const getResizedImageUrl = (url: string | null, size: 's' | 'm'): string => {
  if (!url) {
    return ''
  }

  const filename = extractFilenameFromUrl(url)

  if (!filename) {
    return ''
  }

  return `${process.env.REACT_APP_IMAGE_SERVICE_PATH}/image/${size}/${filename}`
}

const getThumbnailUrl = (url?: string | null): string => {
  if (!url) {
    return ''
  }

  return getResizedImageUrl(url, 's')
}

export {
  generateFilenameForUpload,
  generateFilenameWithType,
  extractFilenameFromUrl,
  isImageFile,
  isPdfFile,
  isDocFile,
  isEmailFile,
  generateCanvasElement,
  convertToBlobViaCanvas,
  createImage,
  generateBase64FromBlob,
  shrinkImageViaCanvasElement,
  generateFileFormat,
  getResourceFormat,
  getResizedImageUrl,
  getThumbnailUrl,
}
